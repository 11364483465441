import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalState } from './global.state';

@Injectable()
export class WorkspaceAggregatorInterceptor implements HttpInterceptor {

  constructor( private _state: GlobalState ) {
  }

  // TODO: When WealthFluent is deployed and we make the switch to FinGoal completely, this interceptor should be removed
  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    if ( req.url.includes( 'workspace-aggregator' ) && !this._state.globalVars.inWealthFluent ) {
      return next.handle( req.clone( {
        setHeaders: {
          'aggregator': 'yodlee',
        },
      } ) );
    } else {
      return next.handle( req );
    }
  }

}
