import { Directive, HostListener, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from '../../../utils/util.service';
import { environment } from '../../../../environments/environment';

@Directive( {
  selector: 'button[ripPrint]',
} )
export class RipPrintDirective {

  _printStyle = [];

  constructor( private _http: HttpClient, private snackBar: MatSnackBar ) {

  }

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() printSectionId: string;

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() printTitle: string;

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() useExistingCss = false;

  /**
   * A delay in milliseconds to force the print dialog to wait before opened. Default: 0
   *
   * @memberof RipPrintDirective
   */
  @Input() printDelay: number = 0;

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input()
  set printStyle( values: { [key: string]: { [key: string]: string } } ) {
    for ( const key in values ) {
      if ( values.hasOwnProperty( key ) ) {
        this._printStyle.push( ( key + JSON.stringify( values[key] ) ).replace( /['"]+/g, '' ) );
      }
    }
    this.returnStyleValues();
  }

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() prePrintHookFunction: Function;

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() postPrintHookFunction: Function;

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @Input() caller: any;

  /**
   *
   *
   * @returns the string that create the stylesheet which will be injected
   * later within <style></style> tag.
   *
   * -join/replace to transform an array objects to css-styled string
   *
   * @memberof RipPrintDirective
   */
  returnStyleValues() {
    return `<style> ${ this._printStyle.join( ' ' ).replace( /,/g, ';' ) } </style>`;
  }

  /**
   *
   *
   * @returns html for the given tag
   *
   * @memberof RipPrintDirective
   */
  private _styleSheetFile = '';
  private _styleSheetFilePath = '';

  /**
   * @memberof RipPrintDirective
   * @param cssList
   */
  @Input()
  set styleSheetFile( cssList: string ) {
    this._styleSheetFilePath = cssList;
    const linkTagFn = function ( cssFileName ) {
      return `<link rel="stylesheet" type="text/css" href="${ cssFileName }">`;
    };
    if ( cssList.indexOf( ',' ) !== -1 ) {
      const valueArr = cssList.split( ',' );
      for ( const val of valueArr ) {
        this._styleSheetFile = this._styleSheetFile + linkTagFn( val );
      }
    } else {
      this._styleSheetFile = linkTagFn( cssList );
    }

  }

  /**
   * @returns string which contains the link tags containing the css which will
   * be injected later within <head></head> tag.
   *
   */
  private returnStyleSheetLinkTags() {
    return this._styleSheetFile;
  }

  private getElementTag( tag: keyof HTMLElementTagNameMap ): string {
    const html: string[] = [];
    const elements = document.getElementsByTagName( tag );
    for ( let index = 0; index < elements.length; index++ ) {
      html.push( elements[index].outerHTML );
    }
    return html.join( '\r\n' );
  }

  /**
   * @returns html section to be printed along with some associated inputs
   *
   */
  private getHtmlContents() {
    const printContents = document.getElementById( this.printSectionId );
    const innards = printContents.getElementsByTagName( 'input' );
    for ( let i = 0; i < innards.length; i++ ) {
      innards[i].defaultValue = innards[i].value;
    }
    return printContents.innerHTML;
  }

  private generateHtmlForNewWindow( styles, links, printContents ) {
    return `
      <html>
        <head>
          <title>${ this.printTitle ? this.printTitle : '' }</title>
          ${ this.returnStyleValues() }
          ${ this.returnStyleSheetLinkTags() }
          ${ styles }
          ${ links }
          <link href="https://fonts.googleapis.com/icon?family=Roboto+Mono" rel="stylesheet">
        </head>
        <body>
        <div id="print-header" style="margin: 10px 5px;">
          <span>
            <img src="${environment.common.blueLogoUrl}" style="height: 45px !important; width: 162px !important;"/>
          </span>
          <span style="float: right; color: #207BBC">
            ${environment.common.websiteText}
          </span>
        </div>
          ${ printContents }
          <script defer>
            function triggerPrint(event) {
              window.removeEventListener('load', triggerPrint, false);
              setTimeout(function() {
                window.print();
                // setTimeout(function() { window.close(); }, 0);
              }, ${ this.printDelay });
            }
            window.addEventListener('load', triggerPrint, false);
          </script>
        </body>
      </html>`;
  }

  /**
   *
   *
   * @memberof RipPrintDirective
   */
  @HostListener( 'click' )
  print(): void {

    if ( this.prePrintHookFunction && this.caller ) {
      this.prePrintHookFunction( this.caller );
    }

    let printContents, popupWin, styles = '', links = '';

    if ( this.useExistingCss ) {
      styles = this.getElementTag( 'style' );
      links = this.getElementTag( 'link' );
    }

    printContents = this.getHtmlContents();

    if ( Util.isWebkitHandlerAvailable() ) {
      // need to set the styleSheetFile as flat css instead of a file path
      // only going to work for a single stylesheet file for now
      const headers: HttpHeaders = new HttpHeaders( {
        'Content-Type': 'text/css',
      } );
      this._http.get( this._styleSheetFilePath, { headers } )
        .subscribe(
          data => {
            console.log( data );
            styles = data['text'];
            const html = this.generateHtmlForNewWindow( styles, links, printContents );
            Util.sendWebkitHandlerMessage( 'print', { html } );

          },
          err => {
            // it seems that the http client is trying to parse this as json even if we pass the content-type header as css
            console.log( err );
            styles = `<style>${ err.error.text }</style>`;
            const html = this.generateHtmlForNewWindow( styles, links, printContents );
            Util.sendWebkitHandlerMessage( 'print', { html } );
          } );

    } else {
      const html = this.generateHtmlForNewWindow( styles, links, printContents );
      popupWin = window.open( '', '_blank', 'top=0,left=0,height=auto,width=auto' );
      popupWin.document.open();
      popupWin.document.write( html );
      setTimeout( () => {
        popupWin.document.close();
      }, 2500 );
    }
    if ( this.postPrintHookFunction && this.caller ) {
      this.postPrintHookFunction( this.caller );
    }
  }
}
