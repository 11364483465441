import { Component, inject, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BenchmarkEditorComponent } from '../../pages/modals/benchmarkEditor';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BenchmarkState } from '../../utils/benchmark.state';
import { Auth } from '../../auth.service';
import { Util } from '../../utils/util.service';
import { EVENT_NAMES, EXPECTED_VALUE_SCALES, INVESTMENT_EXPERIENCES } from '../../utils/enums';
import { SaveBenchmarkConfirmationDialogComponent } from './components/save-benchmark-confirmation-dialog.component';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GlossaryUtil } from '../../utils/glossary.util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { Router } from '@angular/router';
import { GlobalState } from '../../global.state';
import { WithWorkspacePermission } from '../../shared/workspace-permission';
import { HouseholdMembersState } from '../../utils/household-members.state';
import { Subject } from 'rxjs';
import { USER_PILOT_EVENT_NAMES, UserAnalyticsService } from '../../utils/user-analytics.service';
import { UsersUtil } from '../../utils/users.util';


@Component( {
  selector: 'rip-benchmark-setup-layout',
  template: `
    <nb-card style="border-radius: 15px; margin-top: 32px;">
      <nb-card-header style="position: relative;">
        <nb-badge *ngIf="benchmarkState?.frontierError || benchmarkState?.riskReturnError"
                  text="Error Loading Data. Click here to try again. If the problem persists, please contact {{ supportAddress }}"
                  position="top left" (click)="benchmarkState?.getRiskReturns()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div id="benchmark-setup-title">
          My Plan/Benchmark
          <fa-icon [icon]="faInfoCircle"
                   class="form-info"
                   [fixedWidth]="true"
                   size="sm"
                   [nbPopover]="benchmarkIntro"
                   nbPopoverClass="rip-popover">
          </fa-icon>
        </div>
      </nb-card-header>
      <nb-card-body class="no-padding-card-body"> <!-- [nbSpinner]="benchmarkState?.loading">-->
        <div *ngIf="!deviceIsMobile">
          <rip-risk-tolerance-slider style="width: 100%;" #riskSlider
                                     [hideBucketsOnStart]="true"
                                     [hideBucketsOnClick]="true"
                                     [issues]="benchmarkState?.selectedBucket?.issues"
                                     (riskLevelChanged)="benchmarkState?.riskLevelToFrontierSolution($event)"></rip-risk-tolerance-slider>
        </div>

        <!-- DESKTOP -->
        <div *ngIf="!deviceIsMobile">
          <!-- INVESTOR INFO AND CHOSEN BENCHMARK -->
          <nb-accordion>
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header style="padding-top: 0px; padding-bottom: 10px;">
                <div class="flexing-row-only space-between" style="width: 95%;">
                  <div class="benchmark-accordion-title">My Lifetime Wealth Plan</div>
                  <ng-container *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]">
                    <button nbButton style="background-color: #00AEEF; border-radius: 8px; border: 0;" status="primary" (click)="confirmSave( $event )"
                            *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed"
                            [disabled]="benchmarkState.hideWealthChart">
                      <!-- hideWealthChart === true means there is something wrong with the investor info,
                       so we shouldn't let them save their plan if data is bad-->
                      Save My Wealth Plan
                    </button>
                  </ng-container>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body class="wealth-plan-accordion-item-body">
                <div class="flexing-row-only space-around"
                     *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div>
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>
                <div class="flexing with-gap" id="wealth-plan-accordion-item-container"
                     *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <!-- MY PROFILE, CONTROLS AND STUFF COLUMN -->
                  <div class="flexing space-between-safe vertical-flex with-gap planning-sidebar">
                    <rip-investor-info #investorInfo
                                       [ignoreOnboarding]="true"
                                       [selectedBucket]="benchmarkState?.selectedBucket"
                                       [savingsAnnuityUntilRetirement]="benchmarkState?.selectedBucket?.stats?.savingsAnnuityUntilRetirement"
                                       [issues]="benchmarkState?.selectedBucket?.issues">

                    </rip-investor-info>
                    <div class="scale-control-container">
                      <rip-scale-control></rip-scale-control>
                      <rip-probability-control
                        [issues]="benchmarkState?.selectedBucket?.issues"></rip-probability-control>


                    </div>


                    <div *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]" class="scale-control-container">
                      <div>
                        <span style="font-weight: bold;">Benchmark Composition</span>
                        <span class="disclaimer form-disclaimer"
                              (click)="openGlossary( 35 )">
                      <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon></span>
                      </div>
                      <div class="sample-proxy-buttons"
                           *ngFor="let defaultBenchmark of benchmarkState.defaultBenchmarks">
                        <button mat-button
                                (click)="benchmarkState.loadBenchmark(defaultBenchmark, true)"
                                matTooltip="Click to load this benchmark"
                                matTooltipClass="mat-tooltip-custom"
                                type="button"
                                color="{{ 
                                ( benchmarkState.benchmark.benchmarkData.name === defaultBenchmark.benchmarkData.name )
                                 || !benchmarkState.benchmark.name ? 'primary' : '' }}">
                          {{ defaultBenchmark.name }}
                        </button>
                      </div>
                      <div style="margin: 10px;">
                        <button mat-button
                                (click)="openBenchmarkEditor()"
                                color="{{ !benchmarkState.benchmark.benchmarkData.name ? 'primary' : '' }}">
                          Customize Benchmark Composition
                        </button>
                      </div>
                    </div>

                  </div>

                  <!-- WEALTH CHART, PROBABILITY NUMBERS SUMMARY, AND ANALYSIS COLUMN -->
                  <div class="flexing space-between vertical-flex with-gap wealth-plan-column"
                       *ngIf="!benchmarkState?.loadingExpectedWealthData">
                    <div id="wealth-plan-container">
                      <rip-expected-wealth-chart
                        [chartDataSets]="benchmarkState?.selectedBucket?.chartData"
                        [yMax]="benchmarkState?.selectedBucket?.stats?.yMax"
                        [showTitle]="false"
                        [height]="deviceIsMobile ? '350px' : '600px'"
                        [deviceIsMobile]="deviceIsMobile">

                      </rip-expected-wealth-chart>
                      <rip-wealth-chart-zoom-control></rip-wealth-chart-zoom-control>
                    </div>

                    <div class="flexing space-around with-gap">
                      <div class="flexing space-around" style="flex: 1;">
                        <rip-goal-contributions [showAddGoalButton]="true"
                                                style="width: 100%;"></rip-goal-contributions>
                      </div>
                    </div>
                    <rip-household-members>

                    </rip-household-members>
                  </div>
                </div>
                <div *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed" style="margin-top: 30px;">
                  <!-- Container for Household members list-->

                  <!-- ANALYSIS CONTAINER for chosen plan -->
                  <rip-wealth-plan-analysis [bucket]="benchmarkState?.selectedBucket" style="flex: 1;"
                                            [showButton]="false">

                  </rip-wealth-plan-analysis>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
          
          <!-- SUMMARY  ROW-->
          <nb-accordion>
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header>
                <div class="flexing-row-only">
                  <div class="benchmark-accordion-title">My Plan Benchmark Summary</div>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body>
                <div class="flexing-row-only space-around"
                     *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div>
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>
                <div style="flex: 1;" class="flexing vertical-flex space-between"
                     *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div class="ripsaw-card-header">
                    <div class="ripsaw-card-subheader">
                      Based on your planning, the Plan Benchmark Portfolio is composed of the following assets. This is
                      a {{ benchmarkState?.benchmark?.name }}
                      investment strategy
                      <!-- with a {{ benchmarkState?.selectedBucket?.riskLevel?.riskLabel }} level-->
                    </div>
                  </div>

                  <rip-benchmark-asset-allocation-breakdown>

                  </rip-benchmark-asset-allocation-breakdown>

                  <rip-risk-exposure-donut [listItems]="benchmarkState?.benchmarkRiskExposureList"
                                           [titlePrefix]="'Benchmark Portfolio'">
                  </rip-risk-exposure-donut>


                  <rip-risk-exposure-donut [listItems]="benchmarkState?.portfolioRiskExposureList"
                                           [titlePrefix]="'Current Investment Portfolio'"
                                           *ngIf="benchmarkState?.investmentTotalCached !== 0">
                  </rip-risk-exposure-donut>
                  <div>
                    To see a full breakdown of your portfolio, go to the
                    <a [routerLink]="'/pages/holdings'" class="rip-link">holdings</a>
                    page
                  </div>

                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
          
          <!-- ADVANCED STUFF (FRONTIER, FORWARD LOOKING TABLE) -->
          <nb-accordion>
            <nb-accordion-item [expanded]="userIsAdvanced">
              <nb-accordion-item-header>
                <div class="flexing-row-only">
                  <div class="benchmark-accordion-title">Return and Volatility Details</div>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body>
                <div class="flexing-row-only space-around"
                     *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div>
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>
                <div class="flexing space-between" *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div style="flex: 1;">
                    <rip-risk-return-scatter-plot #riskReturnScatterPlot
                                                  [datasets]="benchmarkState?.riskReturnScatterData?.datasets"
                                                  [labels]="benchmarkState?.riskReturnScatterData?.labels"
                                                  [defaultFrontierPointRadius]="0"
                                                  [interactive]="benchmarkState?.term === 'long'"
                                                  [chartWrapperHeight]="600"
                                                  [maxWidth]="'800px'"
                                                  (pointClicked)="benchmarkState?.frontierPointClicked($event)"
                                                  [chartTitle]="'Expected Return vs Total Volatility (Risk)'">

                    </rip-risk-return-scatter-plot>
                  </div>
                  <div style="flex: 1;">
                    <div class="flexing vertical-flex">
                      <rip-benchmark-forward-looking [riskReturns]="benchmarkState?.riskReturnsValues"
                                                     [benchmarkData]="benchmarkState?.benchmark?.benchmarkData"
                                                     [allProxies]="benchmarkState?.allProxies"
                                                     [cashBondStockWeightMap]="benchmarkState?.cashBondStockWeightMap"
                                                     #forwardLookingComponent></rip-benchmark-forward-looking>
                      <rip-correlation-matrix [riskReturnsValues]="benchmarkState?.riskReturnsValues"
                                              [loading]="false"
                                              [combinedTable]="true"
                                              [term]="benchmarkState?.term"
                                              [errorLoadingData]="benchmarkState?.riskReturnError"
                                              *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed"></rip-correlation-matrix>
                    </div>
                  </div>
                </div>
                <ng-container *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]">
                  <div class="flexing space-around" style="margin-top: 25px;"
                       *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                    <button nbButton status="primary" style="background-color: #00AEEF; border-radius: 8px; border: 0;" status="primary" (click)="confirmSave()"
                            [disabled]="benchmarkState.hideWealthChart">
                      <!-- hideWealthChart === true means there is something wrong with the investor info,
                       so we shouldn't let them save their plan if data is bad-->
                      Save My Wealth Plan
                    </button>
                  </div>
                </ng-container>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>


          <!--<div>
            <rip-covariance-matrix [riskReturnsValues]="riskReturnsValues"
                                   [loading]="loading"
                                   [term]="term"
                                   [errorLoadingData]="riskReturnError">
  
            </rip-covariance-matrix>
          </div>-->
        </div>

        <!-- MOBILE -->
        <div *ngIf="deviceIsMobile">

          <!-- INVESTOR INFO AND CHOSEN BENCHMARK -->
          <nb-accordion>
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header>
                <div class="flexing-row-only">
                  <div class="benchmark-accordion-title">My Lifetime Wealth Plan</div>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body class="wealth-plan-accordion-item-body">
                <!-- START INVESTOR HORIZON COPY -->

                <!-- SPINNER -->
                <div *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div style="text-align: center;">
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>

                <div id="risk-tolerance-slider-container" *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <rip-risk-tolerance-slider style="width: 100%;" #riskSlider
                                             [hideBucketsOnClick]="true"
                                             [hideBucketsOnStart]="true"
                                             [issues]="benchmarkState?.selectedBucket?.issues"
                                             [deviceIsMobile]="deviceIsMobile"
                                             (riskLevelChanged)="benchmarkState?.riskLevelToFrontierSolution($event)">

                  </rip-risk-tolerance-slider>

                  <div class="flexing space-between with-gap">
                    <div style="flex: 2;">
                      <rip-expected-wealth-chart [chartDataSets]="benchmarkState?.selectedBucket?.chartData"
                                                 [showTitle]="true"
                                                 [height]="deviceIsMobile ? '275px' : '600px'"
                                                 [deviceIsMobile]="deviceIsMobile">


                      </rip-expected-wealth-chart>

                      <div class="scale-control-container">
                        <rip-scale-control></rip-scale-control>
                        <rip-probability-control
                          [issues]="benchmarkState?.selectedBucket?.issues"></rip-probability-control>
                      </div>
                      <!--                      <rip-goal-contributions [showAddGoalButton]="false"></rip-goal-contributions>-->
                      <rip-wealth-forecast [retired]="investorInfoChosen.retired"></rip-wealth-forecast>
                    </div>

                    <!-- Container for Household members list-->
                    <rip-household-members>

                    </rip-household-members>
                    <!-- ANALYSIS CONTAINER for chosen plan -->
                    <rip-wealth-plan-analysis [bucket]="benchmarkState?.selectedBucket" style="flex: 1;"
                                              [showButton]="false">

                    </rip-wealth-plan-analysis>
                  </div>
                  <rip-investor-info #investorInfoChosen
                                     [selectedBucket]="benchmarkState?.selectedBucket"
                                     [issues]="benchmarkState?.selectedBucket?.issues">

                  </rip-investor-info>
                </div>

                <!-- END INVESTOR HORIZON COPY -->

                <div class="flexing space-around" style="margin-top: 25px;"
                     *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <button *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]"
                  nbButton style="background-color: #00AEEF; border-radius: 8px; border: 0;" status="primary" (click)="confirmSave( $event )"
                          [disabled]="benchmarkState.hideWealthChart">
                    <!-- hideWealthChart === true means there is something wrong with the investor info,
                     so we shouldn't let them save their plan if data is bad-->
                    Save My Wealth Plan
                  </button>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>

          <!-- SUMMARY  ROW-->
          <nb-accordion>
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header>
                <div class="flexing-row-only">
                  <div class="benchmark-accordion-title">My Benchmark Summary</div>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body>
                <div class="flexing-row-only space-around"
                     *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div>
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>
                <div style="flex: 1;" class="flexing vertical-flex space-between"
                     *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div class="ripsaw-card-header">
                    <div class="ripsaw-card-subheader">
                      Your benchmark consists of the below assets. This is
                      a {{ benchmarkState?.benchmark?.name }}
                      investment strategy
                      <!-- with a {{ benchmarkState?.selectedBucket?.riskLevel?.riskLabel }} level-->
                    </div>
                  </div>

                  <rip-benchmark-asset-allocation-breakdown>

                  </rip-benchmark-asset-allocation-breakdown>

                  <rip-risk-exposure-donut [listItems]="benchmarkState?.benchmarkRiskExposureList"
                                           [titlePrefix]="'Benchmark Portfolio'"
                                           [deviceIsMobile]="deviceIsMobile">
                  </rip-risk-exposure-donut>


                  <rip-risk-exposure-donut [listItems]="benchmarkState?.portfolioRiskExposureList"
                                           [titlePrefix]="'Current Investment Portfolio'"
                                           [deviceIsMobile]="deviceIsMobile"
                                           *ngIf="benchmarkState?.investmentTotalCached !== 0">
                  </rip-risk-exposure-donut>
                  <div>
                    To see a full breakdown of your portfolio, go to the
                    <a (click)="goToHoldings()" class="rip-link">holdings</a>
                    page
                  </div>

                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion> 
          
          <!-- ADVANCED STUFF (FRONTIER, FORWARD LOOKING TABLE) -->
          <nb-accordion>
            <nb-accordion-item [expanded]="userIsAdvanced">
              <nb-accordion-item-header>
                <div class="flexing-row-only">
                  <div class="benchmark-accordion-title">Return and Volatility Details</div>
                </div>
              </nb-accordion-item-header>

              <nb-accordion-item-body>
                <div class="flexing-row-only space-around"
                     *ngIf="!benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div>
                    <div>Analyzing Your Financial Goals And Market Opportunities...</div>
                    <rip-bars-spinner></rip-bars-spinner>
                  </div>
                </div>
                <div class="flexing space-between" *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <div style="flex: 1;">
                    <rip-risk-return-scatter-plot #riskReturnScatterPlot
                                                  [datasets]="benchmarkState?.riskReturnScatterData?.datasets"
                                                  [labels]="benchmarkState?.riskReturnScatterData?.labels"
                                                  [defaultFrontierPointRadius]="0"
                                                  [interactive]="benchmarkState?.term === 'long'"
                                                  [chartWrapperHeight]="600"
                                                  [maxWidth]="'800px'"
                                                  (pointClicked)="benchmarkState?.frontierPointClicked($event)"
                                                  [chartTitle]="'Expected Return vs Total Volatility (Risk)'">

                    </rip-risk-return-scatter-plot>
                  </div>
                  <div style="flex: 1;">
                    <div class="flexing vertical-flex">
                      <rip-benchmark-forward-looking [riskReturns]="benchmarkState?.riskReturnsValues"
                                                     [benchmarkData]="benchmarkState?.benchmark?.benchmarkData"
                                                     [allProxies]="benchmarkState?.allProxies"
                                                     [cashBondStockWeightMap]="benchmarkState?.cashBondStockWeightMap"
                                                     #forwardLookingComponent></rip-benchmark-forward-looking>
                      <rip-correlation-matrix [riskReturnsValues]="benchmarkState?.riskReturnsValues"
                                              [loading]="false"
                                              [combinedTable]="true"
                                              [term]="benchmarkState?.term"
                                              [errorLoadingData]="benchmarkState?.riskReturnError"
                                              *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed"></rip-correlation-matrix>
                    </div>
                  </div>
                </div>
                <div class="flexing space-around" style="margin-top: 25px;"
                     *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">
                  <button *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]"
                   nbButton style="background-color: #00AEEF; border-radius: 8px; border: 0;" status="primary" (click)="confirmSave( $event )"
                          [disabled]="benchmarkState.hideWealthChart">
                    <!-- hideWealthChart === true means there is something wrong with the investor info,
                     so we shouldn't let them save their plan if data is bad-->
                    Save My Wealth Plan
                  </button>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>


          <!--<div>
            <rip-covariance-matrix [riskReturnsValues]="riskReturnsValues"
                                   [loading]="loading"
                                   [term]="term"
                                   [errorLoadingData]="riskReturnError">
  
            </rip-covariance-matrix>
          </div>-->
        </div>
      </nb-card-body>
    </nb-card>

    <!--benchmark intro popover-->
    <ng-template #benchmarkIntro>
      <nb-card class="popover-card">
        <nb-card-header class="popover-title">
          My Benchmark and Plan
        </nb-card-header>
        <nb-card-body>
          <div class="popover-content">
            <p> Defining a <b>custom benchmark</b> is necessary for a disciplined investment process. Without
              any
              sophisticated investment knowledge, it is an easily purchased, extremely low cost,
              well-diversified
              portfolio strategy that is consistent with your preferred expected wealth versus risk
              tradeoff. Your
              custom benchmark is also referred to as your long-term <b>strategic asset allocation</b> or
              <b>passive
                portfolio strategy</b>. Your Plan creates you Plan Benchmark Portfolio.</p>

            <p> Given the well-diversified stock and bond portfolios chosen to represent these markets, you
              select the
              cash, bond and stock investment weights from an efficient frontier of expected wealth versus
              risk
              tradeoffs that best satisfies your lifetime financial plan. </p>

            <p>Common question: When can I retire with a sufficient cash flow to live on without too much risk
              of
              running out of money? </p>

            <p>The feasibility of goals is unique to your desired standard of living and what are the Expected
              Wealth
              versus Risk alternatives the financial markets are offering. </p>
            <p>Your lifetime financial plan is a simultaneous set of decisions that includes these key
              variables:</p>
            <ul style="list-style-type:circle">
              <li>
                Current wealth portfolio size
              </li>
              <li>Retirement date</li>
              <li>Annual savings for investment prior to retirement</li>
              <li>Annual net withdrawals in retirement</li>
              <li>Length of retirement</li>
              <li>Financial Goals</li>
              <li>Expected Wealth vs Risk investment strategy</li>
            </ul>
            <a class="btn btn-primary" style="color: white;"href="javascript:userpilot.trigger('Mm4Ex3Yt2P')"> Walk through the pieces now.</a>
          </div>
        </nb-card-body>
      </nb-card>

    </ng-template>

  `,
  styleUrls: [ './benchmark-setup-layout.component.scss' ],
} )

export class BenchmarkSetupLayoutComponent extends WithWorkspacePermission implements OnDestroy {

  private onDestroy: Subject<void> = new Subject<void>();

  userAnalyticsService: UserAnalyticsService = inject( UserAnalyticsService );
  usersUtil: UsersUtil = inject( UsersUtil );

  @Input() maxHeight: string = '95vh';

  faQuestionCircle = faQuestionCircle;
  faInfoCircle = faInfoCircle;
  userIsAdvanced: boolean = false; // will get this from onboarding or user profile later
  subscriberName: string = 'benchmarkSetupLayoutComponent';
  deviceIsMobile: boolean;
  supportAddress: string = Util.getSupportAddress();

  EXPECTED_VALUE_SCALES = EXPECTED_VALUE_SCALES;

  constructor( public dialog: MatDialog,
               public benchmarkState: BenchmarkState,
               private glossaryUtil: GlossaryUtil,
               private _auth: Auth,
               private _detectorService: DeviceDetectorService,
               private router: Router,
               private _state: GlobalState,
               public householdMembersState: HouseholdMembersState,
  ) {
    super();

    if ( environment.env !== 'prod' ) {
      window[ 'benchmarkSetupLayout' ] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    const user = Util.getLoggedInUser( this._auth );
    if ( user.investmentExperience === INVESTMENT_EXPERIENCES.diamond ||
      user.investmentExperience === INVESTMENT_EXPERIENCES.square ) {
      this.userIsAdvanced = true;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  /*
   * Open the benchmark editor dialog for changing benchmark proxies
   **/
  openBenchmarkEditor() {
    this.dialog.open( BenchmarkEditorComponent, {
      minWidth: '85vw',
    } );
  }

  confirmSave( event?: MouseEvent ) {
    event?.stopPropagation();
    console.log( 'confirm save event: ', event );
    const dialogRef: MatDialogRef<SaveBenchmarkConfirmationDialogComponent> =
      this.dialog.open( SaveBenchmarkConfirmationDialogComponent, {} );

    dialogRef.afterClosed().subscribe( ( confirmed ) => {
      if ( confirmed ) {
        this._state.subscribe( EVENT_NAMES.USER_BENCHMARK_SAVED, () => {
          this.userAnalyticsService.trackInUserPilot( {
            name: USER_PILOT_EVENT_NAMES.SAVED_BENCHMARK,
            meta: {},
          } );
          this._state.unsubscribe( EVENT_NAMES.USER_BENCHMARK_SAVED, this.subscriberName );
        }, this.subscriberName );
        this.benchmarkState?.saveBenchmark();
        const user = Util.getLoggedInUser( this._auth );
        user.has_saved_plan = true;
        this.usersUtil.simpleUserUpdate( user );
      }
    } );
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

  goToHoldings() {
    if ( this.deviceIsMobile ) {
      this._state.notifyDataChanged( EVENT_NAMES.MOBILE_GO_TO_HOLDINGS );
    } else {
      this.router.navigateByUrl( '/pages/holdings' ).then( () => {
      } );
    }
  }

}
