import { Component, ViewChild } from '@angular/core';
import {
  TradingViewSymbolOverviewComponent,
} from '../tradingView';
import { MarketIndexDataComponent } from '../marketData';
import { UsYieldCurveComponent } from '../usYieldCurve';
import { BondMarketsTabComponent } from '../bondMarkets';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { NbTabComponent } from '@nebular/theme';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { SettingsComponent } from '../../pages/modals/settings';
import { MatDialog } from '@angular/material/dialog';
import { StockMarketsTabComponent } from '../stockMarkets';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component( {
  selector: 'rip-market-information-dashboard',
  template: `
    <nb-card class="market-info-main-card">
      <nb-card-header>
        <rip-markets-overview *ngIf="!deviceIsMobile"></rip-markets-overview>
        <div *ngIf="deviceIsMobile" id="market-info-dashboard-header">
          <div id="market-info-dashboard-title">
            <h3>Market Analysis</h3>
          </div>
          <rip-trading-view-market-overview></rip-trading-view-market-overview>
        </div>
      </nb-card-header>
      <nb-card-body class="no-padding-card-body no-scroll-horizontal" id="market-info-dashboard-card-body">

        <div id="market-info-dashboard-content">
          <!-- open settings dialog to market info card -->
          <button mat-raised-button
                  matTooltip="Adjust Market Information Settings"
                  matTooltipClass="mat-tooltip-custom"
                  (click)="openSettings()"
                  class="settings-button">
            <fa-layers style="font-size: 1.1rem;">
              <fa-icon [icon]="faAnalytics" size="1x"></fa-icon>
              <fa-icon [icon]="faSlidersH" size="xs"></fa-icon>
            </fa-layers>
          </button>
          <nb-tabset (changeTab)="tabChanged($event)">
            <!--<nb-tab [tabTitle]="yourBenchmarkTab" [active]="currentTabName === yourBenchmarkTab">
                Your benchmark stuff goes here
            </nb-tab>-->
            <nb-tab [tabTitle]="yieldCurveTab" [active]="currentTabName === yieldCurveTab" responsive>
              <div class="market-info-dashboard-tab-content">
                <rip-us-yield-curve #usYieldCurveComponent></rip-us-yield-curve>
              </div>
            </nb-tab>
            <nb-tab [tabTitle]="bondMarketsTab" [active]="currentTabName === bondMarketsTab" responsive>
              <div class="market-info-dashboard-tab-content">
                <rip-bond-markets-tab #bondMarketsTabComponent></rip-bond-markets-tab>
              </div>
            </nb-tab>
            <nb-tab [tabTitle]="stockMarketsTab" [active]="currentTabName === stockMarketsTab" responsive>
              <div class="market-info-dashboard-tab-content">
                <rip-stock-markets-tab #stockMarketsTabComponent></rip-stock-markets-tab>
              </div>
            </nb-tab>
            <nb-tab [tabTitle]="cryptoMarketsTab" [active]="currentTabName === cryptoMarketsTab" responsive>
              <div class="market-info-dashboard-tab-content">
                <rip-trading-view-crypto-markets></rip-trading-view-crypto-markets>

                <rip-trading-view-symbol-overview [tickers]="cryptoSymbols"
                                                  [brandingLabel]="'Cryptocurrency Markets '"
                                                  #tradingViewSymbolChart></rip-trading-view-symbol-overview>
              </div>
            </nb-tab>
            <nb-tab [tabTitle]="oneDayBreakdownTab" [active]="currentTabName === oneDayBreakdownTab" responsive>
              <div class="market-info-dashboard-tab-content">
                <rip-one-day-change-breakdown></rip-one-day-change-breakdown>
              </div>
            </nb-tab>
          </nb-tabset>
        </div>
      </nb-card-body>
      <!--<nb-card-footer>

      </nb-card-footer>-->
    </nb-card>
  `,
  styleUrls: [ './market-information-dashboard.component.scss' ],
} )

export class MarketInformationDashboardComponent {

  faSlidersH: IconDefinition = faSlidersH;
  faAnalytics: IconDefinition = faAnalytics;

  @ViewChild( 'tradingViewSymbolChart' ) tradingViewSymbolChart: TradingViewSymbolOverviewComponent;
  @ViewChild( 'marketTradingViewSymbolChart' ) marketTradingViewSymbolChart: TradingViewSymbolOverviewComponent;

  @ViewChild( 'marketDataTableComponent' ) marketDataTableComponent: MarketIndexDataComponent;
  @ViewChild( 'usYieldCurveComponent' ) usYieldCurveComponent: UsYieldCurveComponent;
  @ViewChild( 'bondMarketsTabComponent' ) bondMarketsTabComponent: BondMarketsTabComponent;
  @ViewChild( 'stockMarketsTabComponent' ) stockMarketsTabComponent: StockMarketsTabComponent;

  readonly yourBenchmarkTab: string = 'Your Benchmark';
  readonly yieldCurveTab: string = 'US Yield Curve';
  readonly bondMarketsTab: string = 'Bonds';
  readonly stockMarketsTab: string = 'Stocks';
  readonly marketData: string = 'Market Data';
  readonly cryptoMarketsTab: string = 'Crypto';
  readonly oneDayBreakdownTab: string = 'Daily Performance';

  currentTabName: string = this.stockMarketsTab;

  cryptoSymbols: string[][] = GlobalState.marketInfoCryptoSymbols;

  marketSymbols = GlobalState.marketInfoTickerTapeSymbols;

  subscriberName: string = 'MarketInfoDashboardComponent';

  deviceIsMobile: boolean = false;

  constructor( public dialog: MatDialog ) {

    if ( environment.env !== 'prod' ) {
      window['ripsaw_marketDataDialog'] = this;
    }

  }


  tabChanged( tab: NbTabComponent ) {
    // console.log( tab );
    this.currentTabName = tab.tabTitle;
    if ( this.cryptoMarketsTab === this.currentTabName ) {
      setTimeout( () => {
        this.tradingViewSymbolChart.initChart();
      }, 500 );
    } else if ( this.marketData === this.currentTabName ) {
      setTimeout( () => {
        this.marketTradingViewSymbolChart.initChart();
      } );
    } else if ( this.bondMarketsTab === this.currentTabName ) {
      setTimeout( () => {
        this.bondMarketsTabComponent.initChart();
        this.bondMarketsTabComponent.forwardLookingTable.recalculate();
      }, 500 );
    } else if ( this.stockMarketsTab === this.currentTabName ) {
      setTimeout( () => {
        this.stockMarketsTabComponent.forwardLookingMarketsTable.recalculate();
        this.stockMarketsTabComponent.forwardLookingSectorsTable.recalculate();
      } );
    } else if ( this.yieldCurveTab === this.currentTabName ) {
      setTimeout( () => {
        this.usYieldCurveComponent.setColumnsOnTables();
      }, 1000 );
    }
  }

  openSettings() {
    if ( this.dialog.openDialogs.length === 0 ) {
      this.dialog.open( SettingsComponent, {
        // width: '50vw',
        height: '50vh',
        data: {
          singleSection: 'Market Information Settings',
        },
        panelClass: 'settings-panel',
        hasBackdrop: false,
      } );
    }
  }


}

