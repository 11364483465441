import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '../../pages/profile/profile.service';
import { Subject } from 'rxjs';
import { HouseholdMember } from '@ripsawllc/ripsaw-analyzer';
import { takeUntil } from 'rxjs/operators';
import { Util } from '../../utils/util.service';


@Component( {
  template: `
    <h3 mat-dialog-title>
      Add Household Member
    </h3>
    <div mat-dialog-content [nbSpinner]="working">
      <div *ngIf="error" class="dialog-error-msg">
        {{ error }}
      </div>
      <div>
        Are you sure you want to remove {{  memberToDelete?.name }} from your household?
      </div>
    </div>
    <div mat-dialog-actions class="delete-confirmation-actions">
      <button mat-raised-button (click)="deleteHouseholdMember()" color="warn">
        REMOVE
      </button>
      <button mat-button (click)="cancel()">
        Cancel
      </button>
    </div>
  `,
  styleUrls: [ `./delete-household-member-dialog.component.scss` ],
} )

export class DeleteHouseholdMemberDialogComponent {

  private readonly onDestroy = new Subject<void>();

  error: string;
  working: boolean = false;
  memberToDelete: HouseholdMember;

  constructor( private dialog: MatDialogRef<DeleteHouseholdMemberDialogComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private _profileService: ProfileService ) {
    this.memberToDelete = data;
  }

  deleteHouseholdMember() {
    this.working = true;
    console.log( 'delete household member...' );
    this._profileService.deleteHouseholdMember( this.memberToDelete )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.working = false;
          this.error = undefined;
          this.dialog.close( true );
        }, error: ( err ) => {
          console.error( err );
          this.working = false;
          this.error = `Error removing household member. ${ Util.getRefCodeSupportString( err.refCode ) }`;
        },
      } );
  }

  cancel() {
    this.dialog.close();
  }
}
