import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash-es';
import {
  NotificationsService,
} from '../globalData';
import { GlobalState } from '../global.state';
import { environment } from '../../environments/environment';
import { Util } from './util.service';
import { takeUntil } from 'rxjs/operators';
import { Logger } from './logger.service';
import { Subject } from 'rxjs';
import { Auth } from '../auth.service';
import { Notification } from './dataInterfaces';
import { EVENT_NAMES } from './enums';

@Injectable()
export class NotificationsUtil implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  notifications: Notification[] = [];

  firstLoadDone: boolean = false;

  subscriberName: string = 'NotificationsUtil';

  gatherNotificationsIntervalId: any;

  constructor( private _state: GlobalState,
               private _notificationsService: NotificationsService,
               private _auth: Auth ) {
    this.gatherAllNotifications();

    if ( environment.env !== 'prod' ) {
      window['ripsaw_notificationsUtil'] = this;
    }

    this._state.subscribe( EVENT_NAMES.LOGGED_IN, () => {
      this.setupIntervalForGatheringNotifications();
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.LOGOUT, () => {
      clearInterval( this.gatherNotificationsIntervalId );
    }, this.subscriberName );
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    this.onDestroy.next();
  }

  unsubscribeAll() {
    this._state.unsubscribe( [
      EVENT_NAMES.TOKENS_RENEWED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.LOGGED_IN,
      EVENT_NAMES.LOGOUT,
    ].join( ' | ' ), this.subscriberName );
  }

  setupIntervalForGatheringNotifications() {
    this.gatherNotificationsIntervalId = setInterval( () => {
      this.gatherAllNotifications();
    }, 10 * 60000 ); // 10 * 1 min worth of ms
  }

  gatherAllNotifications() {
    this._notificationsService.getUserNotifications()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( userNotifications: Notification[] ) => {
          console.log( userNotifications );

          this.notifications = userNotifications?.length > 0 ? [ ...userNotifications ] : [];

          this._notificationsService.getGlobalNotifications()
            .pipe( takeUntil( this.onDestroy ) )
            .subscribe( {
              next: ( globalNotifications: Notification[] ) => {
                // console.log( globalNotifications );
                this.mergeGlobalNotificationsIntoUsers( globalNotifications );
                this.sortNotifications();

                this.removeExpiredNotifications();
              }, error: ( err ) => {
                // global notifications retrieval error
                console.error( err );
              },
            } );
        }, error: ( err ) => {
          // user notifications retrieval error
          console.error( err );
          if ( !Util.getLoggedInUser( this._auth ) ) {
            clearInterval( this.gatherNotificationsIntervalId );
          }
        },
      } );
  }

  removeExpiredNotifications() {
    const length = this.notifications.length;
    _.remove( this.notifications, ( n: Notification ) => {
      return this.notificationExpired( n );
    } );
    if ( this.notifications.length !== length ) {
      this.updateUserNotifications();
    }
  }

  mergeGlobalNotificationsIntoUsers( globalNotifications: Notification[] ) {
    let modified = false;
    let warningNotification: Notification;
    let newsNotification: Notification;
    for ( const n of globalNotifications ) {
      const existingNotificationIndex = this.notifications.findIndex( ( x: Notification ) => {
        return x.id === n.id;
      } );
      const existingNotification = this.notifications[existingNotificationIndex];

      if ( !existingNotification && !n.deleted && !this.notificationExpired( n ) ) {
        this.notifications.push( n );
        modified = true;
        if ( n.type === 'news' ) {
          newsNotification = n;
        }
      } else if ( existingNotification ) {
        if ( n.deleted || this.notificationExpired( n ) ) {
          this.notifications.splice( existingNotificationIndex, 1 );
        } else {
          const read = existingNotification.read;
          Object.assign( existingNotification, n );
          existingNotification.read = read;
          if ( n.type === 'news' && !read ) {
            newsNotification = existingNotification;
          }
        }
        modified = true;
      }
      if ( n.type === 'warning' && !n.deleted && !this.notificationExpired( n ) ) {
        warningNotification = n;
      }
    }
    this._state.globalVars.warningNotification = warningNotification;
    if ( warningNotification ) {
      this._state.notifyDataChanged( EVENT_NAMES.WARNING_NOTIFICATION, warningNotification );
    } else {
      this._state.notifyDataChanged( EVENT_NAMES.WARNING_NOTIFICATION );
    }

    if ( newsNotification ) {
      newsNotification.read = true;
      this._state.notifyDataChanged( EVENT_NAMES.NEWS_NOTIFICATION, newsNotification );
    }

    if ( modified ) {
      this.updateUserNotifications();
    }
  }

  notificationExpired( n: Notification ) {
    return n.expiration < Date.now();
  }

  updateUserNotifications() {
    this._notificationsService.updateUserNotifications( this.notifications )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          const responseData = response.data;
          if ( responseData.token ) {
            // commented out because it is unnecessary considering the notifications should get updated regardless of whether the token is changed in the session.
            // set session if there was a modification. otherwise, we essentially negate the session timeout
            /*if ( userModified ) {
              this._auth.setNewToken( responseData.token );
            }*/
            this._state.notifyDataChanged( 'notifications.updated', this.notifications );
            // console.log('token stored');
          }
          // console.log( 'notifications updated' );
        }, error: ( err ) => {
          console.error( err );
        },
      } );
  }

  sortNotifications() {
    this.notifications.sort( ( a, b ) => ( a.timestamp > b.timestamp ) ? 1 : -1 );
  }

}
