import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// Simple logging service to control what environments logging statements are shown in. eventually we can add calls to
// our api to let us know when important errors are happening for users

@Injectable()
export class Logger {

  /**
   * Function to log content to console in non production environments
   * @param log - content to be logged to the console
   * @param trace - whether to print the stack trace tree
   */
  static log( log: any, trace?: boolean ) {
    if ( environment.env !== 'prod' ) {
      console.log( log );
      if ( trace ) {
        console.log( Logger.getStackTrace() );
      }
    }
  }

  /**
   * Function to log content to console in non production environments
   * @param log - content to be logged to the console
   * @param trace - whether to print the stack trace tree
   */
  static warn( log: any, trace?: boolean ) {
    if ( environment.env !== 'prod' ) {
      console.warn( log );
      if ( trace ) {
        console.warn( Logger.getStackTrace() );
      }
    }
  }

  /**
   ** Function to log content to console in non production environments with optional stack trace
   * @param log - content to be logged to the console
   * @param trace [optional] - whether to print the stack trace tree
   */
  static info( log: any, trace?: boolean ) {
    if ( environment.env !== 'prod' ) {
      console.info( log );
      if ( trace ) {
        console.info( Logger.getStackTrace() );
      }
    }
  }

  /**
   * * Function to log content to console in any environment
   * @param log - content to be logged to the console
   */
  static error( log: any ) {
    console.error( log );
  }

  /**
   * Function to log table content to console in non production environments
   * @param obj
   */
  static table( obj: any ) {
    if ( environment.env !== 'prod' ) {
      console.table( obj );
    }
  }

  /**
   *
   *
   */
  static getStackTrace() {
    try {
      throw new Error();
    } catch ( err ) {
      return err.stack.slice( 94, 300 );
    }
  }

}
