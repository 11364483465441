import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AppStoreService } from './store';
import { WorkspaceLoadedStore } from './store/workspace';
import { take } from 'rxjs/operators';
import { Logger } from './utils/logger.service';

const PATHS_TO_APPLY_WP_ID = [
  'workspace-goals',
  'workspace-household-members',
  'workspace-benchmarks',
  'workspace-manual-accounts',
  'workspace-account-mappings',
  'workspace-aggregator',
  'workspace-revisions',
  'userOverrides',
];

@Injectable()
export class WorkspacesInterceptor implements HttpInterceptor {

  constructor(
    private appStoreService: AppStoreService,
  ) {
  }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return this.appStoreService.loadedWorkspace$
      .pipe(
        take( 1 ),
        switchMap( workspace => next.handle( this.applyWorkspaceId( req, workspace ) ) ),
      );
  }

  private applyWorkspaceId( req: HttpRequest<any>, workspace: WorkspaceLoadedStore ): HttpRequest<any> {
    if ( workspace?.id && PATHS_TO_APPLY_WP_ID.find( path => req.url.includes( path ) ) ) {
      // Logger.info( `setting workspace id in header: ${ workspace.id }` );
      return req.clone( {
        setHeaders: {
          'X-Workspace-Id': String( workspace.id ),
        },
      } );
    }

    return req;
  }
}
