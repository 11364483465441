import { AfterViewInit, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateValidator } from '../../theme/validators';
import moment from 'moment';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../theme/pipes';
import { ExpectedWealthBucket, ExpectedWealthIssue, InvestorInfo, User } from '../../utils/dataInterfaces';
import { BenchmarkState } from '../../utils/benchmark.state';
import { Auth } from '../../auth.service';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { MonthOrYearCurrencyInputsComponent } from '../currencyInput/month-or-year-currency-inputs.component';
import { EVENT_NAMES } from '../../utils/enums';
import { GoalsState } from '../../utils/goals.state';
import { faLightEmergencyOn } from '@fortawesome/pro-light-svg-icons/faLightEmergencyOn';
import { MatDialog } from '@angular/material/dialog';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../shared/workspace-permission';
import { AppStoreService } from '../../store';
import { Moment } from 'moment/moment';
import { BenchmarkUtil, InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GlossaryUtil } from '../../utils/glossary.util';
import { WealthFluentMessageService } from '../../theme/services';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';


@Component( {
  selector: 'rip-investor-info',
  template: `
    <div id="investor-info-container">
      <!--<mat-hint *ngIf="form.valid">All fields (except name) required to compute expected wealth</mat-hint>
      <mat-error *ngIf="form.invalid">All fields (except name) required to compute expected wealth</mat-error>-->
      <div class="planning-sidebar-titles">
        Investor Profile
        <!--    <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                      text="4"
                      class="investor-info-analysis-badge"
                      *ngIf="issues?.length > 0"></nb-badge>-->
        <fa-icon [icon]="married ? faUserFriends : faUser" size="1x" class="profile-icon"></fa-icon>
      </div>
      <form [formGroup]="form">
        <div class="flexing space-around with-gap center-vertically">
          <div class="profile-sub-header">
            <div class="form-group" style="position: relative;">
              <mat-form-field appearance="outline">
                <mat-label>Investor Name</mat-label>
                <input matInput formControlName="investor_name" type="text" (change)="updateInvestorName()">
              </mat-form-field>
            </div>
            <div class="form-group" style="position: relative;">
              <mat-form-field appearance="outline">
                <mat-label>Wealth Portfolio Name</mat-label>
                <input matInput formControlName="wealthPortfolioName" type="text"
                       (change)="updateWealthPortfolioName()">
              </mat-form-field>
            </div>
            <div class="form-group flexing-row-only space-between with-gap" style="position: relative;">
              <mat-form-field appearance="outline" style="flex: 2;">
                <input matInput
                       placeholder="Birth Date (MM/DD/YYYY)"
                       name="birthDate"
                       formControlName="birth_date"
                       [min]="minDate"
                       [max]="today"
                       [matDatepicker]="birthDatepicker"
                       (dateChange)="updateAge()">
                <mat-label>Birth Date (MM/DD/YYYY)</mat-label>
                <mat-datepicker-toggle matSuffix [for]="birthDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #birthDatepicker></mat-datepicker>
                <mat-error *ngIf="form.controls.birth_date.hasError('invalidDate')">
                  Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-hint *ngIf="age" style="margin-top: 8px;">
                  ({{ age | ripDecimalPipe : '0-2' }} years old)
                </mat-hint>
              </mat-form-field>

              <div id="married-toggle-container">
                <div class="onboarding-form-label" *ngIf="married">
                  <div>Married/</div>
                  <div>Partner</div>
                </div>
                <div class="onboarding-form-label" *ngIf="!married">
                  Single
                </div>
                <p-inputSwitch formControlName="married" (onChange)="marriedToggled($event.checked)"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>

        <!-- Portfolio Size -->
        <div class="form-group" style="position: relative;">
          <div style="margin-bottom: 10px;">Investment Portfolio</div>
          <mat-form-field appearance="outline">
            <mat-label>{{ portfolioIsApproximate ? 'Approximate ' : '' }}Investment Portfolio Size</mat-label>
            <input matInput formControlName="portfolioSize" type="text" (change)="updatePortfolioSize()"
                   (keydown.enter)="ignoreEnter($event)">
          </mat-form-field>
          <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                    text="A"
                    class="investor-info-analysis-badge"
                    *ngIf="issues?.length > 0"></nb-badge>

          <div class="form-group">
            <div class="flexing center-vertically with-gap">
              <p-selectButton [options]="stateOptions" formControlName="useLivePortfolio"
                              optionLabel="label" optionValue="value"
                              styleClass="p-button-sm"
                              (onChange)="togglePortfolioSource()"></p-selectButton>
              <fa-icon [icon]="faQuestionCircle" class="disclaimer" size="lg"
                       (mouseover)="op.show($event)"></fa-icon>
              <p-overlayPanel #op>
                <ng-template pTemplate>

                  <div>Investment Portfolio Size Source</div>
                  <p-divider></p-divider>
                  <div class="flex align-items-center" style="width: 300px;">
                    <div>
                      <b>Live</b> draws from your wealth portfolio investments, <b>Manual</b> allows you to enter a
                      number for various planning scenarios
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </div>

          </div>
        </div>
        <!-- <div class="form-group">
           <rip-currency-input [control]="$any(form.controls.minimumCash)" [stepSize]="1000"
                               [label]="'Minimum Cash Reserve'"
                               [noNegative]="true"
                               [placeholder]="'$20,000'"
                               (change)="updateMinimumCash()"></rip-currency-input>
         </div>-->

        <!-- section that can reduce risk of ruin -->
        <div class="form-group" style="position: relative;">

          <div class="form-group" *ngIf="!retired" style="position: relative;">
            <div style="margin-bottom: 10px;">Retirement Date (Net Withdrawals Begin)</div>
            <div class="flexing-row-only space-between with-gap">
              <mat-form-field appearance="outline" style="max-width: 80px;">
                <mat-label>Target Age</mat-label>
                <input matInput formControlName="retirementAge" type="text"
                       (change)="retirementAgeUpdated()" (keydown.enter)="ignoreEnter($event)">
              </mat-form-field>
              <mat-form-field appearance="outline" style="max-width: 80px;">
                <mat-label>Years Until</mat-label>
                <input matInput formControlName="yearsUntilRetirement" type="text"
                       (change)="updateYearsUntilRetirement()" (keydown.enter)="ignoreEnter($event)">
              </mat-form-field>
              <mat-form-field appearance="outline" style="max-width: 175px; padding-right: 5px;">
                <mat-label>Date</mat-label>
                <input matInput formControlName="shiftToWithdrawals"
                       [min]="today"
                       [max]="maxDate"
                       [matDatepicker]="withdrawalDatepicker"
                       (dateChange)="shiftToWithdrawalsUpdated()"
                       (keydown.enter)="ignoreEnter($event)">
                <mat-datepicker-toggle matSuffix [for]="withdrawalDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #withdrawalDatepicker
                                [startAt]="form.controls.shiftToWithdrawals.value"></mat-datepicker>

              </mat-form-field>
            </div>
            <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                      text="B"
                      class="investor-info-analysis-badge"
                      *ngIf="issues?.length > 0"></nb-badge>
          </div>
          <!--Retired?--->
          <div class="form-group" style="position: relative;">
            <div class="flexing-row-only space-between with-gap" style="position: relative;">
              <div style="padding-left: 25px;">
                <div class="onboarding-form-label">Retired?</div>
                <p-inputSwitch formControlName="retired" (onChange)="retiredToggled($event.checked)"></p-inputSwitch>
              </div>

              <mat-form-field appearance="outline" style="max-width: 220px;">
                <mat-label>Years in Retirement</mat-label>
                <input matInput formControlName="lengthOfWithdrawals" type="text"
                       (change)="updateLengthOfWithdrawals()"
                       (keydown.enter)="ignoreEnter($event)">
              </mat-form-field>
              <span class="disclaimer form-disclaimer" (click)="openGlossary( 22 )">
                <fa-icon [icon]="faQuestionCircle" style="position: relative; top: 15px;" size="sm"></fa-icon>
              </span>
              <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                        text="C"
                        class="investor-info-analysis-badge"
                        *ngIf="issues?.length > 0"></nb-badge>
            </div>

          </div>
          <div class="form-group" style="position: relative;">
            <div style="margin-bottom: 10px;">
              Net Withdrawals (Retirement Income)
            </div>
            <rip-month-or-year-currency-inputs #annualWithdrawalsComponent
                                               [showAnnualInput]="true"
                                               [annualLabel]="'Annual'"
                                               [monthlyLabel]="'Monthly'"
                                               [annualPlaceholder]="'$200,000'"
                                               [monthlyPlaceholder]="'$1,800'"
                                               [monthlyFormControl]="$any(form.controls?.monthlyWithdrawals)"
                                               [annualFormControl]="$any(form.controls?.annualWithdrawals)"
                                               [annualStepSize]="10000"
                                               (numbersUpdated)="updateAnnualWithdrawals()">

            </rip-month-or-year-currency-inputs>
            <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                      text="D"
                      class="investor-info-analysis-badge"
                      *ngIf="issues?.length > 0"></nb-badge>
          </div>
          <!-- WITHDRAWALS TO LIVE OFF-->
          <div class="form-group" style="position: relative;">
            <div class="flexing-row-only space-between"
                 style="margin-top: -15px; margin-bottom: 15px; font-size: 0.80rem;"
                 *ngIf="!selectedBucket?.totalRetirementGoalsWithdrawals">
              <div>
                <b>Note:</b> {{ selectedBucket?.withdrawalsToLiveOff | ripCurrencyPipe }}
                is maximum to live off expected returns
              </div>

              <div (click)="useLevelToLiveOff()" class="rip-link">Use This</div>
            </div>
          </div>
          <div class="form-group" style="position: relative;" *ngIf="!retired">
            <div style="margin-bottom: 10px;">
              Retirement Contributions/Savings
            </div>
            <rip-month-or-year-currency-inputs
              #annualContributionsComponent
              [showAnnualInput]="true"
              [annualLabel]="'Annual'"
              [monthlyLabel]="'Monthly'"
              [annualPlaceholder]="'$19,000'"
              [monthlyPlaceholder]="'$1,500'"
              [monthlyFormControl]="$any(form.controls?.monthlyContributions)"
              [annualFormControl]="$any(form.controls?.annualContributions)"
              [calcAnnualNumberAfterLoad]="formHasBeenSetupSinceBenchmarkLoad"
              (numbersUpdated)="updateAnnualContributions()">

            </rip-month-or-year-currency-inputs>
            <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'"
                      text="E"
                      class="investor-info-analysis-badge"
                      *ngIf="issues?.length > 0"></nb-badge>
          </div>
          <div class="form-group" *ngIf="!retired">
            <div class="flexing-row-only space-between"
                 style="margin-top: -15px; margin-bottom: 15px; font-size: 0.80rem;"
                 *ngIf="!selectedBucket?.totalRetirementGoalsWithdrawals">
              <div>
                <b>Note:</b> {{ savingsAnnuityUntilRetirement | ripCurrencyPipe }}
                is the expected monthly savings until your retirement date to attain your chosen
                withdrawals in
                retirement, based on your selected strategic asset allocation (benchmark).
              </div>

              <div (click)="useSavingsAnnuity()" class="rip-link">Use This</div>
            </div>
          </div>

          <div *ngIf="emergencyFundGoal" class="flexing-row-only center-vertically" style="gap: 5%;">
            <button mat-raised-button (click)="openEmergencyFundGoal()" class="icon-button"
                    matTooltip="Edit Cash Reserve" matTooltipClass="mat-tooltip-custom">
              <fa-icon [icon]="faLightEmergencyOn" fixedWidth></fa-icon>
            </button>

            <div>{{ emergencyFundGoal.name }}: {{ emergencyFundGoal.total_withdrawal | ripCurrencyPipe }}
            </div>
          </div>
        </div>


        <!--Married?--->
        <!--<div class="onboarding-form-label">Married</div>
        <nb-toggle #marriedToggle formControlName="married">
        </nb-toggle>
      </div>
    </div>-->
        <!-- END OF FORM -->
      </form>
    </div>
  `,
  styleUrls: [ './investor-info.component.scss' ],
} )

export class InvestorInfoComponent extends WithWorkspacePermission implements OnInit, AfterViewInit, OnDestroy {

  private refresher$$: BehaviorSubject<void> = new BehaviorSubject<void>( null );
  private readonly onDestroy: Subject<void> = new Subject<void>();

  protected readonly faQuestionCircle: IconDefinition = faQuestionCircle;

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  @Input() selectedBucket: ExpectedWealthBucket;
  @Input() savingsAnnuityUntilRetirement: number;
  @Input() issues: ExpectedWealthIssue[];
  @Input() ignoreOnboarding: boolean = false;
  @Input() actionsAvailable: boolean;
  @Output() infoChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild( 'annualContributionsComponent' ) annualContributionsComponent: MonthOrYearCurrencyInputsComponent;
  @ViewChild( 'annualWithdrawalsComponent' ) annualWithdrawalsComponent: MonthOrYearCurrencyInputsComponent;


  faUser = faUser;
  faUserFriends = faUserFriends;
  faLightEmergencyOn = faLightEmergencyOn;

  form: UntypedFormGroup = new UntypedFormGroup( {
    'retired': new FormControl<boolean>( null ),
    'portfolioSize': new FormControl<string>( null ),
    'useLivePortfolio': new FormControl<string>( null ),
    'shiftToWithdrawals': new FormControl<string>( null ),
    'annualWithdrawals': new FormControl<string>( null ),
    'monthlyWithdrawals': new FormControl<string>( null ),
    'annualContributions': new FormControl<string>( null ),
    'monthlyContributions': new FormControl<string>( null ),
    'lengthOfWithdrawals': new FormControl<string>( null ),
    'yearsUntilRetirement': new FormControl<string>( null ),
    'retirementAge': new FormControl<string>( null ),
    'probability': new FormControl<string>( null ),
    'minimumCash': new FormControl<string>( null ),
    'married': new FormControl<boolean>( null ),
    'birth_date': new FormControl<Date>( null ),
    'investor_name': new FormControl<string>( null ),
    'wealthPortfolioName': new FormControl<string>( null ),
  } );

  name: string;
  age: number;
  retired: boolean;
  married: boolean;
  portfolioIsApproximate: boolean = true;
  actualInvestmentTotal: number;
  user: User;

  minDate: any = moment().subtract( 120, 'years' ).toDate();
  today: any = moment().toDate();
  maxDate: any = moment().add( 100, 'years' ).toDate();

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  hasEditedPortfolioSize: boolean = false;
  subscriberName: string = 'investorInfoComponent';

  emergencyFundGoal: InvestorGoal;
  // this is necessary so that we know when to have the month-or-year-currency-input component for the annual
  // contributions automatically recalculate annual number from monthly number. without this, the annual contributions
  // gets overwritten incorrectly on first load
  formHasBeenSetupSinceBenchmarkLoad: boolean = false;

  stateOptions: any[] = [
    { label: 'Live', value: 'live' },
    { label: 'Manual', value: 'manual' },
  ];

  // portfolioSizeLocked: boolean = false;

  constructor( public benchmarkState: BenchmarkState,
               public goalsState: GoalsState,
               private _auth: Auth,
               private _state: GlobalState,
               public dialog: MatDialog,
               private appStoreService: AppStoreService,
               private glossaryUtil: GlossaryUtil,
  ) {
    super();

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_investorInfo' ] = this;
    }
    this.subscriberName = `${ this.subscriberName }_${ Util.generateRandomString( 4 ) }`;

    _state.subscribe( 'benchmark.investment.total.updated', ( newTotal ) => {
      this.form.controls.portfolioSize.setValue( newTotal );
      Util.updateInputCurrencyFormat( 'portfolioSize', this.form );
      this.updateExpectedWealth();
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.USER_BENCHMARK_LOADED, () => {
      this.formHasBeenSetupSinceBenchmarkLoad = false;
      setTimeout( () => {

        this.setupForm();
        this.formHasBeenSetupSinceBenchmarkLoad = true;
      } );
    }, this.subscriberName );

    this.emergencyFundGoal = this.goalsState.getEmergencyFundGoal();

    goalsState.update.subscribe( {
      next: () => {
        this.emergencyFundGoal = this.goalsState.getEmergencyFundGoal();
      },
    } );
  }

  ngOnInit(): void {
    this.applyWorkspacePermissions();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.checkForData();
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [ 'benchmark.investment.total.updated' ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
    this.onDestroy.complete();
    this.refresher$$.complete();
  }

  checkForData() {
    if ( ( this.benchmarkState.userBenchmarkLoaded &&
        ( this._state.globalVars?.firstAccountPullComplete || this._state.globalVars?.accountsLoadingError ) )
      || this.benchmarkState.doingOnboarding ) {
      this.formHasBeenSetupSinceBenchmarkLoad = false;
      this.setupForm();
      this.formHasBeenSetupSinceBenchmarkLoad = true;
    } else {
      setTimeout( () => {
        this.checkForData();
      }, 1000 );
    }
  }

  setupForm() {
    this.name = '';
    this.retired = false;
    this.married = false;
    this.actualInvestmentTotal = this.benchmarkState.getInvestmentTotal();
    this.portfolioIsApproximate = this.actualInvestmentTotal === 0;
    let startingInvestmentTotal: number;

    // TODO: add ability to switch between investment portfolio and net worth number

    let retirementAge: string;

    const birthDate = this.benchmarkState?.onboardingData?.birth_date ?? this.benchmarkState?.benchmark?.birth_date;

    if ( this._auth.authenticated() ) { // this should always be the case with the flow we are taking now for onboarding
      this.user = Util.getLoggedInUser( this._auth ); // the benchmarkState onboardingData is a better source
      this.name = ( this.benchmarkState?.onboardingData?.name ?? this.benchmarkState?.benchmark?.investor_name ) ?? '';
      this.age = Math.floor( moment().diff( moment( birthDate ), 'years', true ) );
      retirementAge = this.calcRetirementAge();
      this.retired = BenchmarkUtil.isInvestorRetired( this.benchmarkState.benchmark );
      if ( this.retired ) {
        this.benchmarkState.benchmark.shiftToWithdrawals = null;
        this.benchmarkState.benchmark.annualContributions = null;
      }
      this.married = !!( this.benchmarkState.onboardingData?.married ?? this.benchmarkState?.benchmark?.married );
      // need to ignore onboarding from benchmark layout
      startingInvestmentTotal = ( this.ignoreOnboarding ? null : this.benchmarkState?.onboardingData?.portfolioSize ) ??
        this.benchmarkState?.benchmark?.startingPortfolioSize ?? this.actualInvestmentTotal ?? 500000;
    } else {
      startingInvestmentTotal = this.portfolioIsApproximate ? ( this.benchmarkState?.onboardingData?.portfolioSize ?? BenchmarkUtil.defaultStartingPortfolioSize ) : this.actualInvestmentTotal;
    }

    // could probably skip this info object and just do it all in the form init step, but this helps make the formatting step easier
    const info: InvestorInfo = {
      portfolioSize: startingInvestmentTotal,
      shiftToWithdrawals: this.benchmarkState.benchmark.shiftToWithdrawals ? moment( this.benchmarkState.benchmark.shiftToWithdrawals ).utc() : null,
      annualContributions: this.benchmarkState.benchmark.annualContributions || BenchmarkUtil.defaultAnnualContributions,
      annualWithdrawals: this.benchmarkState.benchmark.annualWithdrawals || BenchmarkUtil.defaultAnnualWithdrawals,
      lengthOfWithdrawals: this.benchmarkState.benchmark.lengthOfWithdrawals,
      yearsUntilRetirement: this.benchmarkState.benchmark.shiftToWithdrawals ? moment( this.benchmarkState.benchmark.shiftToWithdrawals ).utc().diff( moment(), 'years', true ) : null,
      minimumCash: this.benchmarkState.benchmark.minimumCash,
      probability: this.benchmarkState.benchmark.probability ?? 0.05,
      endOfPortfolioDate: this.benchmarkState.benchmark.endOfPortfolioLife ?? moment().add( 50, 'years' ),
    }; /*);*/

    if ( !info.lengthOfWithdrawals && !this.retired && info.endOfPortfolioDate ) {
      info.lengthOfWithdrawals = moment( info.endOfPortfolioDate ).diff( moment( info.shiftToWithdrawals ), 'years', true );
    }

    this.form = new UntypedFormGroup( {
      'retired': new FormControl<boolean>( this.retired ),
      'portfolioSize': new FormControl<string>( this.ripCurrencyPipe.transform( info.portfolioSize ), Validators.required ),
      'useLivePortfolio': new FormControl<string>( this.benchmarkState.benchmark.startingPortfolioSize ? 'manual' : 'live' ),
      'shiftToWithdrawals': new FormControl<string>( info.shiftToWithdrawals, Validators.compose( ( this.retired ? [] : [ DateValidator(), Validators.required ] ) ) ),
      'annualWithdrawals': new FormControl<string>( this.ripCurrencyPipe.transform( info.annualWithdrawals ), Validators.required ),
      'monthlyWithdrawals': new FormControl<string>( this.ripCurrencyPipe.transform( ( info.annualWithdrawals ?? 0 ) / 12 ) ),
      'annualContributions': new FormControl<string>( this.ripCurrencyPipe.transform( info.annualContributions ), ( this.retired ? [] : Validators.required ) ),
      'monthlyContributions': new FormControl<string>( this.ripCurrencyPipe.transform( ( info.annualContributions ?? 0 ) / 12 ) ),
      'lengthOfWithdrawals': new FormControl<string>( this.ripDecimalPipe.transform( info.lengthOfWithdrawals, '0-2' ), Validators.required ),
      'yearsUntilRetirement': new FormControl<string>( info.yearsUntilRetirement ? ( this.ripDecimalPipe.transform( info.yearsUntilRetirement, '0-2' ) ) : '', ( this.retired ? [] : Validators.required ) ),
      'retirementAge': new FormControl<string>( retirementAge, ( this.retired ? [] : Validators.required ) ),
      'minimumCash': new FormControl<string>( this.ripCurrencyPipe.transform( info.minimumCash ) ),
      'probability': new FormControl<string>( this.ripPercentPipe.transform( info.probability, '2-2' ), Validators.required ),
      'married': new FormControl<boolean>( this.married ),
      'birth_date': new FormControl<Date>( birthDate, Validators.required ),
      'investor_name': new FormControl<string>( this.benchmarkState?.benchmark?.investor_name || this.user?.name ),
      'wealthPortfolioName': new FormControl<string>( this.benchmarkState?.benchmark?.wealthPortfolioName ),
    } );

    this.togglePortfolioSource();
    this.annualContributionsComponent?.init( info.annualContributions );
    this.annualWithdrawalsComponent?.init( info.annualWithdrawals );
    console.log( 'this.form.value' );
    console.log( this.form.value );
    // this should only matter for existing users that haven't gone through the onboarding setup
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.updateExpectedWealth();
    this.refresher$$.next();
    console.log( this.form.value );
  }

  updatePortfolioSize() {
    // this.benchmarkState.benchmark.;
    Util.updateInputCurrencyFormat( 'portfolioSize', this.form );
    this.benchmarkState.benchmark.startingPortfolioSize = Util.getFormValueAsNumber( this.form, 'portfolioSize' );
    this.updateExpectedWealth();
  }

  updateAnnualContributions() {
    // Util.updateInputCurrencyFormat( 'annualContributions', this.form );
    this.benchmarkState.benchmark.annualContributions = Util.getFormValueAsNumber( this.form, 'annualContributions' );
    this.updateExpectedWealth();
  }

  /*  updateAnnualSavings() {
   this.benchmarkState.benchmark.annualSavings = this.getFormValueAsNumber( 'annualSavings' );
   this.updateExpectedWealth();
   }*/

  retirementAgeUpdated() {
    // todo: add more validation to these fields. need at least a max age, but maybe also a number validation for this one in particular
    this.benchmarkState.benchmark.shiftToWithdrawals = moment( this.benchmarkState.benchmark.birth_date ).add( Util.getFormValueAsNumber( this.form, 'retirementAge' ), 'years' );
    this.form.controls.shiftToWithdrawals.setValue( this.benchmarkState.benchmark.shiftToWithdrawals );
    this.form.controls.yearsUntilRetirement.setValue( this.ripDecimalPipe.transform(
        Math.abs(
          moment()
            .diff(
              this.getFormValueAsDate( 'shiftToWithdrawals' ), 'years', true ) ), '0-2',
      ),
    );
    // let lengthOfWithdrawals = Util.getFormValueAsNumber( this.form, 'lengthOfWithdrawals' );
    const { lengthOfWithdrawals, retirementDate } = BenchmarkUtil.calcExpectedLifeSpan( this.form.getRawValue() );
    this.form.controls.lengthOfWithdrawals.setValue( new RipsawDecimalPipe().transform( lengthOfWithdrawals, '0-2' ) );
    this.form.controls.shiftToWithdrawals.setValue( retirementDate );
    this.setEndOfPortfolioLife( lengthOfWithdrawals );
    this.updateExpectedWealth();
  }

  shiftToWithdrawalsUpdated() {
    this.benchmarkState.benchmark.shiftToWithdrawals = this.formatDateForSerialization( this.form.controls.shiftToWithdrawals.value );
    this.form.controls.yearsUntilRetirement.setValue( this.ripDecimalPipe.transform(
        Math.abs(
          moment()
            .diff(
              this.getFormValueAsDate( 'shiftToWithdrawals' ), 'years', true ) ), '0-2',
      ),
    );
    const lengthOfWithdrawals = Util.getFormValueAsNumber( this.form, 'lengthOfWithdrawals' );
    this.setEndOfPortfolioLife( lengthOfWithdrawals );
    this.form.controls.retirementAge.setValue( this.calcRetirementAge() );
    this.updateExpectedWealth();
  }

  updateYearsUntilRetirement() {
    const yearsToRetirement: number = Util.getFormValueAsNumber( this.form, 'yearsUntilRetirement' );
    if ( yearsToRetirement > 0 ) {
      this.form.controls.shiftToWithdrawals.setValue( moment().add( yearsToRetirement, 'years' ) );
    }
    this.shiftToWithdrawalsUpdated();
  }

  updateAnnualWithdrawals() {
    // Util.updateInputCurrencyFormat( 'annualWithdrawals', this.form );
    this.benchmarkState.benchmark.annualWithdrawals = Util.getFormValueAsNumber( this.form, 'annualWithdrawals' );
    this.updateExpectedWealth();
  }

  updateLengthOfWithdrawals() {
    this.benchmarkState.benchmark.lengthOfWithdrawals = Util.getFormValueAsNumber( this.form, 'lengthOfWithdrawals' );
    this.setEndOfPortfolioLife( this.benchmarkState.benchmark.lengthOfWithdrawals );
    this.updateExpectedWealth();
  }

  setEndOfPortfolioLife( lengthOfWithdrawals: number ) {
    if ( lengthOfWithdrawals && lengthOfWithdrawals > 0 ) {
      let endOfPortfolioLife: moment.Moment;
      if ( this.retired ) {
        endOfPortfolioLife = moment().add( lengthOfWithdrawals, 'years' );
      } else {
        endOfPortfolioLife = moment( this.getFormValueAsDate( 'shiftToWithdrawals' ) ).add( lengthOfWithdrawals, 'years' );
      }
      this.benchmarkState.benchmark.endOfPortfolioLife = endOfPortfolioLife.toDate();
    }
  }

  getFormValueAsDate( controlName: string ) {
    return moment( this.form.controls[ controlName ].value );
  }

  formatDateForSerialization( date: any ) {
    return moment( date ).format( 'YYYY-MM-DD' );
  }

  updateExpectedWealth() {
    if ( this.form.valid || this.form.disabled ) {
      if ( !this.benchmarkState.benchmark.endOfPortfolioLife ) {
        const lengthOfWithdrawals = Util.getFormValueAsNumber( this.form, 'lengthOfWithdrawals' );
        this.setEndOfPortfolioLife( lengthOfWithdrawals );
      }
      this.benchmarkState?.setupExpectedWealthDataForSelectedFrontierPoint();
      this.benchmarkState?.setupExpectedWealthBuckets();
      // this.infoChanged.emit( { portfolioSizeChanged: newPortfolioValue ? this.getFormValueAsNumber( 'portfolioSize' ) : undefined } );
      this.benchmarkState.hideWealthChart = false;
    } else {
      this.benchmarkState.hideWealthChart = true;
    }
  }

  useLevelToLiveOff() {
    this.form.controls.annualWithdrawals.setValue( this.ripCurrencyPipe.transform( this.selectedBucket?.withdrawalsToLiveOff ) );
    this.form.controls.monthlyWithdrawals.setValue( this.ripCurrencyPipe.transform( this.selectedBucket?.withdrawalsToLiveOff / 12 ) );
    this.updateAnnualWithdrawals();
  }

  useSavingsAnnuity() {
    this.form.controls.monthlyContributions.setValue( this.ripCurrencyPipe.transform( this.savingsAnnuityUntilRetirement ) );
    this.form.controls.annualContributions.setValue( this.ripCurrencyPipe.transform( this.savingsAnnuityUntilRetirement * 12 ) );
    this.updateAnnualContributions();
  }

  retiredToggled( retired: boolean ) {
    this.form.controls.retired.setValue( retired );
    this.retired = retired;
    if ( this.benchmarkState.doingOnboarding ) {
      this.benchmarkState.onboardingData.retired = retired;
    } else {
      this.benchmarkState.benchmark.retired = retired;
    }
    if ( retired ) {
      // remove validators from unnecessary fields
      this.form.controls.yearsUntilRetirement.setValidators( [] );
      this.form.controls.annualContributions.setValidators( [] );
      this.form.controls.shiftToWithdrawals.setValidators( [] );
      this.form.controls.retirementAge.setValidators( [] );
      // set unnecessary fields to null
      this.form.controls.yearsUntilRetirement.setValue( null );
      this.form.controls.shiftToWithdrawals.setValue( null );
      this.form.controls.annualContributions.setValue( null );
      // set the two benchmark fields to undefined
      this.benchmarkState.benchmark.shiftToWithdrawals = null;
      this.benchmarkState.benchmark.annualContributions = null;
    } else {
      this.form.controls.shiftToWithdrawals.setValidators( [ DateValidator(), Validators.required ] );
      this.form.controls.yearsUntilRetirement.setValidators( Validators.required );
      this.form.controls.annualContributions.setValidators( Validators.required );
      this.form.controls.retirementAge.setValidators( Validators.required );
      this.retirementAgeUpdated();
    }
    this.form.controls.yearsUntilRetirement.updateValueAndValidity();
    this.form.controls.annualContributions.updateValueAndValidity();
    this.updateExpectedWealth();
  }

  marriedToggled( married: boolean ): void {
    this.form.controls.married.setValue( married );
    this.married = married;
    this.benchmarkState.benchmark.married = this.extractValueByKey( 'married' );
    // might do more here at some point, like tell the user to add their spouse if they haven't already, etc
  }

  ignoreEnter( event: any ) {
    event?.preventDefault();
    event?.target?.blur();
  }

  calcRetirementAge(): string {
    const retirementDate: Moment = moment( this.form.controls.shiftToWithdrawals.value ?? this.benchmarkState.benchmark?.shiftToWithdrawals );
    const birthDate: Moment = moment( this.form.controls.birth_date.value ?? this.benchmarkState.benchmark?.birth_date );
    const diff = retirementDate.diff( birthDate, 'years', true );
    let diffString = diff.toFixed( 2 );
    if ( isNaN( parseFloat( diffString ) ) || diff === 0 ) {
      diffString = `${ BenchmarkUtil.defaultRetirementAge }`;
    }
    return diffString;
  }

  openEmergencyFundGoal(): void {
    if ( this.emergencyFundGoal ) { // just to be sure
      this.wealthFluentMessageService.sendOpenEditGoalMessage( this.emergencyFundGoal );
    }
  }

  updateAge(): void {
    if ( !this.form.controls.birth_date?.hasError( 'invalidDate' ) ) {
      this.age = moment( this.today ).diff( moment( this.form.controls.birth_date.value ), 'years', true );

      const val = this.extractValueByKey( 'birth_date' );
      this.benchmarkState.benchmark.birth_date = val ? this.formatDateForSerialization( val ) : null;
      this.benchmarkState.investorsCurrentAge = this.age;

      this.retirementAgeUpdated();
    }
  }

  updateInvestorName(): void {
    this.benchmarkState.benchmark.investor_name = this.extractValueByKey( 'investor_name' );
  }

  updateWealthPortfolioName(): void {
    this.benchmarkState.benchmark.wealthPortfolioName = this.extractValueByKey( 'wealthPortfolioName' );
  }

  togglePortfolioSource() {
    if ( this.form.controls.useLivePortfolio.value === 'live' ) {
      this.resetPortfolioSize();
      this.form.controls.portfolioSize.disable();
    } else {
      this.form.controls.portfolioSize.enable();
    }
  }

  resetPortfolioSize(): void {
    this.benchmarkState.benchmark.startingPortfolioSize = undefined;
    this.form.controls.portfolioSize.setValue( this.benchmarkState.investmentTotalCached );
    Util.updateInputCurrencyFormat( 'portfolioSize', this.form );
    this.updateExpectedWealth();
  }

  private extractValueByKey( key: string ) {
    return this.form.controls[ key ]?.value || null;
  }

  private applyWorkspacePermissions(): void {
    combineLatest( [
      this.appStoreService.loadedWorkspacePermissions$,
      this.refresher$$.asObservable(),
    ] )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( [ allPerms ] ) => {
        const hasPermissions = hasAnyPermissionFn( allPerms, [ this.perm.OWNER, this.perm.INVESTOR_PROFILE_EDIT ] );
        if ( this.actionsAvailable || hasPermissions ) {
          this.form.enable();
        } else {
          this.form.disable();
        }
      } );
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

}
