import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../auth-guard.service';
import { BalanceSheetComponent } from './balanceSheet';
import { MarketInformationDashboardComponent } from '../reusableWidgets/marketInformationDashboard';
import { environment } from '../../environments/environment';
import { WorkspacesGuard } from '../workspaces.guard';
import { WorkspacePermission, WorkspacePermissionGuard } from '../shared/workspace-permission';
import { WorkspacesDashboardGuard } from '../workspaces-dashboard.guard';
import { PAGE_NAMES } from '../utils/enums';
import { SomethingWentWrongComponent } from './something-went-wrong/something-went-wrong.component';


export const routes: Routes = [
  {
    path: 'something-went-wrong',
    component: SomethingWentWrongComponent,
    title: `${ environment.appName } - Oops`, // this shouldn't really be shown because it will only happen in an iFrame
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginModule ),
    title: `${ environment.appName } - Login`,
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterModule ),
    title: `${ environment.appName } - Register`,
  },
  {
    path: 'market-info',
    loadChildren: () => import('./public-market-info/public-market-info.module').then( m => m.PublicMarketInfoModule ),
    title: `${ environment.appName } - ${ PAGE_NAMES.MARKET_INFORMATION_DASHBOARD }`,
  },
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [ WorkspacesGuard ],
    children: [
      {
        path: '',
        redirectTo: 'accounts',
        pathMatch: 'full',
      },
      {
        path: 'balanceSheet',
        // loadChildren: () => import('./balanceSheet/balanceSheet.module').then(m => m.BalanceSheetModule),
        component: BalanceSheetComponent,
        canActivate: [ AuthGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.BALANCE_SHEET }`,
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then( m => m.AccountsModule ),
        canActivate: [ AuthGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.ACCOUNTS }`,
      },
      {
        path: 'holdings',
        loadChildren: () => import('./holdings/holdings.module').then( m => m.HoldingsModule ),
        canActivate: [ AuthGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.HOLDINGS }`,
      },
      {
        path: 'market-information-dashboard',
        component: MarketInformationDashboardComponent,
        canActivate: [ AuthGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.MARKET_INFORMATION_DASHBOARD }`,
      },
      {
        path: 'benchmark',
        loadChildren: () => import('./benchmark-setup/benchmark-setup.module').then( m => m.BenchmarkSetupModule ),
        canActivate: [ AuthGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.PLAN_BENCHMARK }`,
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then( m => m.TransactionsModule ),
        canActivate: [ AuthGuard, WorkspacePermissionGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.TRANSACTIONS }`,
        data: {
          permissions: [ WorkspacePermission.OWNER, WorkspacePermission.TRANSACTIONS_VIEW ],
        },
      },
      {
        path: 'workspaces',
        loadChildren: () => import('./workspaces/workspaces.module').then( m => m.WorkspacesModule ),
        canActivate: [ AuthGuard, WorkspacesDashboardGuard ],
        title: `${ environment.appName } - ${ PAGE_NAMES.WORKSPACES }`,
      },
    ],
  },
];

export const routing: ModuleWithProviders<unknown> = RouterModule.forChild( routes );
