import { Injectable, OnDestroy, QueryList } from '@angular/core';
import { GlobalDataService, PricingService } from '../globalData';
import { GlobalState } from '../global.state';
import { Auth } from '../auth.service';
import * as _ from 'lodash-es';
import { Util } from './util.service';
import { MortgageHelpers } from './mortgageHelpers';
import { AlertModalService } from '../reusableWidgets/alertModal';
import moment from 'moment';
import { BondHelpers } from './bondHelpers';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataQualityChecker } from './dataQualityChecker';
import { OneDayChangeUtil } from './oneDayChangeUtil';
import { Logger } from './logger.service';
import {
  AccountRefreshStatusItem,
  Connection,
  GenericError,
  GlobalOverride,
  NotificationIssue,
  RipsawAPIGenericResponse,
  Security,
} from './dataInterfaces';
import { Account, Position } from '@ripsawllc/ripsaw-analyzer';
import { InstitutionIconComponent } from '../reusableWidgets/institutionIcon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OverridesService } from '../globalData/overrides.service';
import { environment } from '../../environments/environment';
import { ManualAccountUtil } from './manualAccount.util';
import { TransactionsState } from './transactions.state';
import { EVENT_NAMES } from './enums';
import { AccountsComponent } from '../pages/accounts/accounts.component';
import { AppStoreService } from '../store';
import { TreasuryRatesUtil } from './treasury-rates.util';

@Injectable()
export class AccountManager implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.LOGOUT, this.subscriberName );
    this.onDestroy.next();
  }

  newlyAddedConnections: any = {};

  accountsRefreshing: any = {};
  maxAccountRefreshChecks: number = 20; // number of tries (20 tries is ~30 seconds if interval is 1.5 seconds)
  accountRefreshPollingInterval: number = 1500; // in milliseconds
  refreshRetryLength: number = 60; // in seconds
  maxSyncIntervalRetries: number = 5; // 5 tries is about 5 minutes

  subscriberName: string = 'AccountManager';
  currentWorkspaceIsPrimary: boolean = true;

  overriddenSecuritiesBeingRetrieved: number = 0;
  needWFToRefreshPrices: Subject<void> = new Subject<void>();
  allDataRetrieved: Subject<boolean> = new Subject<boolean>();
  isSyncingOnLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  /* For ordering the account categories in the account rollup */
  categoryOrder: any = {
    Investment: 0,
    Crypto: 1,
    Banking: 2,
    Vehicle: 3,
    'Real Estate': 4,
    'Valuable(s)': 5,
    Other: 6,
    Alternative: 7,
    'Limited Partnership': 8,
    Misc: 9,
    Insurance: 10,
    Loan: 11,
    Unknown: 12,
  };

  constructor(
    private _gdService: GlobalDataService,
    private _overridesService: OverridesService,
    private _state: GlobalState,
    private _auth: Auth,
    private _pricingService: PricingService,
    private _alertService: AlertModalService,
    public snackBar: MatSnackBar,
    private transactionsState: TransactionsState,
    private _appStoreService: AppStoreService,
    private treasuryRatesUtil: TreasuryRatesUtil,
  ) {
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_accountManager' ] = this;
    }

    this._state.subscribe(
      EVENT_NAMES.LOGOUT,
      () => {
        this.turnOffAllRefreshes();
      },
      this.subscriberName,
    );

    this._appStoreService.loadedWorkspaceIsPrimary$.subscribe( {
      next: ( isPrimary: boolean ) => {
        this.currentWorkspaceIsPrimary = isPrimary;
      },
    } );
  }

  // this is called first
  /*
   * Temporary Function to get our list of mock securities. This is the starting point currently for retrieving the data.
   * Once we start paying for security data, we will have this merged in on the backend
   * */
  entryPoint() {
    if ( !this._state.globalVars.inWealthFluent ) {
      // wealthfluent will send the same data once it has retrieved and processed it. no need to do it twice
      const self = this;
      this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 15 );
      self.getAccounts().catch( err => {
        this.showAggregatorErr( err );
      } );
    } else {
      Logger.log( 'In WF, just get transactions' );
      this.transactionsState.getTransactionCount();
    }
  }

  // this is called second
  /*
   * Function for retrieving account data
   * */
  async getAccounts() {
    this.overriddenSecuritiesBeingRetrieved = 0;
    this.allDataRetrieved.next( false );
    this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 25 );
    const self = this;
    self._state.globalVars.allAccounts = [];
    self._state.globalVars.loadingAccounts = true;
    if ( this.currentWorkspaceHasConnectedAccounts() ) {
      try {
        await self.getAllAccountsWithPositionsFromAggregator();
      } catch ( err ) {
        this.showAggregatorErr( err );
      }
    } else {
      this.getManualAccounts( [] );
    }
  }

  /*
   * Function for getting account and position data from the aggregator lambdas
   * */
  async getAllAccountsWithPositionsFromAggregator() {
    this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 50 );
    const self = this;
    if ( this.currentWorkspaceHasConnectedAccounts() ) {
      self._gdService
        .getAllAggAccountsWithPositions()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( allAccountsResp: any ) => {
            const aggregatorAccounts: Account[] = allAccountsResp.data;
            this.getManualAccounts( aggregatorAccounts );
          },
          error: ( err: GenericError ) => {
            this.showAggregatorErr( err );
          },
        } );
    } else {
      this.getManualAccounts( [] );
    }
  }

  getManualAccounts( aggAccounts: Account[] ) {
    const self = this;
    self._gdService
      .getManualAccounts()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( manualAccountsResp: any ) => {
          const manualAccounts: Account[] = manualAccountsResp.data;
          for ( const a of manualAccounts ) {
            ManualAccountUtil.setIcon( a );
            a.formattedDescription = this.formatAccountDescription( a );
          }
          self.setupAccountData( aggAccounts, manualAccounts );
        },
        error: err => {
          this.showManualAccountsErr( err );
        },
      } );
  }

  /**
   * Function for setting up normal user account data
   * @param aggAccounts {Array} - accounts retrieved from the account aggregator
   * @param manualAccounts {Array} - manual accounts retrieved from our own backend
   * */
  setupAccountData( aggAccounts: Account[], manualAccounts: Account[] ) {
    this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 80 );
    const self = this;
    self._state.globalVars.allAccounts = aggAccounts;
    self._state.globalVars.allManualAccountsFromDB = manualAccounts;
    self._state.globalVars.allManualAccounts = _.cloneDeep( manualAccounts );
    // need this clone so we don't alter the complete set (allManualAccountFromDB)
    _.remove( self._state.globalVars.allManualAccounts, ( a: any ) => {
      return !a.included;
    } );
    self.categorizeAccounts( false );
    this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 90 );
    this.getConnections();
  }

  /* when reload is true, the second half of the function that sets extra metadata is ignored*/

  /**
   * Function for setting up the accounts in a specific ordering by category
   * @param reload {Boolean} - tells the function whether this is an initial load or just a reload of the
   * account ordering. Mostly used when removing accounts based on the mapping
   * */
  categorizeAccounts( reload: boolean ) {
    const self = this;
    self._state.globalVars.accountCategories = [];

    for ( const cat of Object.keys( this.categoryOrder ) ) {
      self._state.globalVars.accountCategories[ this.categoryOrder[ cat ] ] = {
        categoryTitle: cat,
        accounts: [],
        totalValue: 0,
      };
    }

    for ( const a of [
      ...self._state.globalVars.allAccounts,
      ...self._state.globalVars.allManualAccounts,
    ] ) {
      // put accounts into ordered categories
      let cat = a.account_category;
      if ( cat === 'Other' ) {
        cat = a.account_type;
      }
      /*
       if we wanted credit cards to be in loan instead of banking
       if ( cat === 'Banking' && a.account_type === 'Credit Card') {
       cat = 'Loan';
       }*/
      if ( isNaN( this.categoryOrder[ cat ] ) ) {
        cat = 'Other';
      }
      const order = this.categoryOrder[ cat ];
      if ( !self._state.globalVars.accountCategories[ order ] ) {
        self._state.globalVars.accountCategories[ order ] = {
          categoryTitle: cat,
          accounts: [],
          totalValue: 0,
        };
      }

      if ( !reload ) {
        a.cashFromOtherAccounts = 0;
        a.transferTargetAccounts = {};
        a.transferSourceAccounts = {};
        a.secTransfersTargetAccounts = [];
        a.open = false;
      }

      if ( this.wasAccountNewlyAdded( a.connection_id ) ) {
        a.newlyAdded = true;
      }

      self._state.globalVars.accountCategories[ order ].accounts.push( a );
      self._state.globalVars.accountCategories[ order ].totalValue += a.value;
    }
  }

  /*
   * Function for finishing up the data refresh (or initial load)
   * */
  finishRefresh( connectionsResponse?: any ) {
    this._state.globalVars.dataQualityIssues = [];
    this.scanPositionsForMissingData(
      this.getAllPositionsIncludingManualAccounts(),
    );
    this.addRevisedFields( [
      ...this.getAllPositions(),
      ...this.getAllManualPositions(),
    ] );
    this.makeCopiesOfAccountLists( true );
    if ( connectionsResponse && connectionsResponse.data ) {
      this.setupConnectionData( connectionsResponse );
    }
    this.removeAccountsNotIncluded();
    // start the call for transactions now that we have the account mapping and included accounts only.
    // this should also happen each time accounts are refreshed so the right set of full transactions are in memory
    this.transactionsState.getTransactionCount();
    this._state.globalVars.firstAccountPullComplete = true;
    this._state.notifyDataChanged(
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      {},
    );
    this._state.notifyDataChanged( EVENT_NAMES.PROGRESS_UPDATE, 100 );
    if (!this._state.globalVars.inWealthFluent) {
      this.updateManualAccounts();
      this.refreshPrices();
    }
    // need this here in case there were no overridden securities, and we won't get to the same check in the mergeRetrievedData function
    if ( this.overriddenSecuritiesBeingRetrieved === 0 ) {
      if ( !this.isSyncingOnLogin.value ) {
        this.allDataRetrieved.next( true );
      }
    }
  }

  showAggregatorErr( err: GenericError ) {
    console.error( err.err );
    this._state.notifyDataChanged( EVENT_NAMES.ACCOUNTS_LOADING_ERROR, null );
    this._alertService.showAlert( {
      title: 'Error Connecting To Our Aggregator',
      message: `We had a problem connecting to our aggregator to retrieve your accounts. Please try again later. ${ Util.getContactSupportString() }`,
      buttonLabel: 'Reload',
      callback: () => {
        location.reload();
      },
      showCloseButton: false,
    } );
  }

  showManualAccountsErr( err: GenericError ) {
    console.error( err.err );
    this._state.notifyDataChanged( EVENT_NAMES.ACCOUNTS_LOADING_ERROR, null );
    this._alertService.showAlert( {
      title: 'Error Retrieving Manual Accounts',
      message: `We had a problem on our end loading your manual accounts from our system. Please try again. ${
        err.refCode
          ? Util.getRefCodeSupportString( err.refCode )
          : Util.getContactSupportString()
      }`,
      buttonLabel: 'Reload',
      callback: () => {
        location.reload();
      },
      showCloseButton: false,
    } );
  }

  showGenericErr( err: GenericError ) {
    console.error( err.err );
    this._state.notifyDataChanged( EVENT_NAMES.ACCOUNTS_LOADING_ERROR, null );
    this._alertService.showAlert( {
      title: 'Error Loading Accounts',
      message: `We had a problem on our end loading your accounts. Please try again. ${
        err.refCode
          ? Util.getRefCodeSupportString( err.refCode )
          : Util.getContactSupportString()
      }`,
      buttonLabel: 'Reload',
      callback: () => {
        location.reload();
      },
      showCloseButton: false,
    } );
  }

  /**
   * Function for triggering the automatic update of manual account data
   */
  updateManualAccounts() {
    // For each manual account, determine whether an update needs to happen, and if so, get it started
    // real asset won't need it until we can pull data for them
    // cash doesn't need an update
    // bank account doesn't need an update
    // investment accounts will get updated by the priceRefresh function
    // stocks need a price update
    // stock option needs an underlying price update and valuation update based on new underlying price and maturity calc
    // loans and IO bonds need prices recalced
    // crypto needs a price call
    // annuity needs to be recalced
    let count = 0;

    for ( const a of this._state.globalVars.allManualAccountsFromDB ) {
      if ( Util.accountIsCrypto( a ) ) {
        // CRYPTO
        this._pricingService
          .getCryptoPrice( a.positions[ 0 ].ticker )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( priceResponse: any ) => {
            const coinPrice = priceResponse.data;
            if ( coinPrice?.ticker ) {
              a.positions[ 0 ].price = coinPrice.price;
              a.positions[ 0 ].value = coinPrice.price * a.positions[ 0 ].quantity;
              a.value = a.positions[ 0 ].value;
            } else {
              // no price retrieved
            }
            // need to do this regardless of whether a price came back or not
            count++;
            this.checkManualAccountProgress( count );
          } );
      } else if ( Util.accountIsStock( a ) ) {
        // STOCK
        this._pricingService
          .getPrice( a.positions[ 0 ].ticker )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( priceResponse: any ) => {
            const stockPrice = priceResponse.data;
            if ( stockPrice?.ticker ) {
              a.positions[ 0 ].price = stockPrice.price;
              a.positions[ 0 ].value = stockPrice.price * a.positions[ 0 ].quantity;
              a.value = a.positions[ 0 ].value;
            } else {
              // no price retrieved
            }
            // need to do this regardless of whether a price came back or not
            count++;
            this.checkManualAccountProgress( count );
          } );
      } else if ( Util.accountIsStockOption( a ) ) {
        // STOCK OPTION
        this._pricingService
          .getPrice( a.positions[ 0 ].underlying_stock )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( priceResponse: any ) => {
            const stockPrice = priceResponse.data;
            if ( stockPrice?.ticker ) {
              a.positions[ 0 ].underlying_price = stockPrice.price;
              // todo: use util to price the option with the new data
              // a.positions[0].value = stockPrice.price * a.positions[0].quantity;
              a.value = a.positions[ 0 ].value;
            } else {
              // no price retrieved
            }
            // need to do this regardless of whether a price came back or not
            count++;
            this.checkManualAccountProgress( count );
          } );
      } else if ( Util.accountIsAnnuity( a ) ) {
        // ANNUITY
        a.positions[ 0 ].price = ManualAccountUtil.getPVOfAnnuity(
          a,
          this.treasuryRatesUtil.treasuryRates,
        );
        a.positions[ 0 ].value = a.positions[ 0 ].price * a.positions[ 0 ].quantity;
        a.value = a.positions[ 0 ].value;
        count++;
        this.checkManualAccountProgress( count );
      } else {
        // IGNORE EVERYTHING ELSE
        count++;
        this.checkManualAccountProgress( count );
      }
    }
  }

  checkManualAccountProgress( currentCount: number ) {
    if (
      currentCount === this._state.globalVars.allManualAccountsFromDB.length
    ) {
      this.resetManualAccountLists();
      this.categorizeAccounts( false );
      for ( const a of this._state.globalVars.allManualAccounts ) {
        this._state.notifyDataChanged(
          EVENT_NAMES.UPDATED_ACCOUNT_RETRIEVED,
          a.account_id,
        );
      }
      this._state.globalVars.accountsComponent?.rerenderAccountCategories();
      this._state.notifyDataChanged( 'account.settings.updated', {} );
    }
  }

  getConnections() {
    const self = this;
    if ( !this.currentWorkspaceHasConnectedAccounts() ) {
      // don't need to retrieve connections if the user doesn't have if they don't have a yodlee_user_id
      self.finishRefresh( { data: [] } );
    } else {
      self._gdService
        .getAggConnections()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( connectionsResponse: RipsawAPIGenericResponse ) => {
            self._overridesService
              .getOverridesByUser()
              .pipe( takeUntil( this.onDestroy ) )
              .subscribe( {
                next: ( globalOverridesResp: RipsawAPIGenericResponse ) => {
                  self._state.globalVars.globalOverrides = {};
                  // create a map of the global overrides, their ids are uuids and the sql table's PK, so their should be no concern of collisions
                  for ( const o of globalOverridesResp?.data ?? [] ) {
                    self._state.globalVars.globalOverrides[ o?.id ] = o;
                  }
                  self.getUserAccountMapping( connectionsResponse );
                },
                error: err => {
                  console.error( 'Error retrieving global overrides' );
                  console.error( err );
                  self.getUserAccountMapping( connectionsResponse );
                },
              } );
          },
          error: err => {
            this.showAggregatorErr( err );
          },
        } );
    }
  }

  getUserAccountMapping( connectionsResponse: RipsawAPIGenericResponse ) {
    const self = this;
    // get or create the account mapping so the connection manager can pick the right accounts to include
    self._gdService
      .getUserAccountMapping()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( mappingResp: RipsawAPIGenericResponse ) => {
          // Logger.log( mappingResp );
          self._state.globalVars.accountMapping = mappingResp.data;
          self.applyOverridesToAccounts( [
            ...self._state.globalVars.allAccounts,
          ] );
          self.finishRefresh( connectionsResponse );
        },
        error: err => {
          if ( err && err.err && err.err.indexOf( 'R800' ) >= 0 ) {
            self._state.globalVars.allAccountsFromAgg = [];
            self._state.globalVars.accountMapping = {};

            self.createAccountMapping();
            self.applyOverridesToAccounts( [
              ...self._state.globalVars.allAccounts,
            ] );
            self.finishRefresh( connectionsResponse );
          } else {
            this.showGenericErr( err );
          }
        },
      } );
  }

  /*
   * Function for making copies of the accounts lists for using in data aggregation for current numbers
   * */
  makeCopiesOfAccountLists( includeFromAgg?: boolean ) {
    const self = this;
    self._state.globalVars.allAccountsOriginal = _.cloneDeep(
      self._state.globalVars.allAccounts,
    );
    self._state.globalVars.allManualAccountsOriginal = _.cloneDeep(
      self._state.globalVars.allManualAccounts,
    );
    if ( includeFromAgg ) {
      self._state.globalVars.allAccountsFromAgg = _.cloneDeep(
        self._state.globalVars.allAccounts,
      );
    }
  }

  // this is called last
  /*
   * Function for setting up aggregator connection data
   * @param connectionData {Array} - aggregator connection data
   * */
  setupConnectionData( connectionData: any ) {
    this._state.globalVars.allConnections = connectionData.data;
    const connectionsWithAccounts = [];
    for ( const a of [
      ...this._state.globalVars.allAccountsFromAgg,
      ...this._state.globalVars.allAccounts,
      ...this._state.globalVars.allAccountsOriginal,
    ] ) {
      const connection = _.find(
        this._state.globalVars.allConnections,
        ( c: any ) => {
          return c.id === a.connection_id;
        },
      );
      if ( connection ) {
        a.status = connection.status;
        a.logo = connection.logo;
        a.favicon = connection.favicon;
        a.institution_url = connection.institution_url;
        a.last_good_sync = connection.last_good_sync;
        const indicator = Util.accountSyncedRecentlyButFailed( a )
          ? this._state.statusMap[ 'FAILED_RECENTLY' ]
          : this._state.statusMap[ a.status ];
        a.statusIndicator = indicator;
        connection.statusIndicator = indicator;
        connectionsWithAccounts.push( connection );
      }
    }

    this._state.globalVars.connectionsWithoutAccounts = _.xor(
      this._state.globalVars.allConnections,
      connectionsWithAccounts,
    );

    this._state.globalVars.allConnections.forEach( ( connection: Connection ) => {
      connection.statusIndicator = Util.connectionSyncedRecentlyButFailed(
        connection,
      )
        ? this._state.statusMap[ 'FAILED_RECENTLY' ]
        : this._state.statusMap[ connection.status ];
    } );
  }

  /*
   * Function for removing accounts set to be hidden in account mapping
   * */
  removeAccountsNotIncluded() {
    this._state.globalVars.loadingAccounts = true;
    const mapping = this._state.globalVars.accountMapping.mapping;
    if ( mapping ) {
      const ids = {};
      // this predicate returns false for accounts that are set to included in the account mapping so that accounts that
      // aren't set to included are removed
      const removePredicate = ( a: any ) => {
        ids[ a.account_id ] = true;
        if ( !mapping[ a.connection_id ] ) {
          mapping[ a.connection_id ] = {};
          mapping[ a.connection_id ][ a.account_id ] = { include: true };
        }
        if ( !mapping[ a.connection_id ][ a.account_id ] ) {
          mapping[ a.connection_id ][ a.account_id ] = { include: true };
        }
        return !mapping[ a.connection_id ][ a.account_id ].include;
      };
      // need to remove accounts from both arrays because we have to keep track of current and revised
      _.remove( this._state.globalVars.allAccounts, removePredicate );
      _.remove( this._state.globalVars.allAccountsOriginal, removePredicate );
      // need to add back any accounts set to included that aren't in the arrays (ids object tells us which accounts aren't in there)
      for ( const a of this._state.globalVars.allAccountsFromAgg ) {
        if (
          !ids[ a.account_id ] &&
          mapping[ a.connection_id ][ a.account_id ].include
        ) {
          this._state.globalVars.allAccounts.push( a );
          this._state.globalVars.allAccountsOriginal.push( a );
        }
      }
    }
    this.categorizeAccounts( true );
    this._state.globalVars.loadingAccounts = false;
  }

  /*
   * Function apply overrides from the account mapping to the positions in all accounts
   * @param accounts {Array} - the list of accounts to apply overrides to
   * */
  applyOverridesToAccounts( accounts: Account[] ) {
    this._state.globalVars.overridesWithNewValues = [];
    const mapping = this.checkMapping();
    if ( mapping ) {
      // there should always be a mapping because checkMapping creates a new one if there isn't one
      // COMMENTING OUT Because some people are losing their overrides. see RIP-137
      // let overridesRemoved: boolean = false;

      for ( const a of accounts ) {
        a.formattedDescription = this.formatAccountDescription( a );
        this.setAccountFee( a );

        if (
          mapping[ a.connection_id ] &&
          mapping[ a.connection_id ][ a.account_id ]
        ) {
          const accountMapping = mapping[ a.connection_id ][ a.account_id ];
          a.included = accountMapping.include;
          if ( accountMapping.nickname ) {
            // leave this or condition in for now in case there are users who had a nickname before the .new and .old
            // were implemented
            a.description =
              accountMapping.nickname.new ??
              accountMapping.nickname.old ??
              accountMapping.nickname;
            a.name = '';
            a.formattedDescription = a.description;
          }
          const accountOverrides = accountMapping.overrides;
          // if there are overrides for this account, go through each override and apply it to a position or remove it
          if ( accountOverrides ) {
            // this function used to go through all positions, but now it only goes through all overrides
            Object.keys( accountOverrides ).forEach( key => {
              const oldTicker = accountOverrides[ key ].ticker;
              const p = a.positions.find( ( item: any ) => {
                if ( oldTicker ) {
                  return String( item.ticker ) === String( oldTicker.old );
                } else {
                  // not sure if we need to check for a new/old ticker here or not
                  return (
                    String( item.ticker ) === String( key ) ||
                    String( item.id ) === String( key )
                  );
                }
              } );
              if ( p ) {
                const positionOverrides =
                  accountOverrides[ p.ticker ] ??
                  accountOverrides[ p.overridden_ticker ] ??
                  accountOverrides[ p.id ];
                this.applyOverridesToPosition( p, positionOverrides, a );
              } else {
                // COMMENTING OUT Because some people are losing their overrides. see RIP-137
                // purge override because there is no position with that ticker anymore
                // this flag will tell the function to save the map at the end because a change must have been made
                // delete accountOverrides[key];
                // overridesRemoved = true;
              }
            } );
          }
        } else {
          a.included = true;
        }
        // apply global overrides to positions in the account
        for ( const p of a.positions ) {
          const globalId = Object.keys(
            this._state.globalVars.globalOverrides,
          ).find( ( key: string ) => {
            const o = this._state.globalVars.globalOverrides[ key ];
            return (
              o.original_ticker === p.ticker ||
              o.original_ticker === p.overridden_ticker
            );
            // && o.original_security_type === p.security_type;
          } );
          if ( globalId ) {
            const override: GlobalOverride =
              this._state.globalVars.globalOverrides[ globalId ];
            p.global_override_id = override.id;
            this.applyOverridesToPosition( p, override.overrides, a );
          }
        }
      }
      Logger.log(
        `overrides with new values: ${ JSON.stringify(
          this._state.globalVars.overridesWithNewValues,
        ) }`,
      );
      // console.log( 'done applying overrides' );
      // COMMENTING OUT Because some people are losing their overrides. see RIP-137
      /*if ( overridesRemoved ) {
       this._gdService.updateUserAccountMapping( Util.getLoggedInUser( this._auth ).userId, this._state.globalVars.accountMapping )
       .pipe( takeUntil( this.onDestroy ) )
       .subscribe( ( /!*resp: any*!/ ) => {
       Logger.info( 'Purged some old overrides from account mapping' );
       }, ( err ) => {
       console.error( err );
       } );
       }*/
    }
  }

  applyOverridesToPosition( p: any, positionOverrides: any, a: Account ) {
    if ( positionOverrides ) {
      for ( const key of Object.keys( positionOverrides ) ) {
        // check to see if the aggregator is returning a new value for the one overridden
        if (
          positionOverrides[ key ].old !== p[ key ] &&
          !this._state.globalVars.firstAccountPullComplete
        ) {
          // if there is a new value, and this is the initial application of the override, update the override.old field with the new value
          positionOverrides[ key ].old = p[ key ];
          this._state.globalVars.overridesWithNewValues.push(
            Object.assign( { newAggValue: p[ key ] }, positionOverrides[ key ] ),
          );
        }
        p[ key ] = positionOverrides[ key ].new;
      }
      if ( positionOverrides.ticker && !p.security_data_merged_in ) {
        // go get security quote
        this.overriddenSecuritiesBeingRetrieved++;
        this._gdService
          .getSecurity( positionOverrides.ticker.new )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( security: any ) => {
              // console.log( 'Security data retrieved for overridden ticker, %s', p.ticker );
              this.mergeInRetrievedData( security.data, a );
            },
            error: err => {
              console.log( err.err );
              // should we do anything about this error
            },
          } );
        p.overridden_ticker = positionOverrides.ticker.old;
      }
    }
  }

  // todo: this should be changed to be more performant. looping through all accounts and positions for potentially only one security is not great
  mergeInRetrievedData( security: Security, account: Account ) {
    this.overriddenSecuritiesBeingRetrieved--;
    if ( this.overriddenSecuritiesBeingRetrieved < 0 ) {
      this.overriddenSecuritiesBeingRetrieved = 0;
    }
    // have to get the account from both original and revisable list because this data was retrieved async and both
    // sets might exist by the time the data comes back
    const accounts = _.filter(
      [ ...this.getAllOriginalAccounts(), ...this.getAllRevisableAccounts() ],
      ( a: any ) => {
        return account.account_id === a.account_id;
      },
    );
    for ( const a of accounts ) {
      for ( const p of a.positions ) {
        if ( p.ticker === security.ticker ) {
          p.overridden_price =
            p.yodlee_original_data.price !== 1
              ? p.yodlee_original_data.price
              : p.price;
          Object.assign( p, security );
          p.security_data_merged_in = true;
          if (/*!security.price &&*/ security.quote ) {
            p.price = security.quote.price;
          }
          p.isProxy = true;
          if ( p.quantity * p.price !== p.value ) {
            if ( p.yodlee_original_data?.quantity ) {
              p.overridden_quantity = p.yodlee_original_data.quantity;
            } else if ( p.yodlee_original_data?.value ) {
              p.overridden_quantity =
                p.yodlee_original_data.value / p.overridden_price;
            }
            p.quantity = p.yodlee_original_data.value / p.price;
          }
          this._state.notifyDataChanged( EVENT_NAMES.POSITION_OVERRIDDEN, p );
        }
      }
    }
    if ( this.overriddenSecuritiesBeingRetrieved === 0 ) {
      if ( !this.isSyncingOnLogin.value ) {
        this.allDataRetrieved.next( true );
      }
    }
  }

  keysToCheck = [
    'ticker',
    'ticker_name',
    'security_type',
    'price',
    'quantity',
    // 'value',
    // 'expense_ratio', may add this back if we think the user should put it in
  ];

  // these are tickers to look for and ignore because they don't need to have completed fields
  tickersToIgnore = [ 'CUR:USD', 'VEHICLE', 'REALESTATE', 'NONALLOCATED' ];

  /*
   * Function for checking to see if any positions are missing data
   * */
  scanPositionsForMissingData( positions: any ) {
    const groups = this._state.columnGroupings;
    if ( !Array.isArray( positions ) ) {
      console.error( 'positions is not iterable' );
      return;
    }
    for ( const p of positions ) {
      // go through each position in the account looking for missing data
      if ( p && !this.tickersToIgnore.includes( p.ticker ) ) {
        // ignore simple cash positions (i.e. checking accounts, settlement funds, etc)
        delete p.missing_data; // reset flag in case they fixed it previously with an override
        for ( const g of groups ) {
          // go through each group of columns to see if any groups are missing data
          let missingFlag = true;
          if ( AccountManager.groupIsRelevant( p, g ) ) {
            for ( const c of g.columns ) {
              // go through each column of the group to see if there is a field with data (only need one to be filled in)
              if ( p[ c ] !== undefined && p[ c ] !== null ) {
                missingFlag = false;
              }
            }
            if ( missingFlag ) {
              // if flag is true mark position with missing data that the override modal button can show red
              if ( !p.missing_data ) {
                p.missing_data = [];
              }
              // add group label to list of groups missing data
              p.missing_data.push( g.label );
            }
            // if the missingFlag is false, don't do anything
          }
        }
        for ( const key of this.keysToCheck ) {
          if ( p[ key ] === undefined || p[ key ] === null || p[ key ] === '' ) {
            if ( !p.missing_data ) {
              p.missing_data = [];
            }
            p.missing_data.push( key );
          }
        }
        this.removeIssueFromGlobalDataQualityIssues( p );
        this.checkPositionForCBSDiff( p );
        this.checkPositionForPQDiff( p );
      } else {
        // console.log( `cash or undefined: ${p}` );
      }
    }
  }

  /**
   *
   * @param position - position that was overridden, or overrides were removed from
   * @param localOverrides - local overrides. will be null if a global override
   */
  applyOverridesAndScanAgain( position: Position, localOverrides?: any ) {
    const go: GlobalOverride =
      this._state.globalVars.globalOverrides[ position.global_override_id ];
    this.getAllAggregatorAccountsAndCopies().forEach( ( a: Account ) => {
      for ( const p of a.positions ) {
        let appliesToThisPosition = false;
        if (
          p.global_override_id !== undefined &&
          p.global_override_id === position.global_override_id
        ) {
          appliesToThisPosition = true;
        }
        if (
          go !== undefined &&
          ( go?.original_ticker === p.ticker ||
            go?.original_ticker === p.overridden_ticker )
        ) {
          appliesToThisPosition = true;
        }

        if ( p.id === position.id ) {
          appliesToThisPosition = true;
        }

        if ( appliesToThisPosition ) {
          if ( go ) {
            p.global_override_id = go.id;
            this.applyOverridesToPosition( p, go.overrides, a );
          } else {
            this.applyOverridesToPosition( p, localOverrides, a );
            if ( Util.accountIsLoan( a ) || Util.accountIsPrivateLending( a ) ) {
              this.setLoanFields( p, a );
            }
          }

          this.scanPositionsForMissingData( [ p ] );
        }
      }
    } );
    this._state.notifyDataChanged(
      EVENT_NAMES.PROCESS_DATA_QUALITY_ISSUES,
      null,
    );
  }

  addNewGlobalOverrideIdToPositions( go: GlobalOverride ) {
    for ( const a of this.getAllAggregatorAccountsAndCopies() ) {
      for ( const p of a.positions ) {
        if (
          go.original_ticker === p.ticker ||
          go.original_ticker === p.overridden_ticker
        ) {
          p.global_override_id = go.id;
        }
      }
    }
  }

  checkPositionForPQDiff( p ) {
    const issue: NotificationIssue = DataQualityChecker.checkPositionForPQDiff(
      p,
      this,
    );
    if ( issue ) {
      this._state.globalVars.dataQualityIssues.push( issue );
    }
  }

  checkPositionForCBSDiff( p ) {
    const issue: NotificationIssue = DataQualityChecker.checkPositionForCBSDiff(
      p,
      this,
    );
    if ( issue ) {
      this._state.globalVars.dataQualityIssues.push( issue );
    }
  }

  removeIssueFromGlobalDataQualityIssues( position: any ) {
    const oldTicker = position?.overrides?.ticker
      ? position.overrides?.ticker?.old
      : position.overridden_ticker
        ? position.overridden_ticker
        : '';
    _.remove(
      this._state.globalVars.dataQualityIssues,
      ( i: NotificationIssue ) => {
        const accountMatches = i.account_id === position.account_id;
        if ( accountMatches ) {
          if ( oldTicker !== '' ) {
            return (
              accountMatches &&
              ( i.ticker === position.ticker || i.ticker === oldTicker )
            );
          } else {
            return accountMatches && i.ticker === position.ticker;
          }
        } else {
          return false;
        }
      },
    );
  }

  private static groupIsRelevant( p: any, group: any ): boolean {
    const minPercentage = 0.02;
    switch ( group.label ) {
      case 'Cash, Bonds, Stocks':
      case 'Yields and Expenses':
        return true;
      case 'Capitalization Distribution':
      case 'Value, Blend, Growth':
      case 'Stock Global Distribution':
      case 'Stock Sector Distribution':
        return p.stocks && p.stocks > minPercentage;
      case 'Credit Quality Distribution':
      case 'Bond Global Distribution':
      case 'Maturity Range Distribution':
      case 'Bond Sector Distribution':
        return p.bonds && p.bonds > minPercentage;
      default:
        return true;
    }
  }

  /*
   * Function for setting up revised numbers in normal accounts
   * */
  addRevisedFields( positions: any[] ) {
    if ( positions ) {
      for ( const p of positions ) {
        if ( p.security_type && p.security_type.toLowerCase() === 'loan' ) {
          this.setLoanFields( p );
        } else {
          p.revised_difference = 0;
          p.revised_quantity = 0;
          p.revised_value = p.value;
          p.revised_allocation = p.allocation;
          p.buySell = '';
          p.optimizerConstraints =
            p.real_assets !== undefined && p.real_assets > 0 ? '=' : '';
        }
      }
    }
  }

  setLoanFields( p: any, a?: any ) {
    // if ( !a || !a.added_in_revision ) {
    // 12/2/21 - added `?? p.price` to debt condition expression because manual account update seems to leave the manual account without a value, so
    // it gets set in here instead
    const debt: boolean = ( p.value ?? p.price ) <= 0;
    p.quantity = 0 <= p.quantity && p.quantity <= 1 ? p.quantity : 1;
    let revised_price;

    if ( p.current_market_rate === 0 ) {
      delete p.current_market_rate;
    }
    const todaysRate =
      p.current_market_rate ??
      ( [ 'mortgage', 'manual loan', 'manual mortgage' ].includes(
        p.ticker_name?.toLowerCase(),
      )
        ? this.getTodaysRate( p )
        : p.coupon );
    p.current_market_rate = todaysRate;
    p.annualized_yield = p.current_market_rate;
    let termInYears: number;
    if ( p.loan_term ) {
      if ( AccountManager.loanIsManual( p ) ) {
        termInYears = p.loan_term / 12;
      } else {
        termInYears = p.loan_term;
      }
    }
    Util.checkMaturity( p );
    if (
      !p.original_loan_amount ||
      !p.loan_origination_date ||
      !p.loan_term ||
      !p.coupon
    ) {
      p.missing_data = [ 'Loan Fields' ];
      for ( const key of [
        'original_loan_amount',
        'loan_origination_date',
        'loan_term',
        'coupon',
      ] ) {
        if ( !p[ key ] ) {
          p.missing_data.push( key );
        }
      }
      p.price = p.value ?? p.outstanding_balance;
      p.value = p.price * p.quantity;
      revised_price = a ? p.price + a.cashFromOtherAccounts : p.price;
    } else {
      delete p.missing_data;
      // need to get present value for loans
      if ( p.amortization_type === 'interest-only' ) {
        if ( !p.maturity_date ) {
          p.maturity_date = BondHelpers.calcMaturityDate(
            p.loan_origination_date,
            termInYears,
          );
        }
        p.price = BondHelpers.calcPriceForInterestOnlyType(
          moment( p.maturity_date ),
          p.maturity_value,
          p.coupon,
          p.coupon_frequency,
          todaysRate,
        );
        if ( debt ) {
          p.price *= -1;
        }
        p.value = p.price * p.quantity;
        if ( a ) {
          const revisedPV = BondHelpers.calcPriceForInterestOnlyType(
            moment( p.maturity_date ),
            p.maturity_value - a.cashFromOtherAccounts,
            p.coupon,
            p.coupon_frequency,
            todaysRate,
          );
          revised_price = -revisedPV;
        } else {
          revised_price = p.price;
        }
      } else {
        // Fully Amortizing
        const analytics = MortgageHelpers.calcMortgageAnalyticValues(
          p.original_loan_amount,
          p.outstanding_balance,
          new Date( p.loan_origination_date ),
          new Date( p.maturity_date ),
          new Date(),
          p.coupon,
          termInYears || 30,
          todaysRate,
          0, // this.paydownAmount,
        );

        p.price = analytics.presentValue;
        p.outstanding_balance = analytics.outstandingBalance;

        if ( debt ) {
          p.price *= -1;
        }
        p.value = p.price * p.quantity;
        if ( a ) {
          const analytics = MortgageHelpers.calcMortgageAnalyticValues(
            p.original_loan_amount,
            p.outstanding_balance,
            new Date( p.loan_origination_date ),
            new Date( p.maturity_date ),
            new Date(),
            p.coupon ?? p.current_market_rate,
            termInYears ?? 30,
            todaysRate,
            Math.abs( a.cashFromOtherAccounts ),
          );
          const revisedPV = analytics.presentValue;
          revised_price = -revisedPV;
        } else {
          revised_price = p.price;
        }
      }
    }
    if ( a && a.added_in_revision ) {
      p.revised_difference = p.price + a.cashFromOtherAccounts;
      p.revised_value = revised_price;
      p.revised_quantity = p.revised_value / p.price;
    } else {
      p.revised_difference = a ? a.cashFromOtherAccounts : 0;
      p.revised_value = revised_price * p.quantity;
      p.revised_quantity = 1 - p.revised_value / p.value;
    }

    p.revised_allocation = 1;
    p.buySell = '';
    p.optimizerConstraints = '';
    this.updateLoanAccountValue( p );
    // }
  }

  updateLoanAccountValue( position: any ) {
    const accounts = _.filter(
      [
        ...this._state.globalVars.allAccounts,
        ...this._state.globalVars.allAccountsOriginal,
        ...this._state.globalVars.allAccountsFromAgg,
      ],
      ( a: any ) => {
        return a.account_id === position.account_id;
      },
    );

    for ( const a of accounts ) {
      a.value =
        position.quantity *
        ( position.price ?? position.outstanding_balance ?? position.cost_basis );
    }
  }

  /**
   * this function is only for use outside of revision (editing) mode
   * */
  addNewManualAccountToLists( account: Account ) {
    account.isManual = true;
    this.addRevisedFields( account.positions );
    this.scanPositionsForMissingData( account.positions );
    this._state.globalVars.allManualAccounts.push( account );
    this._state.globalVars.allManualAccountsFromDB.push( _.cloneDeep( account ) );
    this._state.globalVars.allManualAccountsOriginal.push( _.cloneDeep( account ) );

    _.uniqBy( this._state.globalVars.allManualAccounts, 'account_id' );
    _.uniqBy( this._state.globalVars.allManualAccountsFromDB, 'account_id' );
    _.uniqBy( this._state.globalVars.allManualAccountsOriginal, 'account_id' );
    this.categorizeAccounts( false );
  }

  /**
   * this function is only for use outside of revision (editing) mode
   * */
  updateManualAccountFields( account: Account ) {
    account.isManual = true;
    const index = _.findIndex(
      this._state.globalVars.allManualAccountsFromDB,
      ( a: any ) => {
        return a.account_id === account.account_id;
      },
    );

    ManualAccountUtil.setIcon( account );
    account.formattedDescription = this.formatAccountDescription( account );
    if ( index >= 0 ) {
      this._state.globalVars.allManualAccountsFromDB[ index ] = account;
    } else {
      // oh shit, this really shouldn't happen
    }
    // resetting the manual account lists will also make the changes in allManualAccounts and allManualAccountOriginal
    // by copying the fromDB list
    this.resetManualAccountLists();

    this.categorizeAccounts( false );
  }

  /**
   * function to reset the manual account lists after a change is made (e.g. manual included or hidden)
   */
  resetManualAccountLists() {
    const self = this;

    self._state.globalVars.allManualAccounts = _.cloneDeep(
      self._state.globalVars.allManualAccountsFromDB,
    );

    for ( const a of self._state.globalVars.allManualAccounts ) {
      this.addRevisedFields( a.positions );
    }
    // need this clone so we don't alter the complete set (allManualAccountFromDB)
    _.remove( self._state.globalVars.allManualAccounts, ( a: any ) => {
      return !a.included;
    } );

    self._state.globalVars.allManualAccountsOriginal = _.cloneDeep(
      self._state.globalVars.allManualAccounts,
    );
  }

  /**
   * this function is only for use outside of revision (editing) mode
   * */
  removeManualAccountFromLists( account: any ) {
    _.remove( this._state.globalVars.allManualAccounts, ( a: any ) => {
      return a.account_id === account.account_id;
    } );

    _.remove( this._state.globalVars.allManualAccountsOriginal, ( a: any ) => {
      return a.account_id === account.account_id;
    } );

    _.remove( this._state.globalVars.allManualAccountsFromDB, ( a: any ) => {
      return a.account_id === account.account_id;
    } );

    this.categorizeAccounts( false );
  }

  updateAggregatedAccount( account: Account ) {
    account.statusIndicator = this._state.statusMap[ account.status ];

    const accountsPageAccount = _.find(
      this._state.globalVars.allAccounts,
      ( a: Account ) => {
        return a.account_id === account.account_id;
      },
    );

    Object.assign( accountsPageAccount, account );
    this.addRevisedFields( accountsPageAccount.positions );

    const accounts = _.filter(
      [
        ...this._state.globalVars.allAccountsOriginal,
        ...this._state.globalVars.allAccountsFromAgg,
      ],
      ( a: Account ) => {
        return a.account_id === account.account_id;
      },
    );

    for ( const a of accounts ) {
      Object.assign( a, account );
      this.addRevisedFields( a.positions );
    }

    this.reapplyOverridesAndReplaceInAccountCategoriesOfAggregatedAccount(
      accountsPageAccount,
    );
    this._state.notifyDataChanged(
      EVENT_NAMES.UPDATED_ACCOUNT_RETRIEVED,
      account.account_id,
    );
  }

  updateAggregatedAccountStatus( account_id: string | number, status: string ) {
    const accountsPageAccount = _.find(
      this._state.globalVars.allAccounts,
      ( a: Account ) => {
        return a.account_id === account_id;
      },
    );

    const indicator = Util.accountSyncedRecentlyButFailed( accountsPageAccount )
      ? this._state.statusMap[ 'FAILED_RECENTLY' ]
      : this._state.statusMap[ status ];

    accountsPageAccount.statusIndicator = indicator;
    accountsPageAccount.status = status;

    const accounts = _.filter(
      [
        ...this._state.globalVars.allAccountsOriginal,
        ...this._state.globalVars.allAccountsFromAgg,
      ],
      ( a: Account ) => {
        return a.account_id === account_id;
      },
    );

    for ( const a of accounts ) {
      a.statusIndicator = indicator;
      a.status = status;
    }

    this.reapplyOverridesAndReplaceInAccountCategoriesOfAggregatedAccount(
      accountsPageAccount,
    );
    this._state.notifyDataChanged(
      EVENT_NAMES.UPDATED_ACCOUNT_RETRIEVED,
      account_id,
    );
  }

  reapplyOverridesAndReplaceInAccountCategoriesOfAggregatedAccount(
    account: Account,
  ) {
    this.applyOverridesToAccounts( [ account ] );

    if ( this._state.globalVars.accountsComponent ) {
      let cat = account.account_category;
      if ( cat === 'Other' ) {
        cat = account.account_type;
      }

      if ( isNaN( this.categoryOrder[ cat ] ) ) {
        cat = 'Other';
      }
      const order = this.categoryOrder[ cat ];
      const accountIndex = _.findIndex(
        this._state.globalVars.accountCategories[ order ],
        ( a: Account ) => {
          return a.account_id === account.account_id;
        },
      );

      this._state.globalVars.accountsComponent.accountCategories[ order ][
        accountIndex
        ] = this._state.globalVars.accountCategories[ order ][ accountIndex ] =
        account;
    }
  }

  getTodaysRate( loan ) {
    let yearsLeft;
    const todaysRates = this._state.globalVars.mortgageRates;
    if ( loan.maturity_date ) {
      yearsLeft = moment( loan.maturity_date ).diff(
        moment( new Date() ),
        'years',
        true,
      );
    } else if ( loan.loan_term && loan.loan_origination_date ) {
      yearsLeft =
        loan.loan_term -
        moment( new Date() ).diff(
          moment( loan.loan_origination_date ),
          'years',
          true,
        );
    } else {
      return todaysRates.Fixed30Year.refi.rate;
    }
    let rate;
    if ( yearsLeft > 20 ) {
      // use 30 year rate
      rate = todaysRates.Fixed30Year.refi.rate;
    } else if ( yearsLeft > 15 ) {
      // use 20 year rate
      rate = todaysRates.Fixed20Year.refi.rate;
    } else if ( yearsLeft > 10 ) {
      // use 15 year rate
      rate = todaysRates.Fixed15Year.refi.rate;
    } else if ( yearsLeft > 7 ) {
      // use 10 year rate
      rate = todaysRates.Fixed10Year.refi.rate;
    } else if ( yearsLeft > 5 ) {
      // use 7 year arm rate
      rate = todaysRates.ARM7.refi.rate;
    } else if ( yearsLeft > 3 ) {
      // use 5 year arm rate
      rate = todaysRates.ARM5.refi.rate;
    } else {
      // use 3 year arm rate
      rate = todaysRates.ARM3.refi.rate;
      if ( rate === 0 ) {
        // have seen this one be 0, but not others
        rate = todaysRates.ARM5.refi.rate;
      }
    }

    return rate;
  }

  /**
   * Function for checking whether an account is in an investment category
   * @param account - account to check
   * @return {boolean} - returns true if the account is in the investment category
   * */
  accountIsInvestment( account: any ) {
    return account.account_category === 'Investment';
  }

  /**
   * Function for getting the combination of allAccounts and allManualAccounts
   * @return {any[]} - set of accounts that are used in a revision
   * */
  getAllRevisableAccounts() {
    return [
      ...( this._state.globalVars.allAccounts || [] ),
      ...( this._state.globalVars.allManualAccounts || [] ),
    ];
  }

  /**
   * */
  getAllRevisableInvestmentAccounts() {
    return this.getAllRevisableAccounts().filter( ( account: Account ) => {
      return this.accountIsInvestment( account );
    } );
  }

  /**
   * Function for getting all accounts that are loan type
   * @return {Account[]} - set of accounts that are category Loan
   * */
  getAllLoanAccounts() {
    return this.getAllRevisableAccounts().filter( ( account: Account ) => {
      return Util.accountIsLoan( account );
    } );
  }

  /**
   * Function for getting all accounts that are an asset type
   * @return {Account[]} - set of accounts that are asset type
   */
  getAllAssetAccounts(): Account[] {
    return this.getAllOriginalAccounts().filter( ( account: Account ) => {
      return account.account_category.toUpperCase() !== 'LOAN';
    } );
  }

  /**
   *  Function for finding a liability account using an account_id of an asset
   * @param assetAccountId - account_id of an asset type account
   * @returns {Account} - liability account or undefined
   */
  searchForCorrespondingAssetId( assetAccountId: string ): Account {
    return this.getAllLoanAccounts().find( ( account: Account ) => {
      return assetAccountId === account.corresponding_asset_id;
    } );
  }

  /**
   *  Function for finding an asset account using an account_id of a liability
   * @param liabilityAccountId - account_id of a liability type account
   * @returns {Account} - asset account or undefined
   */
  searchForCorrespondingLiabilityId( liabilityAccountId: string ): Account {
    return this.getAllAssetAccounts().find( ( account: Account ) => {
      return liabilityAccountId === account.corresponding_liability_id;
    } );
  }

  /**
   * Function for getting the combination of allAccountsOriginal and allManualAccountsOriginal
   * @return {Account[]} - set of accounts that correspond to the user's current portfolio (not revised)
   * */
  getAllOriginalAccounts(): Account[] {
    return [
      ...( this._state.globalVars.allAccountsOriginal || [] ),
      ...( this._state.globalVars.allManualAccountsOriginal || [] ),
    ];
  }

  /**
   * Function for getting all accounts that global overrides might be applied in
   * @return {Account[]} - set of accounts from aggregator (original, revised and including hidden)
   */
  getAllAggregatorAccountsAndCopies(): Account[] {
    return [
      ...this._state.globalVars.allAccounts,
      ...this._state.globalVars.allAccountsOriginal,
      ...this._state.globalVars.allAccountsFromAgg,
    ];
  }

  /**
   * Function for retrieving the list of all positions in all accounts
   * @return {Position[]} - array of positions from every account in the currently shown portfolio, including non_allocated_funds positions
   * */
  getAllPositions(): Position[] {
    const positions: Position[] = [];
    for ( const a of this._state.globalVars.allAccounts ) {
      // need to make sure we add any non allocated cash for aggregation
      if ( this.accountIsInvestment( a ) && a.non_allocated_funds ) {
        positions.push( a.non_allocated_funds );
      }
      for ( const p of a.positions ) {
        positions.push( p );
      }
    }
    return positions;
  }

  /**
   * Function for retrieving the list of all positions in all manual accounts
   * */
  getAllManualPositions() {
    const positions = [];
    for ( const a of this._state.globalVars.allManualAccounts ) {
      // need to make sure we add any non allocated cash for aggregation
      if ( this.accountIsInvestment( a ) && a.non_allocated_funds ) {
        positions.push( a.non_allocated_funds );
      }
      for ( const p of a.positions ) {
        positions.push( p );
      }
    }
    return positions;
  }

  /**
   * Function for retrieving the list of all positions in all accounts, including manual
   * @return {Position[]} - mutable array of all positions including those in manual accounts, that are used in revisions
   * */
  getAllPositionsIncludingManualAccounts(): Position[] {
    return [ ...this.getAllManualPositions(), ...this.getAllPositions() ];
  }

  /* just commenting in case this comes in handy later
   /!**
   * Function for retrieving all revisable positions except for real assets and corresponding liabilities
   * @return {Position[]} - array of positions that doesn't include real assets or corresponding liabilities
   *!/
   getAllRevisableInvestmentModePositions(): Position[] {
   return this.getAllPositionsIncludingManualAccounts().filter( ( p: any ) => {
   return AllocCalculator.isNotAssetOrCorrespondingLiability( p, this );
   } );
   }*/

  /**
   * Function for retrieving the list of all positions in all original accounts. does not include hidden accounts
   * @return {Position[]} - unchanged array of positions from aggregated accounts. does not include hidden accounts
   * */
  getAllOriginalPositions(): Position[] {
    const positions = [];
    for ( const a of this._state.globalVars.allAccountsOriginal ) {
      for ( const p of a.positions ) {
        positions.push( p );
      }
    }
    return positions;
  }

  /**
   * Function for retrieving the list of all positions in all original accounts including hidden accounts
   * @return {Position[]} - unchanged array of positions from aggregated accounts including hidden accounts
   * */
  getAllPositionsFromAgg(): Position[] {
    const positions = [];
    for ( const a of this._state.globalVars.allAccountsFromAgg ) {
      for ( const p of a.positions ) {
        positions.push( p );
      }
    }
    return positions;
  }

  /**
   * Function for retrieving the list of all positions in all original manual accounts
   * @return {Position[]} - unchanged array of positions from manual accounts
   * */
  getAllOriginalManualPositions(): Position[] {
    const positions = [];
    for ( const a of this._state.globalVars.allManualAccountsOriginal ) {
      for ( const p of a.positions ) {
        positions.push( p );
      }
    }
    return positions;
  }

  /**
   * Function for retrieving the list of all positions in all original accounts, including manual
   * @return {Position[]} - unchanged array of positions from original set including those in manual accounts
   * */
  getAllOriginalPositionsIncludingManualAccounts(): Position[] {
    return [
      ...this.getAllOriginalManualPositions(),
      ...this.getAllOriginalPositions(),
    ];
  }

  /**
   * Function for retrieving all positions and their copies. created for after removing overrides
   * @return {Position[]} -
   */
  getAllPositionsWithCopies(): ( Position[] | Position )[] {
    return [
      ...this.getAllPositions(),
      ...this.getAllOriginalPositions(),
      ...this.getAllPositionsFromAgg(),
    ];
  }

  /**
   *
   * @param id - id of the account to be retrieved from the list of revisable accounts (ignores hidden accounts)
   * @return account with the matching id
   */
  getRevisableAccountFromId( id: number | string ): any {
    return _.find( this.getAllRevisableAccounts(), ( a: any ) => {
      return a.account_id === id;
    } );
  }

  /**
   *
   * @param id - id of the account to be retrieved from getAllOriginalAccounts (all possible accounts including hidden ones)
   * @return account with the matching id
   */
  getOriginalAccountFromId( id: number | string ): Account {
    return _.find( this.getAllOriginalAccounts(), ( a: Account ) => {
      return a.account_id === id;
    } );
  }

  /*
   * Function for including or hiding an account in the mapping
   * @param account {Object} - account to be included or hidden
   * @param callback {Function} - callback function to be called when the account mapping is finished updating. Mostly
   * for getting updated for notification purposes
   * */
  includeAccount( account: any, callback: Function ) {
    const mapping = this._state.globalVars.accountMapping.mapping;

    let mapConnection = mapping[ account.connection_id ];
    if ( !mapConnection ) {
      console.log(
        `Didn't find connection ${ account.connection_id } in the accountMapping. Adding connection to map...`,
      );
      mapping[ account.connection_id ] = {};
      mapConnection = mapping[ account.connection_id ];
    }
    let mapAccount = mapConnection[ account.account_id ];
    if ( !mapAccount ) {
      console.log(
        `Didn't find account ${ account.account_id } in the accountMapping. Adding account to map...`,
      );
      mapConnection[ account.account_id ] = { include: true };
      mapAccount = mapConnection[ account.account_id ];
    }
    mapAccount.include = !mapAccount.include;
    this.removeAccountsNotIncluded();
    this.scanPositionsForMissingData( account.positions );
    this.categorizeAccounts( false );
    this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
    this._state.notifyDataChanged( EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED, {} );
    this._gdService
      .updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: (/*resp: any*/ ) => {
          this.getAllAggregatorAccountsAndCopies().forEach( ( a: Account ) => {
            if ( a.account_id === account.account_id ) {
              a.included = mapAccount.include;
            }
          } );
          callback( mapAccount.include );
        },
        error: err => {
          console.error( err );
          this._alertService.showAlert( {
            title: 'Error Hiding/Including Account',
            message: `We had a problem on our end hiding/including the account. Please try again later. ${ Util.getContactSupportString() }`,
            showCloseButton: true,
          } );
        },
      } );
  }

  /*
   * Function for including or hiding an account in the mapping
   * @param account {Object} - account to be included or hidden
   * @param callback {Function} - callback function to be called when the account mapping is finished updating. Mostly
   * for getting updated for notification purposes
   * */
  includeAccountNew( account: any, callback: Function, option: boolean | null ) {
    const mapping = this._state.globalVars.accountMapping.mapping;
    let mapConnection = mapping[ account.connection_id ];
    if ( !mapConnection ) {
      console.log(
        `Didn't find connection ${ account.connection_id } in the accountMapping. Adding connection to map...`,
      );
      mapping[ account.connection_id ] = {};
      mapConnection = mapping[ account.connection_id ];
    }
    let mapAccount = mapConnection[ account.account_id ];
    if ( !mapAccount ) {
      console.log(
        `Didn't find account ${ account.account_id } in the accountMapping. Adding account to map...`,
      );
      mapConnection[ account.account_id ] = { include: true };
      mapAccount = mapConnection[ account.account_id ];
    }
    mapAccount.include = option;
    this.removeAccountsNotIncluded();
    this.scanPositionsForMissingData( account.positions );
    this.categorizeAccounts( false );
    this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
    this._state.notifyDataChanged( EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED, {} );
    this._gdService
      .updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: (/*resp: any*/ ) => {
          this.getAllAggregatorAccountsAndCopies().forEach( ( a: Account ) => {
            if ( a.account_id === account.account_id ) {
              a.included = mapAccount.include;
            }
          } );
          callback( mapAccount.include );
        },
        error: err => {
          console.error( err );
          this._alertService.showAlert( {
            title: 'Error Hiding/Including Account',
            message: `We had a problem on our end hiding/including the account. Please try again later. ${ Util.getContactSupportString() }`,
            showCloseButton: true,
          } );
        },
      } );
  }

  /**
   * Function for removing a deleted connection for the account mapping
   * @param connection {Object} - connection to be removed from the mapping
   * */
  removeConnectionFromMapping( connection: any ) {
    const mapping = this._state.globalVars.accountMapping;

    delete mapping[ connection.id ];
    this._gdService
      .updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: (/*resp: any*/ ) => {
          // console.log( resp );
        },
        error: err => {
          console.error( err );
          this._alertService.showAlert( {
            title: 'Error Removing Connection',
            message: `We had a problem on our end removing the connection. Please try again later. ${ Util.getRefCodeSupportString(
              err.refCode,
            ) }`,
            showCloseButton: true,
          } );
        },
      } );
  }

  /**
   * Function for adding a new connection to the account mapping
   * @param {string} connectionId - connection to be added to the mapping
   * */
  addConnectionToAccountMap( connectionId: string ) {
    // console.log( connection );
    this.checkMapping();
    const mapping = this._state.globalVars.accountMapping.mapping;
    if ( !mapping[ connectionId ] ) {
      mapping[ connectionId ] = {};
    }
  }

  /**
   * Function for checking to see if the account mapping exists. If not, one is created
   * */
  checkMapping() {
    const mapping = this._state.globalVars.accountMapping.mapping;
    if ( !mapping ) {
      console.warn( `Didn't find a mapping in the global vars. Creating one...` );
      this.createAccountMapping();
    }
    return mapping;
  }

  /**
   * Function for creating the account mapping
   * */
  createAccountMapping() {
    const mapping = {};
    for ( const a of this._state.globalVars.allAccountsFromAgg ) {
      if ( !mapping[ a.connection_id ] ) {
        mapping[ a.connection_id ] = {};
      }
      mapping[ a.connection_id ][ a.account_id ] = { include: true };
    }
    this._state.globalVars.accountMapping.mapping = mapping;
    this._gdService
      .createUserAccountMapping( mapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // console.log( resp );
          this._state.globalVars.accountMapping.id = resp.data.id;
        },
        error: err => {
          if ( err && err.err && err.err.indexOf( 'ER_DUP_ENTRY' ) >= 0 ) {
            console.info( 'Tried to make duplicate map entry...ignoring' );
          }
        },
      } );
  }

  /**
   * Function for resetting the accounts to their original snapshot
   * */
  resetAccounts() {
    this._state.globalVars.allAccounts = _.cloneDeep(
      this._state.globalVars.allAccountsOriginal,
    );
    this._state.globalVars.allManualAccounts = _.cloneDeep(
      this._state.globalVars.allManualAccountsOriginal,
    );
    this.categorizeAccounts( false );
    // console.log( 'reset accounts' );
  }

  pricesLastRetrieved: moment.Moment;

  typesForPricing: any = {
    'mutual fund': true,
    etf: true,
    etn: true,
    'closed-end fund': true,
    'closed end fund': true,
    'open ended fund': true,
    unknown: true,
    stock: true,
    'preferred stock': true,
    equity: true,
    bond: true,
    tips: true,
    'certificate of deposit': true,
  };

  /**
   * Function for getting updated prices for positions
   * */
  refreshPrices() {

    if ( this._state.globalVars.inWealthFluent ) {
      this.needWFToRefreshPrices.next();
    } else {
      this._state.globalVars.refreshingPrices = true;
      const positions = [
        ...this.getAllPositionsIncludingManualAccounts(),
        ...this.getAllPositionsWithCopies(),
        ...this._state.globalVars.securities,
      ];
      let tickersWithTypes = [];
      for ( const p of positions ) {
        if ( this.typesForPricing[ p?.security_type?.toLowerCase() ] ) {
          tickersWithTypes.push( { identifier: p.ticker, type: p.security_type } );
        }
      }
      tickersWithTypes = _.uniqBy( tickersWithTypes, 'identifier' );
      if (
        this.pricesLastRetrieved &&
        moment().diff( this.pricesLastRetrieved, 'minutes' ) < 15
      ) {
        this._state.notifyDataChanged( EVENT_NAMES.PRICES_UPDATED, null );
        return;
      }
      this._pricingService
        .getPrices( tickersWithTypes )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.pricesLastRetrieved = moment();
            const quoteMap = resp.data;
            // console.log( tickerPriceMap );
            for ( const p of positions ) {
              const quote = quoteMap[ p.ticker ];
              if ( quote && quote.price && quote.price !== 0 ) {
                p.price = quote.price;
                p.quote = quote;
                p.value = p.quantity * p.price;
                p.revised_value = ( p.quantity + p.revised_quantity ) * p.price;
                if ( p.cost_basis ) {
                  p.gain_loss = p.value - p.cost_basis;
                }
                OneDayChangeUtil.calcOneDayChange(
                  p,
                  this._state.globalVars.todayIsTradingDay,
                );
                // todo: update any other fields that are affected by price change
              }
            }
            this._state.globalVars.refreshingPrices = false;
            this._state.notifyDataChanged( EVENT_NAMES.PRICES_UPDATED, null );
          },
          error: err => {
            console.error( err );
            this._state.globalVars.refreshingPrices = false;
            this._state.notifyDataChanged( EVENT_NAMES.PRICES_UPDATED, err );
          },
        } );
    }
  }

  /**
   *
   * @param fee - percent fee
   * @param account - account the fee is in
   */
  setFeeGlobally( fee: number, account: Account ) {
    const accounts = _.filter(
      [
        ...this._state.globalVars.allAccounts,
        ...this._state.globalVars.allAccountsOriginal,
        ...this._state.globalVars.allAccountsFromAgg,
      ],
      ( a: any ) => {
        return a.account_id === account.account_id;
      },
    );
    for ( const a of accounts ) {
      a.management_fee = fee;
      a.user_defined_management_fee = true;
    }
    this._state.notifyDataChanged( EVENT_NAMES.ADVISOR_FEE_UPDATED, {} );
    this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
  }

  setAccountFee( account: Account ) {
    const fee = this.getCurrentFee( account );
    if ( fee !== 0 ) {
      this.setFeeGlobally( fee, account );
    }
  }

  getCurrentFee( account: Account ) {
    const acc = this._state.getAccountOverride( account );
    if ( acc ) {
      const fee = acc.advisor_fee;
      if ( fee ) {
        return fee;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  /**
   *
   * @param nickname - string - new nickname
   * @param account - account whose description needs to be updated
   */
  setNameGlobally( nickname: string, account: any ) {
    const accounts = _.filter(
      [
        ...this._state.globalVars.allAccounts,
        ...this._state.globalVars.allAccountsOriginal,
        ...this._state.globalVars.allAccountsFromAgg,
      ],
      ( a: any ) => {
        return a.account_id === account.account_id;
      },
    );
    for ( const a of accounts ) {
      a.description = nickname;
      a.formattedDescription = a.description;
    }
  }

  private static loanIsManual( p: any ) {
    return [
      'MANUALMORTGAGE',
      'MANUALLOAN',
      'MANUALAUTOLOAN',
      'MANUALBOND',
    ].includes( p.ticker );
  }

  setNewlyAddedConnection( connection_id: number | string ) {
    this.newlyAddedConnections[ connection_id ] = true;
  }

  wasAccountNewlyAdded( connection_id: number | string ) {
    return !!this.newlyAddedConnections[ connection_id ];
  }

  /**
   * Initiate a refresh of the provided account with the aggregator service.
   * @param account - Account that is to be synced with the aggregator service
   * @param institutionIcon - InstitutionIconComponent that is in the account's row (in balance sheet or accounts page)
   * @param snackBar - MatSnackBar instance that is in the component calling the function
   * @param callback - Function to be called after the account refresh is complete
   */
  refreshAccount(
    account: Account,
    institutionIcon: InstitutionIconComponent,
    snackBar: MatSnackBar,
    callback: Function,
  ) {
    if ( !account.isManual && !this._state.globalVars.editing ) {
      this.setAccountAsRefreshing( account.account_id, institutionIcon );
      this._gdService
        .syncConnection( account.connection_id )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( response: any ) => {
            console.log( response );
            this.checkAccountSyncProgress(
              account.connection_id,
              account.account_id,
              institutionIcon,
              snackBar,
              account.formattedDescription,
              callback,
            );
          },
          error: err => {
            console.log( err );
            if ( this.accountsRefreshing[ account.account_id ] ) {
              this.setAccountAsIdle( account.account_id, institutionIcon );
              if ( err.err ) {
                if ( err.err.includes( 'Y821' ) ) {
                  snackBar.open(
                    `Cannot sync accounts more often than every 15 minutes. Retrieving latest available data...`,
                    null,
                    Util.getSnackBarOptions(),
                  );
                  this.getUpdatedConnectionStatusAndRetrieveAccountData(
                    account,
                    institutionIcon,
                    callback,
                  );
                } else if ( err.err.includes( 'Y825' ) ) {
                  snackBar.open(
                    `Account syncing already in progress`,
                    null,
                    Util.getSnackBarOptions(),
                  );
                } else {
                  snackBar.open(
                    `Error Syncing Account: ${ err.err }`,
                    'dismiss',
                    Util.getSnackBarOptions( true ),
                  );
                }
              } else {
                snackBar.open(
                  `Error Syncing Account: ${ err }`,
                  'dismiss',
                  Util.getSnackBarOptions( true ),
                );
              }
            }
          },
        } );
    }
  }

  getUpdatedConnectionStatusAndRetrieveAccountData(
    account: Account,
    institutionIcon: InstitutionIconComponent,
    callback: Function,
  ) {
    this._gdService
      .checkSyncProgress()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          const connection = response.data.find( ( c: Connection ) => {
            return c.id === account.connection_id;
          } );
          if ( connection ) {
            this.retrieveUpdatedAccountData(
              connection,
              account.account_id,
              institutionIcon,
              callback,
            );
          } else {
            this.setAccountAsIdle( account.account_id, institutionIcon );
          }
        },
        error: err => {
          console.error( err );
          this.setAccountAsIdle( account.account_id, institutionIcon );
        },
      } );
  }

  /**
   * Check on the progress of an account/connection sync with the aggregator service
   * @param connection_id - id of the connection the account belongs to, because that is what actually needs to be synced
   * @param account_id - id of the account that was actually clicked to be refreshed
   * @param institutionIcon - InstitutionIconComponent that is in the account's row (in balance sheet or accounts page)
   * @param snackBar - MatSnackBar instance that is in the component calling the function
   * @param accountDescription - formatted string of the account name/description to put in snackbar messages
   * @param callback - Function to be called after the account refresh is complete
   */
  checkAccountSyncProgress(
    connection_id: number | string,
    account_id: number | string,
    institutionIcon: InstitutionIconComponent,
    snackBar: MatSnackBar,
    accountDescription: string,
    callback: Function,
  ) {
    this._gdService
      .checkSyncProgress()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          // console.log( response );

          const connection = response.data.find( ( c: Connection ) => {
            return c.id === connection_id;
          } );

          if (
            connection &&
            connection.status !== 'IN_PROGRESS' &&
            connection.status !== 'LOGIN_IN_PROGRESS'
          ) {
            if ( this.isAccountRefreshing( account_id ) ) {
              this.retrieveUpdatedAccountData(
                connection,
                account_id,
                institutionIcon,
                callback,
              );
            } else {
              // not sure if this should be possible, but let's set the account as idle just in case
              this.setAccountAsIdle( account_id, institutionIcon );
              this.updateAggregatedAccountStatus( account_id, connection.status );
            }
          } else {
            setTimeout( () => {
              // can't do anything if no entry in the map, so set the account as idle
              if ( this.isAccountRefreshing( account_id ) ) {
                // if we get to the max refresh checks we should take a break and start an interval to wait on
                if (
                  this.accountsRefreshing[ account_id ].checkSyncTries >
                  this.maxAccountRefreshChecks
                ) {
                  snackBar.open(
                    `Refresh of ${ accountDescription } taking longer than expected, will try again soon`,
                    null,
                    Util.getSnackBarOptions(),
                  );
                  this.setAccountAsIdle( account_id, institutionIcon );
                  if (
                    !this.accountsRefreshing[ account_id ].syncIntervalRetries ||
                    this.accountsRefreshing[ account_id ].syncIntervalRetries <
                    this.maxSyncIntervalRetries
                  ) {
                    this.setIntervalForAccountRefreshRetry( account_id, () => {
                      this.setAccountAsRefreshing( account_id, institutionIcon );
                      this.checkAccountSyncProgress(
                        connection_id,
                        account_id,
                        institutionIcon,
                        snackBar,
                        accountDescription,
                        callback,
                      );
                    } );
                  } else {
                    snackBar.open(
                      `We have tried several times to get updated info for ${ accountDescription }, but our aggregation provider is still syncing the account with the institution. Please check back later`,
                      null,
                      Util.getSnackBarOptions(),
                    );
                    // clear interval and reset interval retries
                    this.clearAccountRefreshRetryInterval( account_id );
                    return;
                  }
                } else {
                  // haven't hit max, so increment the syncTries and call checkSyncProgress again
                  this.accountsRefreshing[ account_id ].checkSyncTries++;
                  this.checkAccountSyncProgress(
                    connection_id,
                    account_id,
                    institutionIcon,
                    snackBar,
                    accountDescription,
                    callback,
                  );
                }
              } else {
                this.setAccountAsIdle( account_id, institutionIcon );
              }
            }, this.accountRefreshPollingInterval );
          }
        },
        error: err => {
          if ( this.accountsRefreshing[ account_id ] ) {
            // this should be false/undefined if the user logged out or swtiched workspaces
            this.setAccountAsIdle( account_id, institutionIcon );
            snackBar.open(
              `Error Syncing Account: ${ err.err }`,
              'dismiss',
              Util.getSnackBarOptions( true ),
            );
          }
        },
      } );
  }

  /**
   * After a sync has been completed, retrieve the updated account info
   * @param connection - institution connection (providerAccount) the account is contained in
   * @param account_id - id of the account that was actually clicked to be refreshed
   * @param institutionIcon - InstitutionIconComponent that is in the account's row (in balance sheet or accounts page)
   * @param callback - Function to be called after the account refresh is complete
   */
  retrieveUpdatedAccountData(
    connection: Connection,
    account_id: number | string,
    institutionIcon: InstitutionIconComponent,
    callback: Function,
  ) {
    this._gdService
      .getAggAccountWithPositions( account_id )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          // console.log( response );
          const updatedAccount: Account = response.data;
          updatedAccount.status = connection.status;
          this.updateAggregatedAccount( updatedAccount );
          this._state.notifyDataChanged(
            EVENT_NAMES.RECALCULATE_ALLOCATIONS,
            {},
          );
          callback();
          this.setAccountAsIdle( account_id, institutionIcon );
        },
        error: err => {
          console.error( err );
          this.setAccountAsIdle( account_id, institutionIcon );
        },
      } );
  }

  /**
   * Put account into the refreshing map (or set it's refreshing prop to true) and reset it's tries. Then turn on the
   * spinner in the icon component
   * @param account_id - id of the account that was actually clicked to be refreshed
   * @param institutionIcon - InstitutionIconComponent that is in the account's row (in balance sheet or accounts page)
   */
  setAccountAsRefreshing(
    account_id: number | string,
    institutionIcon: InstitutionIconComponent,
  ) {
    const aStatusObj: AccountRefreshStatusItem =
      this.accountsRefreshing[ account_id ];
    if ( aStatusObj ) {
      aStatusObj.refreshing = true;
      aStatusObj.checkSyncTries = 0;
    } else {
      this.accountsRefreshing[ account_id ] = {
        refreshing: true,
        checkSyncTries: 0,
      };
    }
    if ( institutionIcon ) {
      institutionIcon.showSpinner();
    }
  }

  /**
   * Account should be in the refreshing map, but we check anyway and then set it's refreshing prop to false and
   * reset it's tries. Then turn off the spinner in the icon component
   * @param account_id - id of the account that was actually clicked to be refreshed
   * @param institutionIcon - InstitutionIconComponent that is in the account's row (in balance sheet or accounts page)
   */
  setAccountAsIdle(
    account_id: number | string,
    institutionIcon: InstitutionIconComponent,
  ) {
    const aStatusObj: AccountRefreshStatusItem =
      this.accountsRefreshing[ account_id ];
    if ( aStatusObj ) {
      aStatusObj.refreshing = false;
      aStatusObj.checkSyncTries = 0;
      delete aStatusObj.nextRetry;
    } else {
      this.accountsRefreshing[ account_id ] = {
        refreshing: false,
        checkSyncTries: 0,
      };
    }
    if ( institutionIcon ) {
      institutionIcon.hideSpinner();
    }
  }

  cancelAccountRefreshInterval( account_id: number | string ) {
    const aStatusObj: AccountRefreshStatusItem =
      this.accountsRefreshing[ account_id ];
    if ( aStatusObj ) {
      clearInterval( aStatusObj.retryInterval );
      delete aStatusObj.retryInterval;
    } else {
      // shouldn't need to do anything here (but also shouldn't really get to this line
    }
  }

  /**
   * This function turns off all the refreshing of accounts. It clears intervals for checking sync progress and turns
   * off the spinners on intitution icons on the accounts page.
   * On logout, this will be called without the icons because the accounts will be gone
   * @param {QueryList<InstitutionIconComponent>} institutionIcons - optional list of icons to be set idle
   */
  turnOffAllRefreshes( institutionIcons?: QueryList<InstitutionIconComponent> ) {
    for ( const key of Object.keys( this.accountsRefreshing ) ) {
      if ( this.accountsRefreshing[ key ] ) {
        if ( this.accountsRefreshing[ key ].retryInterval ) {
          clearInterval( this.accountsRefreshing[ key ].retryInterval );
        }
        if ( institutionIcons ) {
          const instIcon = institutionIcons.find(
            ( icon: InstitutionIconComponent ) => {
              return icon.acct.account_id === key;
            },
          );
          this.setAccountAsIdle( key, instIcon );
        }
        delete this.accountsRefreshing[ key ];
      }
    }
  }

  /**
   * Check to see if the account is refreshing
   * @param account_id - id of the account to check to see if it is refreshing
   */
  isAccountRefreshing( account_id: number | string ) {
    const aStatusObj: AccountRefreshStatusItem =
      this.accountsRefreshing[ account_id ];
    if ( aStatusObj ) {
      return aStatusObj.refreshing;
    } else {
      return false;
    }
  }

  /**
   * The provided account is newly added and status must be not successful, so we need to check the progress of the
   * initial sync
   * @param account_id - id of the account that was actually clicked to be refreshed
   * @param institutionIcons - QueryList of InstitutionIconComponents (all the icons in the calling component)
   * @param snackBar - MatSnackBar instance that is in the component calling the function
   * @param accountDescription
   * @param callback - Function to be called after the account refresh is complete
   */
  refreshInProgressAccount(
    account_id: number | string,
    institutionIcons: QueryList<InstitutionIconComponent>,
    snackBar: MatSnackBar,
    accountDescription: string,
    callback: Function,
  ) {
    if ( !this._state.globalVars.editing ) {
      const instIcon = institutionIcons.find(
        ( icon: InstitutionIconComponent ) => {
          return icon.acct.account_id === account_id;
        },
      );

      this.setAccountAsRefreshing( account_id, instIcon );
      this.checkAccountSyncProgress(
        instIcon.acct.connection_id,
        account_id,
        instIcon,
        snackBar,
        accountDescription,
        callback,
      );
    }
  }

  /**
   * Sets an interval in the AccountRefreshStatusItem for counting down till the next account refresh attempt
   * @param account_id - account id to use in setting up the interval in the accountsRefreshing map
   * @param callback - function to call when the countdown is over
   */
  setIntervalForAccountRefreshRetry(
    account_id: number | string,
    callback: Function,
  ) {
    // set the number of seconds to wait to retry the account refresh polling
    this.accountsRefreshing[ account_id ].nextRetry = this.refreshRetryLength;
    // set an interval to decrement the nextRetry value so the view can update the countdown
    this.accountsRefreshing[ account_id ].retryInterval = setInterval( () => {
      this.accountsRefreshing[ account_id ].nextRetry--; // decrement nextRetry by 1 (second)
      if ( this._state.globalVars.accountsComponent ) {
        this._state.globalVars.accountsComponent.doChanges(); // need to tell the accounts component to check for changes
      }
      // check if nextRetry is down to 0
      if ( this.accountsRefreshing[ account_id ].nextRetry <= 0 ) {
        // end of countdown, clear interval
        this.clearAccountRefreshRetryInterval( account_id );
        // initiate refresh
        callback();
      }
    }, 1000 ); // interval is 1 second so it counts down
    if ( this.accountsRefreshing[ account_id ].syncIntervalRetries ) {
      this.accountsRefreshing[ account_id ].syncIntervalRetries++;
    } else {
      this.accountsRefreshing[ account_id ].syncIntervalRetries = 1;
    }
  }

  accountRefreshRetryIntervalExists( account_id: number | string ) {
    return (
      this.accountsRefreshing[ account_id ] &&
      this.accountsRefreshing[ account_id ].retryInterval
    );
  }

  /**
   * Clears an interval for retrying account refresh
   * @param account_id
   */
  clearAccountRefreshRetryInterval( account_id: number | string ) {
    clearInterval( this.accountsRefreshing[ account_id ].retryInterval );
    this.accountsRefreshing[ account_id ].retryInterval = undefined;
    this.accountsRefreshing[ account_id ].syncIntervalRetries = 0;
  }

  /**
   *
   * @param accountManager - the AccountManager instance
   * @param institutionIcons - QueryList of InstitutionIconComponents (all the icons in the calling component)
   * @param snackBar - MatSnackBar instance that is in the component calling the function
   */
  accountStatusIntervalFunction(
    accountManager: AccountManager,
    institutionIcons: QueryList<InstitutionIconComponent>,
    snackBar: MatSnackBar,
  ) {
    const accounts = accountManager.getAllRevisableAccounts();
    for ( const a of accounts ) {
      if (
        ( a.status === 'IN_PROGRESS' || a.status === 'LOGIN_IN_PROGRESS' ) &&
        !accountManager.isAccountRefreshing( a.account_id ) &&
        !accountManager.accountRefreshRetryIntervalExists( a.account_id )
      ) {
        accountManager.refreshInProgressAccount(
          a.account_id,
          institutionIcons,
          snackBar,
          a.formattedDescription,
          () => {
            // console.log( `Finished Updating account: ${ a.account_id }` );
          },
        );
      }
    }
  }

  /**
   *
   * @param acct
   */
  formatAccountDescription( acct: Account ) {
    return Util.formatAccountDescription( acct );
  }

  hasRevisableAccounts(): boolean {
    return this.getAllRevisableAccounts().length > 0;
  }

  toggleEditingAndResetAccounts() {
    const editing = this._state.globalVars.editing;
    const accountsComp: AccountsComponent =
      this._state.globalVars.accountsComponent;
    if ( editing ) {
      accountsComp.exitEditMode();
      this._state.globalVars.unsavedChanges = false;
      setTimeout( () => {
        this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
      }, 1000 );
    }
  }

  private currentWorkspaceHasConnectedAccounts() {
    if ( !this._state.globalVars.currentWorkspace ) {
      return true;
    } else {
      return (
        this._state.globalVars.currentWorkspace?.owner?.yodlee_user_id ||
        ( this._state.globalVars.currentWorkspace?.users &&
          Util.getLoggedInUser( this._auth ).yodlee_user_id )
      );
    }
  }
}
