import { Injectable, OnDestroy } from '@angular/core';
import { OverridesService } from '../globalData/overrides.service';
import { takeUntil } from 'rxjs/operators';
import { Util } from './util.service';
import {
  GlobalOverride,
  GlobalOverrideItem,
  LocalOverrideItem, NotificationIssue,
  OverrideGroupSetItem,
} from './dataInterfaces';
import { Position, Account } from '@ripsawllc/ripsaw-analyzer';
import { Subject } from 'rxjs';
import { OverrideHelpers } from './overrideHelpers';
import { GlobalState } from '../global.state';
import { AccountManager } from './accountManager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { EVENT_NAMES } from './enums';
import { environment } from '../../environments/environment';
import { Logger } from './logger.service';
import { NotificationsService } from '../globalData';
import { Auth } from '../auth.service';

@Injectable()

export class OverridesUtil implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  readonly dataCheck: Subject<void> = new Subject<void>();

  globalOverrideItems: GlobalOverrideItem[] = [];
  localOverrideItems: LocalOverrideItem[] = [];
  positionsWithMissingData: Position[] = [];

  subscriberName: string = 'overridesUtil';

  firstLoadDone: boolean = false;

  constructor( private _overridesService: OverridesService,
               private _state: GlobalState,
               private _accountManager: AccountManager,
               public snackBar: MatSnackBar,
               public dialog: MatDialog,
               private _notificationsService: NotificationsService,
               private _auth: Auth ) {

    this._state.subscribe( [
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.POSITION_OVERRIDDEN,
      EVENT_NAMES.PROCESS_DATA_QUALITY_ISSUES,
    ].join( ' | ' ), () => {
      this.firstLoadDone = true;
      this.checkAndCompileOverrideData();
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.reset();
    }, this.subscriberName );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.POSITION_OVERRIDDEN,
      EVENT_NAMES.PROCESS_DATA_QUALITY_ISSUES,
      EVENT_NAMES.LOGOUT,
    ].join( ' | ' ), this.subscriberName );
  }


  checkAndCompileOverrideData() {
    this.globalOverrideItems = [];
    this.localOverrideItems = [];
    this.positionsWithMissingData = [];

    // loop through all accounts and their positions to get the active global override items, their accounts and also
    // check for positions with missing data
    this._state.globalVars.allAccountsFromAgg.forEach( ( a: Account ) => {

      a.positions?.forEach( ( p: Position ) => {
        if ( p.global_override_id ) {
          // get the global override from state
          const go: GlobalOverride = this._state.globalVars.globalOverrides[p.global_override_id];
          // check to see if there is already a goi
          const i = this.globalOverrideItems.find( ( goi: GlobalOverrideItem ) => {
            return goi.override.id === go.id;
          } );

          if ( i ) {
            // if there is a goi, then just add the account to its list of accounts
            i.accounts.push( a );
          } else {
            // setup keysets for displaying the overrides
            const globalKeySets: OverrideGroupSetItem[] = OverrideHelpers.buildGlobalKeySets( {
              ignoreFunc: null,
              position: p,
              positionOverrides: go?.overrides ?? {},
              state: this._state,
              account: null,
            } );
            // set up the initial global override item
            const goi: GlobalOverrideItem = {
              override: go,
              accounts: [ a ],
              spinning: false,
              overridesEmpty: Object.keys( go.overrides ).length === 0,
              globalKeySets,
            };

            // add the item to the dialog's list
            this.globalOverrideItems.push( goi );
          }
        }

        const localOverrides = OverrideHelpers.getLocalPositionOverrides( this._state, p, a );
        if ( localOverrides && Object.keys( localOverrides ).length > 0 && !p.overridden_ticker ) {
          const localKeySet: OverrideGroupSetItem = OverrideHelpers.buildLocalKeySet(
            p,
            localOverrides,
            a,
            this._state,
          );

          const loi: LocalOverrideItem = {
            account: a,
            position: p,
            overrides: localOverrides,
            spinning: false,
            localKeySet,
          };

          this.localOverrideItems.push( loi );
        }

        // check the position for missing data and add to the missing data list for the dialog if it has the field set
        if ( p.missing_data?.length > 0 && a.included ) {
          p.formatted_account_description = a.formattedDescription;
          this.positionsWithMissingData.push( p );
        }

      } );

    } );

    // loop through global overrides to find any that are not in use
    Object.keys( this._state.globalVars.globalOverrides ).forEach( ( key ) => {

      const go: GlobalOverride = this._state.globalVars.globalOverrides[key];
      // try and grab the global override item
      const i = this.globalOverrideItems.find( ( goi: GlobalOverrideItem ) => {
        return goi.override.id === go.id;
      } );
      // if there is no item, then this is an old override that is no longer in use, so make an item for it with no position or accounts
      if ( !i ) {
        const accounts = [];
        const globalKeySets: OverrideGroupSetItem[] = OverrideHelpers.buildGlobalKeySets( {
          ignoreFunc: null,
          position: {},
          positionOverrides: go?.overrides ?? {},
          state: this._state,
          account: null,
        } );

        const goi: GlobalOverrideItem = {
          override: go,
          accounts,
          spinning: false,
          overridesEmpty: Object.keys( go.overrides ).length === 0,
          globalKeySets,
        };

        this.globalOverrideItems.push( goi );
      }
    } );

    if ( this.positionsWithMissingData.length > 0 || this._state.globalVars.dataQualityIssues?.length > 0 ) {
      this.alertAdmins( 'dataQuality' );
    }
    this.dataCheck.next();
  }

  deleteGlobalOverride( go: GlobalOverride, cb?: Function ) {
    this._overridesService.deleteOverride( go.id ).pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          console.log( resp );
          this.removeOverrideFromGlobalList( go );
          this.removeUsages( go );
          this.snackBar.open( 'Global Override removed successfully', null, Util.getSnackBarOptions() );
          cb?.();
        }, error: ( err ) => {
          console.error( err );
          this.snackBar.open( `Error removing global override: ${ err?.err }. ${ Util.getRefCodeSupportString( err?.refCode ) }`,
            null, Util.getSnackBarOptions() );
          cb?.( err );
        },
      } );
  }

  removeOverrideFromGlobalList( go: GlobalOverride ) {
    delete this._state.globalVars.globalOverrides[go.id];
  }

  removeUsages( go: GlobalOverride ) {
    this._accountManager.getAllAggregatorAccountsAndCopies().forEach( ( a: Account ) => {
      const positionsAffected: Position[] = [];
      a.positions.forEach( ( p: Position ) => {
        if ( p.global_override_id === go.id ) {
          delete p.global_override_id;
          OverrideHelpers.setWithOldValues( p, go.overrides );
          positionsAffected.push( p );
        }
      } );
      this._accountManager.scanPositionsForMissingData( positionsAffected );
      // this._state.notifyDataChanged( EVENT_NAMES.POSITION_OVERRIDDEN, p );
    } );
    this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
  }

  updateAllCopiesOfPositionAfterRemove( updatedPosition: Position ) {
    this._accountManager.getAllPositionsWithCopies().forEach( ( p: Position ) => {
      if ( p.id === updatedPosition.id ) {
        Object.assign( p, updatedPosition );
      }
    } );
  }

  private alertAdmins( type: any ) {
    if ( !this.firstLoadDone ) {
      if ( environment.env !== 'dev' ) {
        const user = Util.getLoggedInUser( this._auth );

        let message = `
        ${ user.name } has ${ type } issue(s).\n
        `;

        if ( this.positionsWithMissingData.length > 0 ) {

          message += `
          Here is a list of positions with missing data: \n
         ${ JSON.stringify( this.positionsWithMissingData ) }
        `;
        }

        if ( this._state.globalVars.dataQualityIssues.length > 0 ) {
          message += ` \n
          Here is a list of data inconsistencies: \n
          ${ JSON.stringify( this._state.globalVars.dataQualityIssues ) }
          `;
        }

        this._notificationsService.alertAdmins( {
          type,
          user: user.name,
          message,
        } )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( /*response: any*/ ) => {
              // console.log( response );
            }, error: ( err ) => {
              console.error( err );
            },
          } );

      } else {
        Logger.info( 'alert dev admin' );
      }
    }
  }

  private reset() {
    this.firstLoadDone = false;
    this.globalOverrideItems = [];
    this.localOverrideItems = [];
    this.positionsWithMissingData = [];
  }
}
