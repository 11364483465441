import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { RiskReturn } from '../../utils/dataInterfaces';
import { RipsawDecimalPipe } from '../../theme/pipes';
import { GlobalDataService, MarketDataService } from '../../globalData';
import { GlobalState } from '../../global.state';
import { UsersUtil } from '../../utils/users.util';
import { Auth } from '../../auth.service';
import { environment } from '../../../environments/environment';
import { ChartColorUtil } from '../../utils/chart-color.util';
import { TradingViewSymbolOverviewComponent } from '../tradingView';
import { MarketInfoUtil } from '../../utils/market-info.util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { MarketCalcTerms } from '../../utils/enums';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component( {
  selector: 'rip-bond-markets-tab',
  templateUrl: './bond-markets-tab.component.html',
  styleUrls: [ './bond-markets-tab.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class BondMarketsTabComponent implements OnDestroy {

  @ViewChild( 'tradingViewSymbolChart' ) tradingViewSymbolChart: TradingViewSymbolOverviewComponent;
  @ViewChild( 'forwardLookingTable', { static: false } ) forwardLookingTable: DatatableComponent;

  faPlusCircle: IconDefinition = faPlusCircle;
  faMinusCircle: IconDefinition = faMinusCircle;
  ColumnMode = ColumnMode;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  readonly primeIdentifiers: any = {
    'VGSH': { longName: 'US Short-Term Government', shortName: 'US ST Gov' },
    'VGIT': { longName: 'US Intermediate-Term Government', shortName: 'US IT Gov' },
    'VGLT': { longName: 'US Long-Term Government', shortName: 'US LT Gov' },
    'VCSH': { longName: 'US Short-Term Investment Grade Corporate', shortName: 'US ST IG Corp' },
    'VCIT': { longName: 'US Intermediate-Term Investment Grade Corporate', shortName: 'US IT IG Corp' },
    'VCLT': { longName: 'US Long-Term Investment Grade Corporate', shortName: 'US LT IG Corp' },
    'VWEAX': { longName: 'High Yield Corporate', shortName: 'HY Corp' },
    'VMBS': { longName: 'Mortgage-Backed Securities', shortName: 'MBS' },
    'BND': { longName: 'Total US Investment Grade', shortName: 'Total US IG' },
    'BNDX': { longName: 'Total International', shortName: 'Total Intl' },
    // 'VWOB': 'Emerging Market Government Bonds',
    // ticker: {longName: '', shortName: ''}
  };

  readonly primeIdentifiersKeysArray: string[] = Object.keys( this.primeIdentifiers );

  tvSymbols = this.primeIdentifiersKeysArray.map( t => {
    return [ t ];
  } );

  riskReturns: Map<string, RiskReturn> = new Map<string, RiskReturn>();
  riskReturnsValues: RiskReturn[] = [];

  updatedRiskPremium: number;
  loading: boolean = true;

  allFunds: any = {};

  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  _pipe: any = { transform: ( val: number) => this.ripDecimalPipe.transform( val, '2-2' ) };
  _LTERPipe: any = { transform: ( val: number) => this.ripDecimalPipe.transform( val * 100, '2-2' ) };

  forwardLookingTableColumns: any[] = [];

  cssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

  messages: any = {
    emptyMessage: 'Loading Risk Return Measures',
    totalMessage: 'Risk Return Measures',
  };

  expRetVsStdDevScatterData: any = {
    datasets: [],
    labels: [],
  };

  longTermExpRetVsStdDevScatterData: any = {
    datasets: [],
    labels: [],
  };

  expRetVsEffDurationScatterData: any = {
    datasets: [],
    labels: [],
  };

  longTermExpRetVsEffDurationScatterData: any = {
    datasets: [],
    labels: [],
  };

  expRetVsSDScatterDataToShow: any = {
    datasets: [],
    labels: [],
  };

  expRetVsEffDurScatterDatasetToShow: any = {
    datasets: [],
    labels: [],
  };

  riskReturnError: boolean = false;
  subscriberName: string = 'BondMarketsTabComponent';

  correlationMatrixReadMore: boolean = false;

  bondsMainBlurbMobileReadMore: boolean = false;

  forwardLookingTableReadMore: boolean = false;

  deviceIsMobile: boolean = false;

  term: MarketCalcTerms = MarketCalcTerms.short;

  appName: string = environment.appName;

  constructor( private _mdService: MarketDataService,
               private _state: GlobalState,
               public dialog: MatDialog,
               private _usersUtil: UsersUtil,
               private _auth: Auth,
               public snackBar: MatSnackBar,
               private _detectorService: DeviceDetectorService,
               private _gdService: GlobalDataService ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    this.setColumns();

    this.resetFunds();
    this.getRiskReturns();
    if ( environment.env !== 'prod' ) {
      window['ripsaw_bondMarketsTab'] = this;
    }

    this._state.subscribe( 'market.calc.settings.changed', () => {
      this.getRiskReturns();
    }, this.subscriberName );

  }

  ngOnDestroy() {
    this._state.unsubscribe( [ 'market.calc.settings.changed' ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  getSharpeRatioColumnCellClass() {
    const self = this;
    return ( { row /*, column, value*/ } ) => {
      const rowIndex = self.forwardLookingTable.bodyComponent.getRowIndex(row);
      return {
        'border-on-top': rowIndex === 0,
        'border-on-bottom': rowIndex === self.riskReturnsValues.length - 1,
        'border-on-sides': true,
      };
    };
  }

  setColumns() {
    this.forwardLookingTableColumns = [
      {
        prop: 'longName',
        name: 'Bond Sector Funds',
        minWidth: 250,
        width: 250,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'expectedReturn' : 'longTermExpectedReturn',
        name: 'Expected Return (%)',
        pipe: this.term === MarketCalcTerms.short ? this._pipe : this._LTERPipe,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'annualizedStandardDeviation' : 'longTermAnnualizedStandardDeviation',
        name: 'Total Volatility (%)',
        pipe: this._pipe,
      },
      {
        prop: 'effectiveDuration',
        name: 'Effective Duration',
        pipe: this._pipe,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'expectedSharpeRatio' : 'longTermSharpeRatio',
        name: 'Expected Sharpe Ratio',
        pipe: this._pipe,
        cellClass: this.getSharpeRatioColumnCellClass(),
      },
    ];

    if ( this.deviceIsMobile ) {
      for ( let i = 1; i < this.forwardLookingTableColumns.length; i++ ) {
        this.forwardLookingTableColumns[i].width = this.forwardLookingTableColumns[i].minWidth = this.forwardLookingTableColumns[i].maxWidth = 80;
      }
    }
  }

  initChart() {
    this.tradingViewSymbolChart.initChart();
  }

  resetFunds() {
    // reset allFunds to prime and then add benchmark funds (not already in the prime list) and user defined funds
    this.allFunds = _.clone( this.primeIdentifiers );
  }

  getRiskReturns() {
    // show spinner
    this.loading = true;


    this._gdService.getSecurities( Object.keys( this.allFunds ).join( ',' ) ).pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( securitiesResponse: any ) => {

        // get expected returns
        this._mdService.getRiskReturns( securitiesResponse.data, 'bond' )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( resp: any ) => {
            this.processReturns( resp?.data );
            this.riskReturnError = MarketInfoUtil.bondRiskReturnsHaveError( this.riskReturnsValues );
            this.expRetVsEffDurScatterDatasetToShow = this.term === MarketCalcTerms.short ? this.expRetVsEffDurationScatterData : this.longTermExpRetVsEffDurationScatterData;
            this.expRetVsSDScatterDataToShow = this.term === MarketCalcTerms.short ? this.expRetVsStdDevScatterData : this.longTermExpRetVsStdDevScatterData;

            this.loading = false;
            this.loading = false; // hide the spinner
          }, ( err ) => {
            console.error( err );
            this.riskReturnError = true;
            this.loading = false;
          } );
      }, ( err ) => {
        console.error( err );
        this.riskReturnError = true;
        this.loading = false;
      } );
  }

  processReturns( returns: RiskReturn[] ) {
    // reset the map
    this.riskReturns = new Map<string, RiskReturn>();

    // setup scatter plot datasets and labels
    this.expRetVsEffDurationScatterData.datasets = [];
    this.expRetVsStdDevScatterData.datasets = [];
    this.longTermExpRetVsStdDevScatterData.datasets = [];
    this.longTermExpRetVsEffDurationScatterData.datasets = [];

    const riskReturnScatterLabels = [];
    const colors = ChartColorUtil.getColorGradients( returns.length );

    // go through the expected returns and set up the map and then back to an array to
    for ( let i = 0; i < returns.length; i++ ) {
      const rr = returns[i];

      rr.label = this.allFunds[rr.identifier].shortName ?? this.allFunds[rr.identifier];
      rr.longName = this.allFunds[rr.identifier].longName || rr.label;
      this.riskReturns.set( rr.identifier, rr );

      // set the label for this risk return set in the scatter plot
      riskReturnScatterLabels.push( rr.label );
      // set the data point and the options for the first plot
      // short term
      this.expRetVsStdDevScatterData.datasets.push( {
        data: [ { x: rr.annualizedStandardDeviation, y: rr.expectedReturn } ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
      } );
      // long term
      this.longTermExpRetVsStdDevScatterData.datasets.push( {
        data: [ { x: rr.longTermAnnualizedStandardDeviation, y: rr.longTermExpectedReturn * 100 } ], // lter needs to be converted from decimal
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
      } );

      // set the data point and the options for the second plot
      // short term
      this.expRetVsEffDurationScatterData.datasets.push( {
        data: [ { x: rr.effectiveDuration, y: rr.expectedReturn } ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
      } );

      // long term
      this.longTermExpRetVsEffDurationScatterData.datasets.push( {
        data: [ { x: rr.effectiveDuration, y: rr.longTermExpectedReturn * 100 } ], // lter needs to be converted from decimal
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
      } );

    }
    this.riskReturnsValues = Array.from( this.riskReturns.values() );
    /* // not sure if this is necessary after all
    // get the base return, VTC to grab the forward-looking header numbers
    const vtcReturn = this.riskReturns.get( 'VTC' );
    if (!vtcReturn) {
      // send error somewhere
    }
    this.updatedRiskPremium = vtcReturn?.updatedRiskPremium; // if, no vtc don't break the whole thing
    */
    this.expRetVsStdDevScatterData.labels = riskReturnScatterLabels;
    this.longTermExpRetVsStdDevScatterData.labels = riskReturnScatterLabels;
    this.expRetVsEffDurationScatterData.labels = riskReturnScatterLabels;
    this.longTermExpRetVsEffDurationScatterData.labels = riskReturnScatterLabels;
  }

  markCorrespondingCorrelationHovered( correlationIdentifier: string, rrIdentifier: string, status: boolean ) {
    for ( const rr of this.riskReturnsValues ) {
      if ( rr.identifier === correlationIdentifier ) {
        for ( const c of rr.correlations ) {
          if ( c.identifier === rrIdentifier ) {
            c.hovered = status;
          }
        }
      }
    }
  }

  changeTerm( term: MarketCalcTerms ) {
    this.term = term;
    console.log( 'change term' );
    this.setColumns();
    this.expRetVsEffDurScatterDatasetToShow = this.term === MarketCalcTerms.short ? this.expRetVsEffDurationScatterData : this.longTermExpRetVsEffDurationScatterData;
    this.expRetVsSDScatterDataToShow = this.term === MarketCalcTerms.short ? this.expRetVsStdDevScatterData : this.longTermExpRetVsStdDevScatterData;
  }

}
