import { Injectable, OnDestroy } from '@angular/core';
import { AdminService } from '../globalData/admin.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalState } from '../global.state';
import { EVENT_NAMES } from './enums';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from './mobileUtil.service';
import { Auth } from '../auth.service';
import { environment } from '../../environments/environment';
import { LoginRecord } from './dataInterfaces';

@Injectable()

export class UserStatsState implements OnDestroy {

  private readonly onDestroy = new Subject<void>();
  deviceIsMobile: boolean = false;

  subscriberName: string = 'UserStatsState';

  currentStats: any = {};
  preRegistrationStats: any = {};

  constructor( private _adminService: AdminService,
               private _state: GlobalState,
               private _deviceDetectorService: DeviceDetectorService,
               private _auth: Auth ) {
    this.deviceIsMobile = MobileUtil.deviceIsMobile( _deviceDetectorService );
    if ( !_auth.authenticated() ) {
      // subscribe to registration completed event so we can mark new users as mobile or desktop
      this.setupRegistrationSubscription();

    }
    // reset class var(s) on logout
    _state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.currentStats = undefined;
      this.preRegistrationStats = {};
      this.setupRegistrationSubscription();
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window['ripsaw_userStatsState'] = this;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  setupRegistrationSubscription() {
    this._state.subscribe( EVENT_NAMES.REGISTRATION_COMPLETED, () => {
      // set up the initial login array and first record
      const loginRecord: LoginRecord = {
        timestamp: new Date(),
        deviceType: this.deviceIsMobile ? 'mobile' : 'desktop',
      };
      // set the singUpDevice and logins user stats
      this.updateUserStatistics( {
        signUpDevice: this.deviceIsMobile ? 'mobile' : 'desktop',
        logins: [ loginRecord ],
        regDetails: this.preRegistrationStats,
      } ).pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: () => {
            console.log( 'marked user as a mobile or desktop signup and created their first login record' );
            // now unsubscribe because this is really unlikely to happen more than once in a session
            this._state.unsubscribe( EVENT_NAMES.REGISTRATION_COMPLETED, this.subscriberName );
          },
          error: ( err ) => {
            console.error( 'error marking user as a mobile or desktop sign up' );
          },
        } );
    }, this.subscriberName );
  }

  updateUserStatistics( stats: any ): Observable<any> {
    return new Observable<any>( observer => {
      this._adminService.getUserStatistics().pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            const userStats = resp.data;
            Object.assign( userStats, stats );
            this.currentStats = userStats;
            this._adminService.setUserStatistics( userStats ).pipe( takeUntil( this.onDestroy ) )
              .subscribe( {
                next: () => {
                  console.log( 'User stats updated' );
                  observer.next();
                },
                error: ( err ) => {
                  console.error( err );
                  observer.error();
                },
              } );
          },
          error: ( err ) => {
            console.error( err );
            observer.error();
          },
        } );
    } );
  }

  getUserStats() {
    return new Observable<any>( observer => {
      this._adminService.getUserStatistics().pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.currentStats = resp.data;
            observer.next();
          },
          error: ( err ) => {
            console.error( err );
            observer.error();
          },
        } );
    } );
  }

  updateLoginStats() {
    this.getUserStats()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: () => {
          const logins = this.currentStats.logins ?? [];
          const loginRecord: LoginRecord = {
            timestamp: new Date(),
            deviceType: this.deviceIsMobile ? 'mobile' : 'desktop',
          };
          logins.push( loginRecord );
          this.updateUserStatistics( {
            logins,
          } )
            .pipe( takeUntil( this.onDestroy ) )
            .subscribe( {
              next: () => {
                // successful tracking of a login
              },
              error: ( err ) => {
                // error, but the user doesn't care. we could maybe send info to admins, but meh
              },
            } );
        },
        error: ( err ) => {
          // error, but the user doesn't care. we could maybe send info to admins, but meh
        },
      } );

  }

}
