import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';


@Component( {
  selector: 'rip-rss-app-feed',
  template: `
    <div  #containerDiv
         style="max-height: 100%;">
      <rssapp-feed id="{{rssAppId}}"></rssapp-feed>
    </div>
  `,

} )

export class RssAppFeedComponent implements AfterViewInit {

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  rssAppId: string = environment.common.rssAppId;

  ngAfterViewInit(): void {
    setTimeout( () => {
      const script = document.createElement( 'script' );
      script.src = 'https://widget.rss.app/v1/feed.js';
      script.async = true;
      this.containerDiv.nativeElement.appendChild( script );
    } );
  }

}
