import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ColumnSet, GlobalState } from '../../../../../global.state';
import { GlobalDataService } from '../../../../../globalData';
import { RipsawPercentPipe } from '../../../../../theme/pipes';
import { Util } from '../../../../../utils/util.service';
import * as _ from 'lodash-es';
import { environment } from '../../../../../../environments/environment';
import { MatchingFundDataSourceComponent } from './components/matching-fund-data-source/matching-fund-data-source.component';
import { BenchmarkComponent } from '../../../../../utils/dataInterfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenerResultsFilterOptions } from '../newInvestmentSelector/newInvestmentSelector.component';
import { RipThemeLoadingSpinnerService } from '../../../../../theme/services';
import { EVENT_NAMES } from '../../../../../utils/enums';
import { Logger } from '../../../../../utils/logger.service';


export interface SubSectorOptionData {
  value: string;
  label: string;
  column?: number;
}

@Component( {
  selector: 'rip-screener',
  templateUrl: './screener.component.html',
  styleUrls: [ './screener.component.scss' ],
} )

export class ScreenerComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @ViewChild( 'matchingFundDataSource', { static: false } ) matchingFundDataSource: MatchingFundDataSourceComponent;

  @Input() mainSector: string;
  @Input() multiSelect: boolean = true;
  @Input() fundTypeOptionsSelected: any = {};
  @Input() fundFamiliesSelected: string[] = [];
  @Input() forBenchmarkProxy: boolean = false;

  @Input() filterOptions: ScreenerResultsFilterOptions;

  @Output() securitiesSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() initComplete: EventEmitter<any> = new EventEmitter<any>();

  static readonly StockTierOptions: SubSectorOptionData[] = [
    { value: 'world_total', label: 'World Stock Market', column: 2 }, // 0
    { value: 'us_total', label: 'Total US Stock Market', column: 1 }, // 1
    { value: 'us_large_cap', label: 'US Large Capitalization Tilt', column: 1 }, // 2
    { value: 'us_mid_cap', label: 'US Mid Capitalization Tilt', column: 1 }, // 3
    { value: 'us_small_cap', label: 'US Small Capitalization Tilt', column: 1 }, // 4
    { value: 'non_us_total', label: 'Total International Stock Markets', column: 3 }, // 5
    { value: 'non_us_developed_markets', label: 'Developed Stock Markets', column: 3 }, // 6
    { value: 'non_us_emerging_markets', label: 'Emerging Stock Markets', column: 3 }, // 7
  ];

  static readonly BondsTierOptions: SubSectorOptionData[] = [
    { value: 'world_total', label: 'World Bond Market', column: 2 }, // 0
    { value: 'us_total_ig', label: 'U.S. Investment Grade', column: 1 }, // 1
    { value: 'us_high_yield', label: 'U.S. High Yield', column: 1 }, // 2
    { value: 'us_treasury_aaa', label: 'U.S. Treasury (AAA)', column: 1 }, // 3
    { value: 'us_corporate_ig', label: 'U.S. Corporate Investment Grade', column: 1 }, // 4
    { value: 'us_agency_mbs', label: 'U.S. Agency MBS', column: 1 }, // 5
    { value: 'us_municipal_ig', label: 'U.S. Municipal Investment Grade', column: 1 }, // 6
    { value: 'non_us_total', label: 'Total International (Non-U.S.)', column: 3 }, // 7
    { value: 'non_us_developed_markets', label: 'Developed Bond Markets', column: 3 }, // 8, new
    { value: 'non_us_emerging_markets', label: 'Emerging Bond Markets', column: 3 }, // 9, old 8
  ];

  static readonly OtherTierOptions: SubSectorOptionData[] = [
    { value: 'other_hedge_funds', label: 'Hedge funds', column: null },
    { value: 'other_variable_annuities', label: 'Variable Annuities', column: null },
    { value: 'other_balanced', label: 'Balanced', column: null },
    { value: 'other_commodity', label: 'Commodity', column: null },
    { value: 'other_gold', label: 'Gold', column: null },
    { value: 'other_liquidity', label: 'Liquidity', column: null },
    { value: 'other_minimum_vol', label: 'Minimum Volatility', column: null },
    { value: 'other_momentum', label: 'Momentum', column: null },
    { value: 'other_quality', label: 'Quality', column: null },
    { value: 'other_multifactor', label: 'Multifactor', column: null },
    { value: 'other_target_date', label: 'Target Date', column: null },
    { value: 'other_specialty', label: 'Specialty', column: null },
  ];

  static readonly MarketCapTierOptions: SubSectorOptionData[] = [
    { value: 'blend', label: 'Blend', column: null }, // 0
    { value: 'value', label: 'Value Tilt', column: null }, // 1
    { value: 'growth', label: 'Growth Tilt', column: null }, // 2
  ];

  // this group isn't in the tile selection as of yet. currently only used by ExposureChoicesComponent
  static readonly ValueBlendGrowthTierOptions: SubSectorOptionData[] = [
    { value: 'small_cap', label: 'Small Cap', column: null }, // 0
    { value: 'mid_cap', label: 'Mid Cap', column: null }, // 1
    { value: 'large_cap', label: 'Large Cap', column: null }, // 2
  ];

  static readonly CreditQualityTierOptions: SubSectorOptionData[] = [
    { value: 'short_term', label: 'Short Term', column: 1 }, // 0
    { value: 'mid_term', label: 'Intermediate Term', column: 1 }, // 1
    { value: 'long_term', label: 'Long Term', column: 1 }, // 2
    { value: 'other_term', label: 'Laddered', column: 1 }, // 3
  ];

  next_tier_sector_options: any[] = null;

  matchingFunds: any[];

  // variables related to user choices
  sectorSelections: any[] = [];
  selectionHeader: string = '';
  currentTier: number = 1;
  maxTier: number = 3;
  // fundTypeOptionsSelected: any;

  showColumnHeaders: boolean = true;
  showNextLevelOptions: boolean = true;
  showMainSectorOptions: boolean = true;

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  showErrorMessage: boolean = false;
  showResults: boolean = false;
  showNoResultsMessage: boolean = false;

  readonly spinnerSelector: string = 'screener-spinner';

  ngAfterViewInit(): void {
    setTimeout( () => {
      if ( this._state.globalVars && this._state.globalVars.aggUserToken ) {
        this.initializeVariables();
      } else {
        this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
          this.initializeVariables();
        }, 'screenerComponent' );
      }
      if ( environment.env !== 'prod' ) {
        window[`ripsaw_${ this.mainSector }_screener`] = this;
      }
      this.initComplete.emit( this );
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, 'screenerComponent' );
    this.onDestroy.next();
  }

  constructor( private _state: GlobalState,
               private _cd: ChangeDetectorRef,
               private _spinnerService: RipThemeLoadingSpinnerService,
               private _gdService: GlobalDataService ) {

  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    // this._cd.markForCheck();
    this._cd.reattach();
  }

  applyNewSettings() {
    if ( !this.queryHasNotBeenRun() ) {
      this.queryScreener();
    }
  }

  /**
   *
   * @param options [any] - if present, contains a risk dimension property (string) and a minPropAmount (number) that
   * should be used to filter out securities with less than that amount of the risk dimension
   */
  goForwardOneStep( options?: any ) {
    this.checkForMoreOptionsOrMatchingFunds( options );
    if ( this.currentTier < this.maxTier ) {
      this.currentTier++;
    }
    this.setSelectionHeader();
    this.showNextLevelOptions = this.shouldShowNextLevelOptions();
    this.showMainSectorOptions = this.shouldShowMainSectorOptions();
  }

  goBackOneStep() {
    // step backward to remove the last item set by the user
    // always clear selected fund
    // this.selection.clear();
    if ( this.matchingFunds ) {
      this.matchingFunds = null;
    }

    if ( this.sectorSelections.length > 0 ) {
      this.sectorSelections.pop(); // remove the last sector set
    }

    if ( this.currentTier > 1 ) {
      this.currentTier -= 1;
    } else {
      this.initializeVariables();
    }

    this.setSectorSelectionOptions( this.currentTier );
    this.setSelectionHeader();
    this.showNextLevelOptions = this.shouldShowNextLevelOptions();
    this.showMainSectorOptions = this.shouldShowMainSectorOptions();
    this.showResults = false;
    this.showNoResultsMessage = false;
    this.showErrorMessage = false;
  }

  setSectorSelectionByBenchmarkWeight( weight: BenchmarkComponent ) {
    if ( weight.label.toLowerCase().includes( 'non-us' ) ) {
      this.currentTier = 2;
      this.setSectorSelection( ScreenerComponent.StockTierOptions[5] );
    } else if ( weight.label.toLowerCase().includes( 'us total stock' ) ) {
      this.currentTier = 2;
      this.setSectorSelection( ScreenerComponent.StockTierOptions[1] );
    } else if ( weight.label.toLowerCase().includes( 'us investment grade' ) ) {
      this.currentTier = 2;
      this.setSectorSelection( ScreenerComponent.BondsTierOptions[1] );
    } else if ( weight.label.toLowerCase().includes( 'cash proxy' ) ) {
      // this.currentTier = 1;
      // this.setSectorSelection( { value: this.mainSector, label: Util.toTitleCase( this.mainSector ) } );
    }
    this.goForwardOneStep();

  }

  setSelectorSelectionByTierOptions( tier: number, tierOptions: any, options?: any ) {
    if ( tier <= this.maxTier ) {
      this.currentTier = tier;
    } else {
      this.currentTier = this.maxTier;
    }

    for ( const tierOption of tierOptions ) {
      this.setSectorSelection( tierOption );
    }
    this.goForwardOneStep( options );
  }

  setSelectionHeader() {
    if ( this.sectorSelections.length >= 1 ) {
      this.selectionHeader = `Risk Exposure: ${ this.sectorSelections[0].label }`;
      let i;
      for ( i = 1; i < this.sectorSelections.length; i++ ) {
        this.selectionHeader += ` -> ${ this.sectorSelections[i].label }`;
      }
      if ( this.filterOptions ) {
        const label = this._state.allColumnsObject[this.filterOptions.column].account.name;
        this.selectionHeader += ` -> (${ label } ${ this.filterOptions.lessOrMoreThan } ${ this.filterOptions.amount * 100 }%)`;
      }
    } else {
      this.selectionHeader = '';
    }
  }

  setSectorSelection( sector: any ) {
    if ( this.sectorSelections.length >= this.currentTier ) {
      // change the sector selection if previously set
      this.sectorSelections[this.currentTier - 1] = sector;
    } else {
      // no selection has been set previously, add it
      this.sectorSelections.push( sector );
    }
  }

  setSectorSelectionOptions( tier: number ) {
    // call service with correct inputs (from previously set tiers) to get options
    // for now hard code as place holders
    let tierOptions: SubSectorOptionData[] = null;
    if ( tier > 0 ) {
      const sectorSelection = this.getSectorSelection( tier - 1 );
      if ( !sectorSelection ) {
        return;
      }
      const previousTierSelection = sectorSelection.value;
      if ( previousTierSelection === 'stocks' ) {
        tierOptions = ScreenerComponent.StockTierOptions;
      } else if ( previousTierSelection === 'bonds' ) {
        tierOptions = ScreenerComponent.BondsTierOptions;
      } else if ( previousTierSelection === 'other' ) {
        tierOptions = ScreenerComponent.OtherTierOptions;
      } else if ( previousTierSelection === 'us_large_cap' || previousTierSelection === 'us_mid_cap' || previousTierSelection === 'us_small_cap' ) {
        tierOptions = ScreenerComponent.MarketCapTierOptions;
      } else if ( previousTierSelection === 'us_treasury_aaa' || previousTierSelection === 'us_corporate_ig' || previousTierSelection === 'us_municipal_ig' ) {
        tierOptions = ScreenerComponent.CreditQualityTierOptions;
      }
    }
    if ( tierOptions && tierOptions.length > 0 ) {
      this.next_tier_sector_options = this.splitSectorOptionsByColumn( tierOptions );
      this.showColumnHeaders = !( this.next_tier_sector_options &&
        this.next_tier_sector_options[0] &&
        this.next_tier_sector_options[0].leftSideChoices &&
        this.next_tier_sector_options[0].leftSideChoices.value &&
        this.next_tier_sector_options[0].leftSideChoices.value.includes( 'term' ) );
    } else {
      this.next_tier_sector_options = null;
      this.showColumnHeaders = true;
    }
  }

  getNextTierSectorList_byCol( tierOptions: SubSectorOptionData[], col ): SubSectorOptionData[] {
    return _.filter( tierOptions, ( o: SubSectorOptionData ) => {
      return o.column === col;
    } );
  }

  splitSectorOptionsByColumn( tierOptions: SubSectorOptionData[] ): any[] {
    let sectorOptionsSplitByCol: any[] = null;
    if ( tierOptions && tierOptions.length > 1 ) {
      sectorOptionsSplitByCol = [];
      const leftHandOptions: SubSectorOptionData[] = this.getNextTierSectorList_byCol( tierOptions, 1 );
      const centerOptions: SubSectorOptionData[] = this.getNextTierSectorList_byCol( tierOptions, 2 );
      const rightHandOptions: SubSectorOptionData[] = this.getNextTierSectorList_byCol( tierOptions, 3 );
      const noSpecifiedColumnOptions: SubSectorOptionData[] = this.getNextTierSectorList_byCol( tierOptions, null );

      const maxLength = Math.max( leftHandOptions.length, rightHandOptions.length, centerOptions.length, noSpecifiedColumnOptions.length );
      let i;
      for ( i = 0; i < maxLength; i++ ) {
        sectorOptionsSplitByCol.push(
          { leftSideChoices: null, centerChoices: null, rightSideChoices: null, anyColumnChoices: null } );

        if ( leftHandOptions.length > i ) {
          sectorOptionsSplitByCol[i].leftSideChoices = leftHandOptions[i];
        }
        if ( centerOptions.length > i ) {
          sectorOptionsSplitByCol[i].centerChoices = centerOptions[i];
        }
        if ( rightHandOptions.length > i ) {
          sectorOptionsSplitByCol[i].rightSideChoices = rightHandOptions[i];
        }
        if ( noSpecifiedColumnOptions.length > i ) {
          sectorOptionsSplitByCol[i].anyColumnChoices = noSpecifiedColumnOptions[i];
        }
      }
      return sectorOptionsSplitByCol;
    }

  }

  shouldShowNextLevelOptions(): boolean {
    return !!( this.getSectorSelection( 1 ) && this.fundTypeOptionsSelected && this.next_tier_sector_options );
  }

  shouldShowMainSectorOptions(): boolean {
    return ( !this.next_tier_sector_options || !this.fundTypeOptionsSelected );
  }

  queryHasNotBeenRun(): boolean {
    return !( this.showResults || this.showErrorMessage || this.showNoResultsMessage );
  }

  queryHasBeenRun(): boolean {
    return this.showResults || this.showErrorMessage || this.showNoResultsMessage;
  }

  getSectorSelection( tier: number ) {
    if ( tier === 0 ) {
      this.initializeVariables();
      return;
    }
    if ( this.sectorSelections ) {
      if ( this.sectorSelections.length >= tier && tier > 0 ) {
        return this.sectorSelections[tier - 1] ? this.sectorSelections[tier - 1] : this.sectorSelections[0];
      } else if ( this.sectorSelections && this.sectorSelections.length === 0 ) {
        this.setSectorSelection( { value: this.mainSector, label: Util.toTitleCase( this.mainSector ) } );
        return this.getSectorSelection( 1 );
      }
    } else {
      return null;
    }
  }

  startOver() {
    this.initializeVariables();
    this.setSelectionHeader();
  }

  initializeVariables() {
    this.showResults = false;
    this.showNoResultsMessage = false;
    this.showErrorMessage = false;
    this.sectorSelections = [];
    this.setSectorSelection( { value: this.mainSector, label: Util.toTitleCase( this.mainSector ) } );

    this.matchingFunds = null;
    this.next_tier_sector_options = null;
    this.currentTier = 1;
    this.setSectorSelectionOptions( this.currentTier + 1 );
    this.checkForMoreOptionsOrMatchingFunds();
    this.showNextLevelOptions = this.shouldShowNextLevelOptions();
    this.showMainSectorOptions = this.shouldShowMainSectorOptions();
  }

  emitSelectedSecurities( selectedSecuritiesList ) {
    this.securitiesSelected.emit( selectedSecuritiesList );
  }

  /**
   *
   * @param options [any] - if present, contains a risk dimension property (string) and a minPropAmount (number) that
   * should be used to filter out securities with less than that amount of the risk dimension
   */
  checkForMoreOptionsOrMatchingFunds( options?: any ) {
    // if the main sector was set and no sub- sector options are required, retrieve the matching funds
    this.setSectorSelectionOptions( this.currentTier + 1 );
    if ( this.getSectorSelection( 1 ) && !this.next_tier_sector_options ) {
      this.queryScreener( options );
    }
  }

  /**
   *
   * @param options [any] - if present, contains a risk dimension property (string) and a minPropAmount (number) that
   * should be used to filter out securities with less than that amount of the risk dimension
   */
  queryScreener( options?: any ) {
    // this.queryScreenerStatus = 'running'; // indicate that the query is running
    this.showNoResultsMessage = false;
    this.showResults = false;
    this.showErrorMessage = false;
    this._spinnerService.show( this.spinnerSelector );

    const lvl1 = this.mainSector;
    let lvl2 = '';
    let lvl3 = '';

    if ( this.mainSector !== 'money_market' ) {
      if ( this.mainSector === this.sectorSelections[0].value ) {
        this.sectorSelections.shift();
      }
      lvl2 = this.getSectorSelection( 1 ) ? this.getSectorSelection( 1 ).value : '';
      lvl3 = this.getSectorSelection( 2 ) ? this.getSectorSelection( 2 ).value : '';
    }

    const queryParams: any = {
      lvl1,
      lvl2,
      lvl3,
    };
    if ( this.forBenchmarkProxy ) {
      queryParams.forBenchmarkProxy = true;
    }

    queryParams.fundFamily = this.fundFamiliesSelected ? this.fundFamiliesSelected : [ '' ];
    if ( this.fundFamiliesSelected.length > 0 && this.fundFamiliesSelected[0].includes( 'Include' ) ) {
      queryParams.fundFamily = '';
    }

    if ( this.fundTypeOptionsSelected ) {
      queryParams.includeIndexFunds = this.fundTypeOptionsSelected.index || 0;
      queryParams.includeActiveFunds = this.fundTypeOptionsSelected.active || 0;
      queryParams.includeEtfs = this.fundTypeOptionsSelected.etf || 0;
      queryParams.includeMutualFunds = this.fundTypeOptionsSelected.mutual_fund || 0;
      queryParams.includeClosedEndFunds = this.fundTypeOptionsSelected.closed_end_fund || 0;
      queryParams.maxExpenseRatio = this.fundTypeOptionsSelected.maxExpenseRatio || 0;
    } else {
      queryParams.includeIndexFunds = 0;
      queryParams.includeActiveFunds = 0;
      queryParams.includeEtfs = 0;
      queryParams.includeMutualFunds = 0;
      queryParams.includeClosedEndFunds = 0;
      queryParams.maxExpenseRatio = 0;
    }

    this._gdService.queryScreener( queryParams )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( response: any ) => {
        if ( response ) {
          this.matchingFunds = [];
          if ( response.data && response.data.length > 0 ) {
            response.data.forEach( ( r: any ) => {
              this.matchingFunds.push(
                {
                  ticker: r.ticker,
                  description: r.name,
                  fund_family: r.fund_family,
                  security_type: r.fund_type,
                  expense_ratio: r.expense_ratio,
                } );
            } );

          }
          // this.queryScreenerStatus = 'finished';
          if ( this.matchingFunds && this.matchingFunds.length > 0 ) {
            this.showResults = true;
          } else {
            this.showNoResultsMessage = true;
          }
          this.doChanges();
          setTimeout( () => {
            if ( this.matchingFundDataSource ) {
              if ( this.mainSector === 'stocks' ) {
                const stocksDetails = this._state.defaultColumnSets.find( ( set: ColumnSet ) => {
                  return set.label === 'Stock Details';
                } );
                this.matchingFundDataSource.selectColumnSet( stocksDetails );
              }
              if ( this.mainSector === 'bonds' ) {
                const bondsDetails = this._state.defaultColumnSets.find( ( set: ColumnSet ) => {
                  return set.label === 'Bond Details';
                } );
                this.matchingFundDataSource.selectColumnSet( bondsDetails );
              }
            }
            this._spinnerService.hide( 0, this.spinnerSelector );
          } );
        } else {
          this._spinnerService.hide( 0, this.spinnerSelector );
        }
      }, ( err ) => {
        // this.queryScreenerStatus = 'failed';
        this.showErrorMessage = true;
        Logger.log( err.err );
        this.doChanges();
        this._spinnerService.hide( 0, this.spinnerSelector );
      } );
  }

}
