import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Account, AllocCalculator, BenchmarkUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import {
  Benchmark,
  BenchmarkExpectedReturnSet,
  EfficientFrontierPoint,
  ExpectedWealthBucket,
  ExpectedWealthIssue,
  ExpectedWealthIssueInstruction,
  ExpectedWealthZoomRange,
  GoalWithdrawal,
  OnboardingData,
  RiskLevel,
  RiskReturn,
  ScheduledGoalWithdrawal,
  StrategicAllocation,
  UserGoal,
} from './dataInterfaces';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../theme/pipes';
import { AllocationWidgetComponent } from '../reusableWidgets/allocationWidget';
import { BenchmarkHelpers } from '../pages/modals/benchmarkEditor/benchmarkHelpers';
import { Util } from './util.service';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { isInteger } from 'lodash-es';
import { GlobalState } from '../global.state';
import { AccountManager } from './accountManager';
import { BenchmarkService } from '../pages/modals/benchmarkEditor/benchmark.service';
import { GlobalDataService, MarketDataService } from '../globalData';
import { Auth } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { StatsUtil } from './stats.util';
import { MarketInfoUtil } from './market-info.util';
import { ChartColorUtil } from './chart-color.util';
import { BENCHMARK_PROXY_IDENTIFIERS, EVENT_NAMES, EXPECTED_VALUE_SCALES, MarketCalcTerms, USER_GOAL_TYPE_IDS } from './enums';
import { BenchmarkOptimizerService } from '../reusableWidgets/benchmark-setup-layout/benchmark-optimizer.service';
import { environment } from '../../environments/environment';
import { GoalsState } from './goals.state';
import { ChartDataset } from 'chart.js';
import { Logger } from './logger.service';
import { GoalsUtil } from './goals.util';
import { Moment } from 'moment/moment';
import { AppStoreService } from '../store';
import { WorkspaceLoadedStore } from '../store/workspace';
import { ErrorStateMatcher } from '@angular/material/core';

export class BenchmarkErrorStateMatcher implements ErrorStateMatcher {
  isErrorState( control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
    const isSubmitted = form && form.submitted;
    return !!( control && control.invalid && ( control.dirty || control.touched || isSubmitted ) );
  }
}

@Injectable()
export class BenchmarkState implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  /*
   * Validates that the input for the benchmark field is valid, meaning it is a number and not NaN
   * */
  benchmarkValidator(): ValidatorFn {
    return ( c: AbstractControl ) => {
      let isValid = true;
      if ( c.value ) {
        if ( typeof c.value === 'string' ) {
          const sanitizedInput = c.value.replace( /[^\d.]/g, '' );
          const input = parseFloat( sanitizedInput );
          if ( isNaN( input ) ) {
            isValid = false;
          }
        }
      }
      if ( isValid ) {
        return null;
      } else {
        return {
          benchmarkValidator: {
            valid: false,
          },
        };
      }
    };
  }


  readonly frontierTicks = 100;

  benchmarkMatcher = new BenchmarkErrorStateMatcher();

  benchmark: Benchmark = BenchmarkUtil.defaultBenchmark();
  initialUserBenchmark: Benchmark;
  benchmarkLastState: any = this.benchmark;

  title: string = 'Choose Benchmark Portfolio Proxies';
  benchmarkFormLoading: boolean = true;

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  form: UntypedFormGroup = new UntypedFormGroup( {} );

  defaultBenchmarks: any = [ ...BenchmarkUtil.defaultBenchmarks() ];

  benchmarkTickers: string[] = [];

  allocWidget: AllocationWidgetComponent;

  calculatedBenchmarkData: any;
  helpers: typeof BenchmarkHelpers;
  maxSecurityCheckTries: number = 5;

  loading: boolean = false;

  dialogIsOpen: boolean = false;
  userBenchmarkLoaded: boolean = false;

  riskPotentialDefaultProbability: number = 0.05;

  selectedBucket: ExpectedWealthBucket;

  expectedWealthBuckets: ExpectedWealthBucket[] = [];
  expectedWealthMin: number = 0;
  expectedWealthMax: number = 0;
  expectedWealthColor: string = '#01B894';
  upsidePotentialColor: string = '#0090D4';
  downsideRiskColor: string = '#FF006E';

  riskReturns: Map<string, RiskReturn> = new Map<string, RiskReturn>();
  riskReturnsValues: RiskReturn[] = [];
  riskReturnError: boolean = false;
  riskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };
  shortTermRiskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };
  longTermRiskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };

  benchmarkStockProxies: any = {};
  benchmarkBondProxies: any = {};
  allProxies: any = {};
  cashProxy: any = {};
  cashBondStockWeightMap: any = {};

  benchmarkRiskExposureList: any[] = [];
  benchmarkAssetAllocationList: any[] = [];
  portfolioRiskExposureList: any[] = [];

  currentShortTermFrontier: EfficientFrontierPoint[];
  currentLongTermFrontier: EfficientFrontierPoint[];
  selectedFrontierPoint: EfficientFrontierPoint;
  selectedFrontierIndex: number;
  selectedDeviation: number;
  selectedRiskLevel: number;

  localSecuritiesCache: any = {};

  term: MarketCalcTerms = MarketCalcTerms.long;

  benchmarkSecuritiesRetrieved: boolean = false;
  longTermFrontierHasBeenProcessed: boolean = false;
  shortTermFrontierHasBeenProcessed: boolean = false;

  bmPortfolio: any;
  expectedWealthScale: EXPECTED_VALUE_SCALES = EXPECTED_VALUE_SCALES.age;
  expectedWealthRange: ExpectedWealthZoomRange = {
    min: undefined,
    max: undefined,
    currentLow: undefined,
    currentHigh: undefined,
    length: 0,
  };
  investorsCurrentAge: number;
  loadingExpectedWealthData: boolean = true;
  refreshingEfficientFrontier: boolean = false;

  doingOnboarding: boolean = false;
  onboardingData: OnboardingData;

  frontierError: boolean = false;

  scaleSubject: Subject<any> = new Subject<any>();

  hideWealthChart: boolean = false;

  startingPortfolioSize: number;
  currentWorkspaceLoadedStore: WorkspaceLoadedStore;

  subscriberName: string = 'benchmarkState';

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _benchmarkService: BenchmarkService,
               private _gdService: GlobalDataService,
               private _auth: Auth,
               private snackBar: MatSnackBar,
               public dialog: MatDialog,
               private _mdService: MarketDataService,
               private _benchmarkOptimizerService: BenchmarkOptimizerService,
               public goalsState: GoalsState,
               private appStoreService: AppStoreService,
  ) {

    this.getBenchmarkByLoadedWorkspace();

    /*    _state.subscribe( EVENT_NAMES.PRICES_UPDATED, () => {
     this.loadBenchmark( this.initialUserBenchmark );
     }, this.subscriberName );*/

    _state.subscribe( [
      EVENT_NAMES.PRICES_UPDATED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.MANUAL_ACCOUNT_CREATED,
      EVENT_NAMES.MANUAL_ACCOUNT_UPDATED,
      EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
      EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED,
    ].join( ' | ' ), () => {
      if ( this.userBenchmarkLoaded ) {
        // don't want to do this before the user's benchmark has been returned, because it will use the default and
        // mess everything up
        this.loadBenchmark( this.benchmark );
      }
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.resetBenchmarkData( true );
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.LOGGED_IN, () => {
      console.log( 'logged in' );
      // this should only happen on subsequent logins from the same instance of the loaded app. Should not hit here when
      // the class instance is loaded the first time, because the subscription happens after the event is fired on first
      // login of a new instance
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_benchmarkState' ] = this;
    }
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.PRICES_UPDATED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.MANUAL_ACCOUNT_CREATED,
      EVENT_NAMES.MANUAL_ACCOUNT_UPDATED,
      EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
      EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED,
      EVENT_NAMES.LOGOUT,
      EVENT_NAMES.LOGGED_IN,
    ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  /*
   * Function to open the modal
   * */
  benchmarkEditorOpened() {
    this.benchmarkFormLoading = true;
    this.allocWidget = this._state.globalVars.allocWidget;
    this.setupBenchmarkCompositionFormControls();
    this.benchmarkFormLoading = false;
    this.benchmarkLastState = Util.clone( this.benchmark );
  }

  setUserBenchmark( benchmark: Benchmark ) {
    this.loadBenchmark( benchmark );
    this.userBenchmarkLoaded = true;
    this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_LOADED );
  }

  /*
   * Function to retrieve user's benchmarks from the benchmark service
   * */
  getUserBenchmark() {
    if ( this._auth.authenticated() && !this._state.globalVars.inWealthFluent ) {
      this._benchmarkService.getBenchmark()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( response: any ) => {
            // console.log( `revisions: ${response.data}` );
            if ( response?.data?.id ) {
              this.benchmark = response.data;
            }
            // if there is no benchmark for this user (maybe first load not through onboarding), create one
            if ( !this.benchmark || !this.benchmark.id ) {
              // the user may be doing onboarding or working on their benchmark and we don't want them losing changes, so merge the current
              // properties onto the default
              const newBenchmark = Object.assign( {}, BenchmarkUtil.defaultBenchmark(), this.benchmark );
              delete newBenchmark.id;
              delete newBenchmark.workspace_id;
              this.createUserBenchmark( newBenchmark );
            } else {
              this._state.globalVars.benchmark = this.benchmark;
            }
            this.loadBenchmark( this.benchmark );
            this.userBenchmarkLoaded = true;
            this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_LOADED );
          }, error: ( err ) => {
            console.error( err );
          },
        } );
    } else {
      // this shouldn't happen but can
      if ( !this.doingOnboarding ) {
        setTimeout( () => {
          this.getUserBenchmark();
        }, 1000 );
      }
    }
  }

  /*
   * Function for creating the user benchmark on first load
   * */
  createUserBenchmark( benchmark: any ) {
    this.benchmark = benchmark;
    this._state.globalVars.benchmark = this.benchmark;
    delete this.benchmark.retired; // this is inferred upon shiftToWithdrawals being undefined
    this._benchmarkService.createBenchmark( benchmark )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          if ( response.data ) {
            this.benchmark = response.data;
            this._state.globalVars.benchmark = this.benchmark;
            this.benchmarkLastState = Util.clone( this.benchmark );
          }
          // this.loadBenchmark( this.benchmark ); // shouldn't need to load it here because it should have already been the one being used
          this.saveButtonOptions.active = false;
        },
        error: ( error ) => {
          console.error( error );
          this.saveButtonOptions.active = false;
        },
      } );
  }

  saveButtonOptions: any = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    disabled: !this.form.valid || !this.checkAllGroupTotals(),
  };

  /*
   * Function for saving the user benchmark. If the benchmark name exists, it will overwrite it, otherwise it will save
   * a new benchmark
   * */
  saveBenchmark( silentSnackBar?: boolean ) {
    this.saveButtonOptions.active = true;

    if ( !this.benchmark.id ) {
      this.createUserBenchmark( this.benchmark );
      return;
    }
    delete this.benchmark.retired; // this is inferred upon shiftToWithdrawals being undefined
    delete this.benchmark.updated_at;
    this._benchmarkService.updateBenchmark( this.benchmark )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          this.benchmark = response.data;
          this.benchmarkLastState = Util.clone( this.benchmark );
          if ( !silentSnackBar ) {
            this.snackBar.open( `Wealth Plan Saved Successfully`, null, Util.getSnackBarOptions() );
          }
          this.saveButtonOptions.active = false;
          this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_SAVED );
        },
        error: ( error ) => {
          console.error( error );
          this.snackBar.open( `Error Saving: Your benchmark could not be saved at this time, please try again soon`, null, Util.getSnackBarOptions() );
          this.saveButtonOptions.active = false;
        },
      } );

  }

  /*
   * Function to set the benchmark her and globally and then emit it to the allocation widget, so it will aggregate and
   * set the benchmark there
   * @param benchmark {Object} - benchmark to be loaded to the form and the allocation widget
   * */
  loadBenchmark( benchmark: Benchmark, fromCompositionEditor?: boolean ) {
    if ( benchmark ) {
      this.investorsCurrentAge = moment().diff( moment( benchmark.birth_date ), 'years', true );

      const tickers = this.checkProxyDataRetrieved( benchmark );
      // this.benchmarkFormLoading = true;
      if ( tickers.length === 0 ) {
        this.loadHelper( benchmark, fromCompositionEditor );
      } else {
        this.checkSecurityList( null, 0 )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: () => {
              this.loadHelper( benchmark, fromCompositionEditor );
            }, error: ( err ) => {
              console.error( err.err );
              this.loadHelper( benchmark, fromCompositionEditor );
            },
          } );
      }
    } else {
      // should probably tell the user something here
    }
  }

  loadHelper( benchmark: Benchmark, fromCompositionEditor?: boolean ) {
    this.investmentTotalCached = undefined; // reset so the next call caches it
    this.getInvestmentTotal();
    if ( fromCompositionEditor ) {
      // only want to reset benchmarkData, not all of the benchmark, like contributions, withdrawals, etc
      this.benchmark.benchmarkData = _.cloneDeep( benchmark.benchmarkData );
    } else {
      // in this case, we want all the data, because it is either a new user and there is no user entered data, or it
      // is the initial load from getUserBenchmark
      this.benchmark = _.cloneDeep( benchmark );
      this.benchmark.retired = BenchmarkUtil.isInvestorRetired( this.benchmark );
    }
    this._state.globalVars.benchmark = this.benchmark;
    this.initialUserBenchmark = _.cloneDeep( this.benchmark );
    this.setupBenchmarkCompositionFormControls();
    this.allocWidget?.setBenchmark();
    this.benchmarkFormLoading = false;
    this.getBenchmarkSetupData();
    this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_LOADED );
  }

  expectedWealthScaleChange( evs: EXPECTED_VALUE_SCALES ) {
    if ( evs ) {
      this.expectedWealthScale = evs;
      delete this.expectedWealthRange.currentLow;
      delete this.expectedWealthRange.currentHigh;
      if ( this.selectedFrontierIndex ) { // don't need to do this before the index is selected the first time
        this.selectFrontierPoint( this.selectedFrontierIndex );
      }
    }
  }

  frontiersAreDefined() {
    return this.currentLongTermFrontier && this.currentShortTermFrontier &&
      this.currentLongTermFrontier.length > 0 && this.currentShortTermFrontier.length > 0;
  }

  setupExpectedWealthBuckets() {
    const user = this.getUserData();

    if ( !this.frontiersAreDefined() ) {
      // don't want to let inner functions throw errors because the frontiers are defined yet
      return;
    }

    // only need to update these when investor info emits a change
    // loop through the BenchmarkUtil.mainRiskLevels and use their riskNumbers to get a frontier index and
    this.expectedWealthBuckets = [];
    this.expectedWealthMin = 0;
    this.expectedWealthMax = 0;
    for ( const riskLevel of BenchmarkUtil.mainRiskLevels ) {
      const frontierIndex = this.getFrontierIndexFromRiskLevel( riskLevel.riskNumber );
      const data = this.setupExpectedWealthDataForAFrontierPoint( frontierIndex );
      const chartData = data?.chartDataSets;
      if ( data.stats.yMin < this.expectedWealthMin ) {
        this.expectedWealthMin = data.stats.yMin;
      }
      if ( data.stats.yMax > this.expectedWealthMax ) {
        this.expectedWealthMax = data.stats.yMax;
      }

      const withdrawalsToLiveOffUsersRetirement = this.calcWithdrawalsToLiveOff( user?.retired, data?.stats );
      const totalRetirementGoalsWithdrawals = this.goalsState.getTotalWithdrawalsFromRetirementGoals();

      const withdrawalsToLiveOff = withdrawalsToLiveOffUsersRetirement - totalRetirementGoalsWithdrawals;

      const bucket: ExpectedWealthBucket = {
        riskLevel,
        chartData,
        withdrawalsToLiveOff,
        stats: data.stats,
      };

      bucket.issues = this.getBucketIssues( bucket );

      this.expectedWealthBuckets.push( bucket );

    }
    this.setWealthChartZoomRange();
    // console.log( 'max of all charts is:', this.expectedWealthMax );
    // console.log( 'min of all charts is:', this.expectedWealthMin );
    this._state.notifyDataChanged( EVENT_NAMES.EXPECTED_WEALTH_BUCKETS_RECALCULATED );
  }

  setupExpectedWealthDataForSelectedFrontierPoint( event?: any ) {

    if ( !this.frontiersAreDefined() ) {
      // don't want to let inner functions throw errors because the frontiers are defined yet
      return;
    }

    const user = this.getUserData();

    this.loadingExpectedWealthData = true;

    const data = this.setupExpectedWealthDataForAFrontierPoint( this.selectedFrontierIndex );

    // console.log( chartDataSets );

    const solutionRiskLevel: number = this.getSolutionRiskLevel( this.selectedFrontierPoint );

    const withdrawalsToLiveOffUsersRetirement = this.calcWithdrawalsToLiveOff( user?.retired, data?.stats );
    const totalRetirementGoalsWithdrawals = this.goalsState.getTotalWithdrawalsFromRetirementGoals();

    const withdrawalsToLiveOff = withdrawalsToLiveOffUsersRetirement - totalRetirementGoalsWithdrawals;

    this.selectedBucket = {
      chartData: data?.chartDataSets,
      withdrawalsToLiveOff,
      totalRetirementGoalsWithdrawals,
      riskLevel: {
        riskLabel: this.getBenchmarkRiskLabel( solutionRiskLevel ),
        icon: BenchmarkUtil.myPlanIcon,
        riskNumber: solutionRiskLevel,
        label: 'My Wealth Plan',
      },
      stats: data?.stats,
    };

    this.goalsState.expectedReturnForFVCalc = data?.stats?.totalLTExpectedReturn;

    // this.benchmarkIssues = this.getBucketIssues( this.selectedBucket );
    this.selectedBucket.issues = this.getBucketIssues( this.selectedBucket );

    this._state.notifyDataChanged( EVENT_NAMES.SELECTED_EXPECTED_VALUE_RECALCULATED );

    this.loadingExpectedWealthData = false;
  }

  calcWithdrawalsToLiveOff( retired: boolean, stats: any ) {

    if ( retired ) {
      // for retired users, the base is startingPortfolioSize, because they are already withdrawing
      return stats?.totalLTExpectedReturn * stats?.firstYear?.expectedWealth; // stats?.startingPortfolioSize;
    } else {
      if ( ( stats?.yearsTillRetirement && stats?.yearsTillRetirement < 1 ) ) {
        // for users who are retiring in less than one year, the base should be the first year
        return stats?.totalLTExpectedReturn * stats?.firstYear?.expectedWealth;
      } else {
        // retirement is more than a year off, so the base should be at retirement, the inflectionPoint
        return stats?.totalLTExpectedReturn * stats?.inflectionPoint?.expectedWealth;
      }
    }

  }

  jointDecisionInstruction: ExpectedWealthIssueInstruction = {
    number: '5',
    text: `<u>Remember:</u> to achieve your lifetime financial goals, it is your preferred <b><i>joint</i></b> decision of an expected wealth versus risk tradeoff <b><i>and</i></b> the components of your profile:`,
    instructions: [
      {
        number: 'A',
        text: `Portfolio size`,
      },
      {
        number: 'B',
        text: `Retirement date`,
      },
      {
        number: 'C',
        text: `Length of retirement`,
      },
      {
        number: 'D',
        text: `Net annual withdrawals (retirement income)`,
      },
      {
        number: 'E',
        text: `Annual contributions/savings`,
      },
    ],
    useStyledList: true,
  };

  notInstruction: ExpectedWealthIssueInstruction = {
    number: '5',
    text: `<u>Note</u> that preferences in the risk dimension are most influenced by your choice of the probability of downside risk outcomes (degree of risk aversion) and choice of the expected wealth versus risk tradeoff currently available in the cash, bond, and stock markets. The other profile decisions mainly affect your level of expected wealth over time. Given a level of expected wealth at any time in the future, risk is defined as the probability distribution of deviations from that expected wealth at that time.`,
  };

  getBucketIssues( bucket: ExpectedWealthBucket ): ExpectedWealthIssue[] {
    const issues: ExpectedWealthIssue[] = [];
    const retired = this.getUserData().retired;


    if ( bucket?.stats?.portfolioEnd.downsideRisk === 0 ) {
      issues.push( {
        type: 'Risk of Ruin in Downside Risk',
        issueClass: 'analysis-issue-warning',
        instructions: [
          {
            number: '1',
            text: `For the lowest ${ this.ripPercentPipe.transform( this.benchmark.probability ?? this.riskPotentialDefaultProbability, '0-0' ) } of outcomes, you are expected to run out of money ${ this.formatYearAtZero( bucket.stats.totalLengthOfPortfolioInYears, bucket.stats.yearAtZero?.downsideRisk ) } the end of retirement.`,
          },
          {
            number: '2',
            text: `Risk aversion is different for everyone. First determine if the default 5% probability for downside risk is a good representation of your risk aversion.If not, consider a change to your preferred level (edit probability to the left).`,
            instructions: [
              {
                number: 'a',
                text: `If you feel <u>less risk averse</u> (more risk tolerant) then the default 5% level, raising the probability to 10 or more percent for downside risk will include more outcomes that may still be above zero through the portfolio end date.`,
              },
              {
                number: 'b',
                text: `If you feel <u>more risk averse</u> (less risk tolerant) than the 5% level, you can lower the probability to 1% downside risk and may need to reduce risk by moving left on the above risk vs. expected wealth slider.`,
              },
            ],
          },
          {
            number: '3',
            text: `If the result is still running out of money before the end of retirement and that is uncomfortable, move slightly left on the above risk vs. expected wealth slider until you reach a suitable plan.`,
          },
          Object.assign( {}, this.jointDecisionInstruction, { number: '4' } ),
          Object.assign( {}, this.notInstruction, { number: '5' } ),
        ],
      } );
    }
    if ( bucket?.stats?.portfolioEnd.expectedWealth === 0 ) {
      issues.push( {
        type: 'Risk of Ruin in Expected Wealth',
        issueClass: 'analysis-issue-danger',
        instructions: [
          {
            number: '1',
            text: `You are expected to run out of money ${ this.formatYearAtZero( bucket.stats.totalLengthOfPortfolioInYears, bucket.stats.yearAtZero?.expectedWealth ) } the end of retirement.`,
          },
          {
            number: '2',
            text: `You can decrease this risk of ruin by making changes in your profile; decreasing annual withdrawals in retirement${ !retired ? ', increasing annual contributions, delaying your retirement' : '' } and reducing the length of retirement will all contribute to having more expected wealth at the end of retirement`,
          },
          {
            number: '3',
            text: `Review the expected wealth versus risk tradeoffs for a preferred joint decision to achieve your lifetime financial goals`,
          },
          {
            number: '4',
            text: `Consider changing your expected wealth versus risk tradeoff as well.`,
          },
          Object.assign( {}, this.jointDecisionInstruction, { number: '5' } ),
          Object.assign( {}, this.notInstruction, { number: '6' } ),
        ],
      } );
    }

    return issues;

  }

  formatYearAtZero( portfolioLengthInYears: number, yearAtZero: number ) {
    const diff = portfolioLengthInYears - yearAtZero;
    if ( yearAtZero === undefined || diff < 1 ) {
      return 'within a year of';
    } else {
      return `${ this.ripDecimalPipe.transform( diff, '0-2' ) } years before`;
    }
  }


  getFrontierPoint( frontierIndex: number, frontier: EfficientFrontierPoint[] ) {
    let indexToUse = frontierIndex;
    if ( !frontierIndex || frontierIndex < 0 || frontierIndex > frontier?.length ) {
      // console.error( ' trying to access frontier point outside of array' );
      Logger.info( ' resetting index to last element in the array' );
      indexToUse = frontier ? frontier.length - 1 : 0;
    }
    if ( frontier?.length > 0 ) {
      const fp = frontier[ indexToUse ];

      return fp ? fp : this.getFrontierPoint( frontierIndex - 1, frontier );
    } else {
      return null;
    }

  }

  setupExpectedWealthDataForAFrontierPoint( frontierIndex: number ) {

    // gather required information
    this.startingPortfolioSize = this.benchmark?.startingPortfolioSize ??
      this.investmentTotalCached ??
      this.getInvestmentTotal() ??
      BenchmarkUtil.defaultStartingPortfolioSize;

    const totalAnnualContributions: number = ( this.benchmark.annualContributions ?? 0 ) + ( this.benchmark.annualSavings || 0 );
    const shiftToWithdrawals: Moment = this.benchmark.shiftToWithdrawals ? moment( this.benchmark.shiftToWithdrawals ) : undefined;
    const annualWithdrawals: number = this.benchmark.annualWithdrawals;
    const lengthOfWithdrawals: number = this.benchmark.lengthOfWithdrawals;

    let endOfInvestorRetirement: Moment;
    if ( this.benchmark.endOfPortfolioLife ) {
      endOfInvestorRetirement = moment( this.benchmark.endOfPortfolioLife );
    } else if ( shiftToWithdrawals ) {
      endOfInvestorRetirement = moment( shiftToWithdrawals ).add( lengthOfWithdrawals, 'years' );
    } else {
      // this means either the end of portfolio life date never got set and the shift to withdrawals is undefined, so they must be retired already
      endOfInvestorRetirement = moment().add( lengthOfWithdrawals, 'years' );
    }


    const monthsTillRetirement: number = ( !this.benchmark?.retired && shiftToWithdrawals ) ?
      Number( shiftToWithdrawals.diff( moment(), 'months', true ).toFixed( 2 ) )
      : 0;

    const monthsTillEndRetirement: number = Number( endOfInvestorRetirement.diff( moment(), 'months', true ).toFixed( 2 ) );

    const longTermFrontierPoint = this.getFrontierPoint( frontierIndex, this.currentLongTermFrontier );


    const longTermOptimalWeights = longTermFrontierPoint.optimal_weights;
    const shortTermPortfolio = BenchmarkUtil.getShortTermBenchmarkStatsFromWeights( longTermOptimalWeights, this.riskReturnsValues, this.cashBondStockWeightMap );

    const totalSTExpectedReturn: number = shortTermPortfolio.er;
    const STStandardDeviation: number = shortTermPortfolio.sd;

    const totalLTExpectedReturn: number = longTermFrontierPoint.minimization_stats.total_expected_return; // this should be from the long term
                                                                                                          // frontier
    const LTStandardDeviation: number = longTermFrontierPoint.minimization_stats.standard_deviation / 100; // this is on the wrong scale for some
                                                                                                           // dumb reason

    const startingX: number = this.determineScaleNumber( 0, moment() );

    // console.log( `starting portfolio: ${ startingPortfolioSize }` );

    let yearAtZero = undefined;

    let yMin = 0;
    let yMax = this.startingPortfolioSize;

    let wealthAtNthMonth = this.startingPortfolioSize;

    const goalWithdrawalInfo = this.getAllGoalWithdrawals();
    // these will be handled different from the goal withdrawals that happen during the main loop
    const retirementWithdrawals = goalWithdrawalInfo.retirementWithdrawals;
    const finalEndDate: Moment = goalWithdrawalInfo?.finalEndDate?.isAfter( endOfInvestorRetirement ) ? goalWithdrawalInfo.finalEndDate : endOfInvestorRetirement;

    // moved this down a bit, so we could look at the additional retirements as well
    const totalLengthOfPortfolioInYears: number = finalEndDate.diff( moment(), 'years', true ); // this is the total
    // these withdrawals will be handled during the main loop
    const goalWithdrawals = goalWithdrawalInfo.withdrawals;
    const LTDiscountFactor = 1 + ( totalLTExpectedReturn / 12 ); // $E$7
    const monthlyWithdrawals = annualWithdrawals / 12; // $D$13
    const pvOfWithdrawals = ( 1 - ( Math.pow( LTDiscountFactor, -monthsTillRetirement ) ) ) / ( LTDiscountFactor - 1 ); // $K$10
    const pvOfWithdrawalsAtRetirementDate = pvOfWithdrawals * monthlyWithdrawals; // $J$10

    const savingsAnnuityFactor = ( Math.pow( LTDiscountFactor, monthsTillRetirement ) - 1 ) / ( LTDiscountFactor - 1 ) * LTDiscountFactor; // $K$11
    const savingsAnnuityUntilRetirement = pvOfWithdrawalsAtRetirementDate / savingsAnnuityFactor;


    // set up the chart data structure each set with a starting point of startingPortfolioSize
    const chartDataSets: ChartDataset[] = [
      {
        data: [
          {
            x: startingX,
            y: this.startingPortfolioSize,
          },
        ],
        label: 'Expected Wealth',
        yAxisID: 'y',
        pointRadius: 0,
        pointHoverRadius: 6,
        // fill: '+1', // if you want to fill to the horizontal line
        fill: false,
        borderWidth: 6,
        tension: 0,
        backgroundColor: this.expectedWealthColor,
        borderColor: this.expectedWealthColor,
        pointBackgroundColor: this.expectedWealthColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#4a95cd',
        pointHoverBorderColor: '#4a95cd',
        pointHitRadius: 15,
      },
      {
        data: [
          {
            x: startingX,
            y: this.startingPortfolioSize,
          },
        ],
        label: 'Downside Risk',
        yAxisID: 'y',
        pointRadius: 0,
        pointHoverRadius: 6,
        // fill: '+1', // if you want to fill to the horizontal line
        fill: false,
        segment: {
          borderWidth: ( context: any ) => {
            return ( context.p0?.raw?.y === 0 && context.p1?.raw?.y === 0 ) ? 4 : 3;
          },
        },
        borderWidth: 3,
        tension: 0,
        backgroundColor: this.downsideRiskColor,
        borderColor: this.downsideRiskColor,
        pointBackgroundColor: this.downsideRiskColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#4a95cd',
        pointHoverBorderColor: '#4a95cd',
        pointHitRadius: 15,
      },
      {
        data: [
          {
            x: startingX,
            y: this.startingPortfolioSize,
          },
        ],
        label: 'Upside Potential',
        yAxisID: 'y',
        pointRadius: 0,
        pointHoverRadius: 6,
        // fill: '+1', // if you want to fill to the horizontal line
        fill: false,
        borderWidth: 3,
        tension: 0,
        backgroundColor: this.upsidePotentialColor,
        borderColor: this.upsidePotentialColor,
        pointBackgroundColor: this.upsidePotentialColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#4a95cd',
        pointHoverBorderColor: '#4a95cd',
        pointHitRadius: 15,
      },
    ];


    // set up the statistics that can be used later
    let stats: any = {
      startingX,
      inflectionPoint: {
        yearsIn: monthsTillRetirement / 12,
      },
      totalSTExpectedReturn,
      STStandardDeviation,
      totalLTExpectedReturn,
      LTStandardDeviation,
      totalLengthOfPortfolioInYears,
      yearsTillRetirement: monthsTillRetirement / 12,
      startingPortfolioSize: this.startingPortfolioSize,
      pvOfWithdrawalsAtRetirementDate,
      savingsAnnuityUntilRetirement,
    };


    // MAIN LOOP:  loop through the portfolio years (in 1 month increments, calculating the wealth for each month
    for ( let n = 1; n <= ( totalLengthOfPortfolioInYears * 12 ); n++ ) {

      const expectedReturn = n <= 12 ? totalSTExpectedReturn : totalLTExpectedReturn;
      const standardDeviation: number = n <= 12 ? STStandardDeviation : LTStandardDeviation;


      const goalCursorData = this.checkForGoalsThisIncrement(
        goalWithdrawals,
        retirementWithdrawals,
        n,
      );

      /*  if ( goalCursorData ) {
       console.log( goalCursorData );
       }*/

      const cursorData = this.calcAndSetOneMonthOfChartData(
        wealthAtNthMonth,
        expectedReturn,
        n,
        totalAnnualContributions,
        this.getAnnualContributionsForThisIncrement( [ ...goalWithdrawals, ...retirementWithdrawals ], n ),
        annualWithdrawals,
        this.getAnnualWithdrawalsForThisIncrement( retirementWithdrawals, n ),
        monthsTillRetirement,
        monthsTillEndRetirement,
        standardDeviation,
        stats,
        yMax,
        yMin,
        chartDataSets,
        yearAtZero,
        goalCursorData,
        goalWithdrawals,
        retirementWithdrawals );

      stats = cursorData.stats;
      wealthAtNthMonth = cursorData.nthMonthWealth;
      yMin = cursorData.yMin;
      yMax = cursorData.yMax;
      yearAtZero = cursorData.yearAtZero;

      if ( cursorData.needToDoRetirementNext ) {
        const retirementData = this.calcAndSetOneMonthOfChartData(
          wealthAtNthMonth,
          expectedReturn,
          monthsTillRetirement,
          totalAnnualContributions,
          this.getAnnualContributionsForThisIncrement( [ ...goalWithdrawals, ...retirementWithdrawals ], n ),
          annualWithdrawals,
          this.getAnnualWithdrawalsForThisIncrement( retirementWithdrawals, n ),
          monthsTillRetirement,
          monthsTillEndRetirement,
          standardDeviation,
          stats,
          yMax,
          yMin,
          chartDataSets,
          yearAtZero,
          goalCursorData,
          goalWithdrawals,
          retirementWithdrawals );

        stats = retirementData.stats;
        wealthAtNthMonth = retirementData.nthMonthWealth;
        yMin = retirementData.yMin;
        yMax = retirementData.yMax;
        yearAtZero = retirementData.yearAtZero;
      }

    }

    /*    chartDataSets.forEach( ( set ) => {
     set.data.sort( ( a: any, b: any ) => {
     return a.x <= b.x ? -1 : 1;
     } );
     } );*/

    stats.yMin = yMin;
    stats.yMax = yMax;
    stats.yearAtZero = yearAtZero;
    stats.withdrawals = goalWithdrawals;
    stats.retirementWithdrawals = retirementWithdrawals;
    // grab the last dollar number for the bucket "legend"s
    stats.portfolioEnd = {
      expectedWealth: chartDataSets[ 0 ].data[ chartDataSets[ 0 ].data.length - 1 ][ 'y' ],
      downsideRisk: chartDataSets[ 1 ].data[ chartDataSets[ 1 ].data.length - 1 ][ 'y' ],
      upsidePotential: chartDataSets[ 2 ].data[ chartDataSets[ 2 ].data.length - 1 ][ 'y' ],
      n: this.formatScaleNumber( chartDataSets[ 0 ].data[ chartDataSets[ 0 ].data.length - 1 ][ 'x' ] ),
      label: 'End',
      date: moment().add( totalLengthOfPortfolioInYears, 'years' ),
    };

    return { chartDataSets, stats };
  }

  setWealthChartZoomRange() {

    const dataLength = this.selectedBucket.chartData[ 0 ].data.length;
    const first = this.selectedBucket.chartData[ 0 ].data[ 0 ];
    const last = this.selectedBucket.chartData[ 0 ].data[ dataLength - 1 ];

    this.expectedWealthRange.min = first.x;
    this.expectedWealthRange.max = last.x;
    this.expectedWealthRange.length = dataLength;

    this.scaleSubject.next( this.expectedWealthRange );
  }

  getAnnualContributionsForThisIncrement( withdrawals: GoalWithdrawal[], n: number ) {
    let totalContributions: number = 0;
    for ( const w of withdrawals ) {
      if ( w.nValue > n ) {
        totalContributions += w.contributions ?? 0;
      }
    }
    return totalContributions;
  }

  getAnnualWithdrawalsForThisIncrement( retirementWithdrawals: GoalWithdrawal[], n: number ) {
    let totalWithdrawals: number = 0;
    for ( const w of retirementWithdrawals ) {
      if ( w.nValue < n && n < w.endNValue ) {
        totalWithdrawals += w.annualWithdrawals ?? 0;
      }
    }
    return totalWithdrawals;
  }

  checkForGoalsThisIncrement( withdrawals: GoalWithdrawal[],
                              retirementWithdrawals: GoalWithdrawal[],
                              n: number ): any {


    const goalInfo = {
      withdrawals: 0,
      indexes: [],
      retirementIndexes: [],
    };
    // console.log( 'n: ', n );
    for ( let i = 0; i < withdrawals.length; i++ ) { // loop through withdrawals in this year
      const w: GoalWithdrawal = withdrawals[ i ];
      const diff: number = Util.diffMomentByUTC( w.date, moment(), 'months', true );

      if ( diff > n && diff < n + 1 ) {
        goalInfo.withdrawals += w.amount;
        goalInfo.indexes.push( i );
      }
    }

    for ( let i = 0; i < retirementWithdrawals.length; i++ ) { // loop through withdrawals in this year
      const w: GoalWithdrawal = retirementWithdrawals[ i ];
      const diff: number = Util.diffMomentByUTC( w.date, moment(), 'months', true );

      if ( diff > n && diff < n + 1 ) {
        // goalTotals.withdrawals += w.amount;
        goalInfo.retirementIndexes.push( i );
      }
    }

    return goalInfo;
  }

  getAllGoalWithdrawals(): any {
    // set up the different withdrawal groups
    const withdrawals: GoalWithdrawal[] = [];
    const retirementWithdrawals: GoalWithdrawal[] = [];
    let finalEndDate: Moment; // to keep track of the final retirement end date

    for ( const goal of this.goalsState?.goals ?? [] ) {
      // treat certain goals differently
      if ( goal.type === USER_GOAL_TYPE_IDS.emergency_fund || !goal.include ) {
        // do nothing for now, these don't have dates, so we can't show them on the graph, but we do use
        // the total of these as the minimum cash reserve in the frontier calcs, so maybe we want to show it somehow later
      } else if ( goal.type === USER_GOAL_TYPE_IDS.retirement ) {
        const m: Moment = moment( goal.goal_date );
        const monthsTillRetirement: number = Util.diffMomentByUTC( m, moment(), 'months', true );

        // need to get the end date to add the line to the expected wealth chart
        const endDate: Moment = moment( m ).add( goal.lengthOfWithdrawals, 'years' );
        const monthsTillRetirementEnd: number = Util.diffMomentByUTC( endDate, moment(), 'months', true );
        // check to see if this goal's end date is after the current cursor
        if ( !finalEndDate ) {
          // if no finalEndDate, then this is the first additional retirement and could be the final end date
          finalEndDate = endDate;
        } else if ( endDate.isAfter( finalEndDate ) ) {
          // if there was a previous end date, then check if this one is after, if so set this one to be the final
          finalEndDate = endDate;
        }

        // create a GoalWithdrawal and push it into the retirementWithdrawals
        retirementWithdrawals.push( {
          goalId: goal.id,
          date: m,
          endDate,
          annualWithdrawals: goal.annualWithdrawals,
          lengthOfWithdrawals: goal.lengthOfWithdrawals,
          nValue: monthsTillRetirement,
          endNValue: monthsTillRetirementEnd,
          contributions: goal.annualContributions,
          name: goal.name,
        } );
      } else {
        // all other goal types are treated the same
        if ( goal.withdrawal_dates?.length > 0 ) {
          // if the goal has a schedule, we need to make a GoalWithdrawal for each of the dates
          for ( const d of goal.withdrawal_dates ) {
            let withdrawalObj: GoalWithdrawal;
            // newer implementation where the user has set a specific amount for each withdrawal date
            if ( d.hasOwnProperty( 'amount' ) ) {
              // new implementation with ScheduledGoalWithdrawal
              const sw: ScheduledGoalWithdrawal = d as ScheduledGoalWithdrawal;
              sw.date = moment( sw.date );
              withdrawalObj = {
                goalId: goal.id,
                amount: sw.amount,
                date: sw.date,
                nValue: Util.diffMomentByUTC( sw.date, moment(), 'months', true ),
                contributions: sw.monthlyContributions * 12,
                name: goal.name,
              };
            } else {
              // old implementation with just date and the amount is the same for each withdrawal
              const m: Moment = moment( GoalsUtil.getMomentFromWithdrawalDate( d ) );
              withdrawalObj = {
                goalId: goal.id,
                amount: goal.total_withdrawal / goal.withdrawal_dates.length,
                date: m,
                nValue: Util.diffMomentByUTC( m, moment(), 'months', true ),
                contributions: goal.annualContributions / goal.withdrawal_dates.length,
                name: goal.name,
              };
            }

            withdrawals.push( withdrawalObj );
          }
        } else {
          // foal has a single withdrawal
          const m: Moment = moment( goal.goal_date );
          const withdrawalObj: GoalWithdrawal = {
            goalId: goal.id,
            amount: goal.total_withdrawal,
            date: m,
            nValue: Util.diffMomentByUTC( m, moment(), 'months', true ),
            contributions: goal.annualContributions,
            name: goal.name,
          };
          withdrawals.push( withdrawalObj );
        }
      }
    }

    // set up a comparator for sorting the withdrawals by date
    const comparator = ( a, b ) => {
      return a.date.isBefore( b ) ? 1 : -1;
    };

    withdrawals.sort( comparator );

    retirementWithdrawals.sort( comparator );

    return { withdrawals, retirementWithdrawals, finalEndDate };
  }

  formatScaleNumber( x: number ) {
    if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.age ) {
      return `${ this.ripDecimalPipe.transform( x, '0-2' ) }`;
    } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears ) {
      return `${ x }`;
    } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.yearsFromToday ) {
      return `${ this.ripDecimalPipe.transform( x, '0-2' ) }`;
    }
    /*if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.age ) {
     return `Age ${ this.ripDecimalPipe.transform( x, '0-2' ) }`;
     } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears ) {
     return `in ${ x }`;
     } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.yearsFromToday ) {
     return `${ this.ripDecimalPipe.transform( x, '0-2' ) } year(s) from today`;
     }*/
  }

  calcAndSetOneMonthOfChartData( wealthAtNthMinusOneMonth,
                                 totalExpectedReturn, // this should be determined as short or long term before this
                                 n, // in months
                                 annualContributions: number,
                                 goalContributionsTotals: number,
                                 annualWithdrawals: number,
                                 goalWithdrawalsTotal: number,
                                 monthsTillRetirement,
                                 monthsTillEndOfRetirement,
                                 standardDeviation,
                                 stats,
                                 yMax,
                                 yMin,
                                 chartDataSets,
                                 yearAtZero: any,
                                 goalInfo?: any,
                                 goalWithdrawals?: GoalWithdrawal[],
                                 retirementWithdrawals?: GoalWithdrawal[] ) {
    const xDate = moment().add( n, 'months' );
    const currentXValue = this.determineScaleNumber( n, xDate );
    const formattedXValue = this.formatScaleNumber( currentXValue );
    let needToDoRetirementNext: boolean = false;

    // goalInfo means we need to do subtract a withdrawal after calculating the nthMonthWealth
    let postGoalWithdrawalExpectedWealth;
    if ( goalInfo ) {
      postGoalWithdrawalExpectedWealth = wealthAtNthMinusOneMonth - goalInfo.withdrawals;
      // postGoalWithdrawalExpectedWealth = this.addPostGoalWithdrawalNumbers( chartDataSets, goalInfo.withdrawals, currentXValue );
    }

    let nthMonthWealth: number = this.calcNthMonthWealth(
      postGoalWithdrawalExpectedWealth ?? wealthAtNthMinusOneMonth, // if there was a goal withdrawal, use the number calculated above, or use the
      // previous month wealth
      totalExpectedReturn,
      n,
      annualContributions,
      goalContributionsTotals,
      annualWithdrawals,
      goalWithdrawalsTotal,
      monthsTillRetirement,
      monthsTillEndOfRetirement );

    const nthMonthRiskPotential: number = this.calcRiskPotentialChunkForNthMonth(
      nthMonthWealth,
      n,
      standardDeviation,
      this.benchmark.probability ?? this.riskPotentialDefaultProbability );


    let upsidePotential = nthMonthWealth + nthMonthRiskPotential;
    let downsideRisk = nthMonthWealth - nthMonthRiskPotential;

    // check all the numbers to see if they hit 0
    if ( nthMonthWealth <= 0 ) {
      if ( !yearAtZero ) {
        yearAtZero = {};
      }
      if ( !yearAtZero?.expectedWealth ) {
        yearAtZero.expectedWealth = n / 12;
      }
      nthMonthWealth = 0;
    }
    if ( upsidePotential <= 0 ) {
      if ( !yearAtZero ) {
        yearAtZero = {};
      }
      if ( !yearAtZero?.upsidePotential ) {
        yearAtZero.upsidePotential = n / 12;
      }
      upsidePotential = 0;
    }
    if ( downsideRisk <= 0 || nthMonthWealth <= 0 ) {
      if ( !yearAtZero ) {
        yearAtZero = {};
      }
      if ( !yearAtZero?.downsideRisk ) {
        yearAtZero.downsideRisk = n / 12;
      }
      downsideRisk = 0;
    }

    // when you get to the retirement year, need to switch the base starting wealth number because withdrawals will be used
    // if yearsTillRetirement is a decimal, then we will need to do a special case next time with n being a decimal,
    if ( n === Math.floor( monthsTillRetirement ) && !isInteger( monthsTillRetirement ) ) {
      needToDoRetirementNext = true;
    }

    // otherwise, we just use n because it is equal
    if ( n.toFixed( 2 ) === monthsTillRetirement.toFixed( 2 ) ) {
      stats.inflectionPoint = {
        expectedWealth: nthMonthWealth,
        downsideRisk,
        upsidePotential,
        n: formattedXValue,
        label: 'Retirement',
      };
      chartDataSets[ 0 ].retirement = currentXValue;
    }

    // check for investor retirement end
    if ( n - monthsTillEndOfRetirement > 0 && n - monthsTillEndOfRetirement < 1 ) {
      chartDataSets[ 0 ].retirementEnd = currentXValue;
    }

    // first year wealth
    if ( n === 12 ) {
      stats.firstYear = {
        expectedWealth: nthMonthWealth,
        downsideRisk,
        upsidePotential,
        n: formattedXValue,
        label: '1 Year',
      };
    }


    // check min and max values
    if ( upsidePotential > yMax ) {
      yMax = upsidePotential;
    }

    if ( downsideRisk < yMin ) {
      yMin = downsideRisk;
    }


    // expected wealth
    chartDataSets[ 0 ].data.push( {
      x: currentXValue,
      y: nthMonthWealth,
    } );
    // downside risk
    chartDataSets[ 1 ].data.push(
      {
        x: currentXValue,
        y: downsideRisk,
      },
    );
    // upside potential
    chartDataSets[ 2 ].data.push( {
      x: currentXValue,
      y: upsidePotential,
    } );

    if ( goalInfo ) {
      for ( const index of goalInfo.indexes ) {
        goalWithdrawals[ index ].expectedWealthAtGoalDate = nthMonthWealth;
        goalWithdrawals[ index ].downsideRiskAtGoalDate = downsideRisk;
        goalWithdrawals[ index ].upsidePotentialAtGoalDate = upsidePotential;
        goalWithdrawals[ index ].xValue = currentXValue;
      }
      for ( const index of goalInfo.retirementIndexes ) {
        retirementWithdrawals[ index ].expectedWealthAtGoalDate = nthMonthWealth;
        retirementWithdrawals[ index ].downsideRiskAtGoalDate = downsideRisk;
        retirementWithdrawals[ index ].upsidePotentialAtGoalDate = upsidePotential;
        retirementWithdrawals[ index ].xValue = currentXValue;
      }
    }

    return {
      nthMonthWealth,
      yMin,
      yMax,
      stats,
      needToDoRetirementNext,
      yearAtZero,
    };
  }

  /**
   *
   * @param n - number of months from today
   * @param date - date corresponding to n months from now
   */
  determineScaleNumber( n: number, date: Moment ): number {
    if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears ) {
      return date.year() + ( date.dayOfYear() / 365 );
    } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.yearsFromToday ) {
      return date.diff( moment(), 'years', true );
    } else if ( this.expectedWealthScale === EXPECTED_VALUE_SCALES.age ) {
      return this.investorsCurrentAge + ( n / 12 );
    }
  }

  calcRiskPotentialChunkForNthMonth( nthMonthExpectedWealth: number, nthMonth: number, standardDeviation: number, probability: number ) {
    const normSInv = StatsUtil.normSInv( 1 - probability );
    const normSDist = StatsUtil.normSDist( normSInv );
    return ( standardDeviation * Math.sqrt( nthMonth / 12 ) ) * nthMonthExpectedWealth * normSDist / probability;
  }

  calcNthMonthWealth( previousWealth: number,
                      expectedReturn: number,
                      monthN: number,
                      annualContributions: number,
                      annualTotalGoalContributions: number,
                      annualWithdrawals: number,
                      annualTotalGoalWithdrawals: number,
                      retirementMonth: number,
                      monthsTillEndRetirement: number ) {

    // monthN is in months
    const inRetirement: boolean = monthN > retirementMonth;

    // turn annual number into monthly number
    let retirementContributionsOrWithdrawals = inRetirement ? -( annualWithdrawals / 12 ) : annualContributions / 12;
    // past the investor's end of retirement date, so they aren't contributing or withdrawing
    if ( monthN > monthsTillEndRetirement ) {
      retirementContributionsOrWithdrawals = 0;
    }
    // but their heirs or partner might be
    const totalContributionsOrWithdrawals = retirementContributionsOrWithdrawals + ( ( annualTotalGoalContributions - annualTotalGoalWithdrawals ) / 12 ); // monthly net contributions/withdrawals

    const discountFactor = 1 + ( expectedReturn / 12 );
    return ( previousWealth + totalContributionsOrWithdrawals ) * discountFactor;
  }


  checkProxyDataRetrieved( benchmark ) {
    this.benchmarkTickers = [];
    const defaultBenchmark = BenchmarkUtil.defaultBenchmark();
    const tickers = [];
    for ( const w of [
      ...benchmark.benchmarkData.stock.weights,
      ...benchmark.benchmarkData.bond.weights,
      benchmark.benchmarkData.cash,
      // need to make sure the defaults are here too in case the user resets to default
      ...defaultBenchmark.benchmarkData.stock.weights,
      ...defaultBenchmark.benchmarkData.bond.weights,
      defaultBenchmark.benchmarkData.cash,
    ] ) {
      const sec = this._state.globalVars.securities.find( ( s: any ) => {
        return s.ticker === w.proxy;
      } );
      if ( !sec ) {
        tickers.push( w.proxy );
      }
      this.benchmarkTickers.push( w.proxy );
    }

    return tickers;

  }

  getCurrentBenchmarkTickerList() {
    const tickers: string[] = [];
    for ( const w of [ ...this.benchmark.benchmarkData.stock.weights, ...this.benchmark.benchmarkData.bond.weights, this.benchmark.benchmarkData.cash ] ) {
      tickers.push( w.proxy );
    }
    return tickers;
  }

  /*
   * Function for resetting to the default benchmark because the user deleted the current benchmark or all of
   * their benchmarks
   * */
  resetBenchmarkToDefault() {
    this.benchmark = BenchmarkUtil.defaultBenchmark();
    this.setupBenchmarkCompositionFormControls();
  }

  /*
   * Function to cancel any changes to the current benchmark and reset it back to original values or the default benchmark
   * */
  cancel() {
    this.loadBenchmark( Util.clone( this.initialUserBenchmark ) );
  }

  /*
   * Function to set up the benchmark form group and add controls for each benchmark weight
   * */
  setupBenchmarkCompositionFormControls() {
    this.benchmarkFormLoading = true;

    this.form = new UntypedFormGroup( {} );
    // loop through the set of benchmark groups to set up the form controls

    // setup bond sector controls
    const bondSectors = this.benchmark.benchmarkData.bond;
    bondSectors.total = 0;
    // loop through each benchmark in the group to sum total and setup each form control
    for ( const item of bondSectors.weights ) {
      bondSectors.total += item.allocation; // add to this groups total and at the end we should get 1 (100%)
      const ripPercentPipe = new RipsawPercentPipe();

      this.form.addControl( item.key, new UntypedFormControl(
        ripPercentPipe.transform( item.allocation, '2-2',
        ),
        Validators.compose( [
          Validators.required,
          this.benchmarkValidator,
        ] ) ) );
    }

    // setup stock sector controls
    const stockSectors = this.benchmark.benchmarkData.stock;
    stockSectors.total = 0;
    // loop through each benchmark in the group to sum total and setup each form control
    for ( const item of stockSectors.weights ) {
      stockSectors.total += item.allocation; // add to this groups total and at the end we should get 1 (100%)


      this.form.addControl( item.key, new UntypedFormControl(
        this.ripPercentPipe.transform( item.allocation, '2-2',
        ),
        Validators.compose( [
          Validators.required,
          this.benchmarkValidator,
        ] ) ) );
    }

  }

  /*
   * Function for updating the aggregated benchmark data
   * */
  updateCalculatedBenchmarkData() {
    if ( !this.benchmark || !this.benchmark.benchmarkData ) {
      this._state.globalVars.benchmark = BenchmarkUtil.defaultBenchmark();
      this.benchmark = this._state.globalVars.benchmark;
    }
    this.calculatedBenchmarkData = AllocCalculator.calculateAllocations(
      BenchmarkUtil.convertBenchmarkToPositions(
        this.benchmark,
        this.getInvestmentTotal(),
        this._state.globalVars.securities ),
      false,
      {
        ra: false,
        todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
      },
    );
  }

  /*
   * Function for validating that a given benchmark group sums up to 100%
   * @param groupName {String} - name of the group to validate
   * */
  benchmarkGroupValid( groupName: string ) {
    const group = this.getGroup( groupName );
    BenchmarkHelpers.updateGroupTotal( group );
    return BenchmarkHelpers.checkGroupTotal( group.total );
    // this.groupErrors.push( `${group.label} weights do not sum to 100%` );

  }

  /*
   * Function for validating that all group totals sum up to 100%
   * */
  checkAllGroupTotals() {
    for ( const key of Object.keys( this.benchmark.benchmarkData ) ) {
      if ( ![ 'cash', 'name' ].includes( key ) && !this.benchmarkGroupValid( key ) ) {
        return false;
      }
    }
    return true;
  }

  /*
   * get the group object from the benchmarkData
   * @param groupName {String} - name of the group to retrieved from the current benchmark data
   * */
  private getGroup( groupName: string ) {
    const group = this.benchmark.benchmarkData[ groupName ];
    if ( !group ) {
      console.error( 'The group name given does not exist' );
      return null;
    } else {
      return group;
    }
  }

  /*
   * Function for updating the given input with a formatted version of the input's value and update the class variable's
   * values too
   * @param input {HtmlInput} - html input to be updated on input event triggered by this input
   * @param formControlName {String} - name of the FormControl that needs to be updated as well as the key value for
   * the benchmark weight if a groupName is given
   * @param groupName {String} - the name of the benchmark group that contains the weight that is being updated
   * */
  updateInput( input: any, formControlName: any, type: string, groupName?: string ) {
    // const selectionStart = input.selectionStart;
    // const selectionEnd = input.selectionEnd;
    const inputValue = BenchmarkHelpers.getValueFromControl( this.form.controls[ formControlName ] );

    // this is a sector weight
    this.form.controls[ formControlName ].setValue( this.ripPercentPipe.transform( inputValue / 100, '2-2' ) );

    if ( groupName ) {
      const weight = _.find( this.benchmark.benchmarkData[ groupName ].weights, ( w: any ) => {
        return w.key === formControlName;
      } );
      if ( weight ) {

      }
      weight.allocation = inputValue / 100;

      this.benchmarkGroupValid( groupName );
    }

    this.updateCalculatedBenchmarkData();
  }

  investmentTotalCached: number;
  aggregatedInvestmentsCached: any;

  /*
   * Function for getting the investment total from the allocation widget
   * */
  getInvestmentTotal() {
    // for now, let's just do this all the time, until the allocation widget code is refactored into a state class that
    // can be injected here to pull the data more easily
    this.investmentTotalCached = this.getInvestmentValueFromAllocCalculator();
    return this.investmentTotalCached;
    // }
  }

  /*
   * Function for getting investmentTotal directly from allocCalculator if needed
   * */
  getInvestmentValueFromAllocCalculator() {
    const accounts: Account[] = this._accountManager.getAllOriginalAccounts() || [];
    const positions: Position[] = this._accountManager.getAllOriginalPositionsIncludingManualAccounts() || [];
    this.aggregatedInvestmentsCached = AllocCalculator.calculateAllocations( positions, false, {
      ra: false,
      accounts,
      revisableAccounts: this._accountManager.getAllRevisableAccounts(),
      todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
    } );
    return this.aggregatedInvestmentsCached?.investment_total;
  }

  benchmarkGroupChoosing: string;
  proxyButtonLabel: string = 'Click to select a proxy security';

  /*
   * Function for adding a new benchmark weight/sector to the current benchmark
   * */
  addBenchmarkWeight( newWeight: any ) {
    this.benchmark.benchmarkData[ this.benchmarkGroupChoosing ].weights.push( newWeight );
    this.setupBenchmarkCompositionFormControls();
    this.benchmarkFormLoading = false;
    this.saveButtonOptions.active = false;
    delete this.benchmark.benchmarkData.name;
  }

  /*
   * Function for removing a benchmark weight from the current benchmark
   * @param weight {Object} - benchmark weight to be removed
   * @param groupName {String} - the name of the benchmark group that the weight is being removed from
   * */
  removeBenchmarkWeight( weight: any, groupName: string ) {
    _.remove( this.benchmark.benchmarkData[ groupName ].weights, ( w: any ) => {
      return w.key === weight.key;
    } );

  }

  /*
   * Function for getting the cash, stocks, and bonds breakdown for a benchmark group
   * */
  getProxyPortfolioBreakdown( cashStockOrBond ) {
    switch ( cashStockOrBond ) {
      case 'cash':
        const proxySec = this._state.globalVars.securities.find( ( s: any ) => {
          return s?.ticker === this.benchmark?.benchmarkData?.cash?.proxy;
        } );
        return { cash: proxySec.cash, stocks: proxySec.stocks, bonds: proxySec.bonds };
      case 'stock':
      case 'bond':
        const proxies = [];
        for ( const w of this.benchmark.benchmarkData[ cashStockOrBond ].weights ) {
          const sec = this._state.globalVars.securities.find( ( s: any ) => {
            return s?.ticker === w?.proxy;
          } );
          /*if ( !sec ) {
           this._gdService.getSecurity( w.proxy ).subscribe( ( resp: any ) => {

           } );
           }*/
          proxies.push( {
            sec,
            weight: w.allocation,
          } );
        }
        return BenchmarkHelpers.aggregateProxies( proxies );

    }
  }

  checkSecurityList( shorterTickersList: string[], tries: number = 0 ) {
    return new Observable( ( observer ) => {
      let tickers = [];
      if ( shorterTickersList ) {
        tickers = shorterTickersList;
      } else {
        for ( const b of this._state.securitiesNotInCache( this.benchmarkTickers ) ) {
          if ( b !== '' ) {
            tickers.push( b );
          }
        }
      }
      if ( tickers.length === 0 ) {
        observer.next();
        return;
      } else {
        this._gdService.getSecurities( tickers.join( ',' ) )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( resp: any ) => {
            this._state.addToSecuritiesCache( resp.data );
            this.benchmarkSecuritiesRetrieved = true;
            const secsNotInCache = this._state.securitiesNotInCache( this.benchmarkTickers );
            if ( secsNotInCache?.length > 0 && tries <= this.maxSecurityCheckTries ) {
              return this.checkSecurityList( secsNotInCache, tries++ );
            } else {
              observer.next();
              return;
            }
          }, ( err ) => {
            if ( tries <= this.maxSecurityCheckTries ) {
              return this.checkSecurityList( null, tries++ );
            } else {
              if ( err && err.err ) {
                observer.error( err.err );
              } else if ( err ) {
                observer.error( err );
              } else {
                observer.error( 'Error retrieving security information!' );
              }
            }
          } );
      }
    } );
  }

  getBenchmarkSetupData() {
    if ( this._state.globalVars.firstAccountPullComplete || this.doingOnboarding ) {
      // reset proxy objects
      this.benchmarkBondProxies = {};
      this.benchmarkStockProxies = {};
      this.cashProxy = {};
      this.getBenchmarkComponents();
      this.getInvestmentTotal();
      this.getPortfolioInfo();
      this.getRiskReturns();
    }
    // this function used to create a subscription to account.manager.refresh.complete and destroy it upon completion,
    // but now there is one created in the constructor that will consolidate all the previous subscriptions to the
    // same event
  }

  getRiskReturns() {
    // show spinner
    this.loading = true;
    this.longTermFrontierHasBeenProcessed = false;

    const stockSecurities = [];
    Object.keys( this.benchmarkStockProxies ).forEach( i => {
      const security = this.localSecuritiesCache[ i ];
      if ( security ) {
        security.allocation = this.benchmarkStockProxies[ i ]?.weight;
        stockSecurities.push( security );
      }
    } );
    const bondSecurities = [];
    Object.keys( this.benchmarkBondProxies ).forEach( i => {
      const security = this.localSecuritiesCache[ i ];
      if ( security ) {
        security.allocation = this.benchmarkBondProxies[ i ]?.weight;
        bondSecurities.push( security );
      }
    } );

    // get expected returns
    this._mdService.getBenchmarkRiskReturns( stockSecurities, bondSecurities ) // , this.benchmark?.benchmarkData?.cash?.proxy )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // reset the map
          this.riskReturns = new Map<string, RiskReturn>();
          this.riskReturnsValues = [];
          this.riskReturnsValues = this.processReturns( resp?.data, this.riskReturns );
          this.getEfficientFrontier();
          this.riskReturnError = MarketInfoUtil.stockRiskReturnsHaveError( this.riskReturnsValues );
          this.loading = false; // hide the spinner
          setTimeout( () => {
            this._state.notifyDataChanged( 'setup.benchmark.fl.table' );
          }, 500 );
        }, error: ( err ) => {
          console.error( err );
          this.riskReturnError = true;
          this.loading = false;
        },
      } );
  }

  processReturns( retrievedReturns: RiskReturn[],
                  riskReturns: Map<string, RiskReturn> ): RiskReturn[] {

    if ( !retrievedReturns || retrievedReturns.length === 0 ) return;
    // setup scatter plot datasets and labels
    this.shortTermRiskReturnScatterData.datasets = [];
    this.longTermRiskReturnScatterData.datasets = [];
    const riskReturnScatterLabels = [];
    const colors = ChartColorUtil.getColorGradients( retrievedReturns.length );
    // go through the expected returns and set up the map and then back to an array to
    for ( let i = 0; i < retrievedReturns.length; i++ ) {
      const rr = retrievedReturns[ i ];
      if ( !rr ) continue;
      // set the label for this risk return set in the scatter plot
      riskReturnScatterLabels.push( rr.label );
      // set the data point and the dataset options
      this.shortTermRiskReturnScatterData.datasets.push( {
        data: [ {
          x: rr.annualizedStandardDeviation ?? rr.updatedStandardDeviation,
          y: BenchmarkUtil.getCorrectER( rr, MarketCalcTerms.short, true ),
        } ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[ i ],
        borderColor: colors[ i ],
        pointBackgroundColor: colors[ i ],
        pointHoverBackgroundColor: colors[ i ],
        pointHoverBorderColor: colors[ i ],

      } );

      this.longTermRiskReturnScatterData.datasets.push( {
        data: [ {
          x: rr.vix ? rr.longTermUpdatedStandardDeviation : rr.longTermAnnualizedStandardDeviation,
          y: BenchmarkUtil.getCorrectER( rr, MarketCalcTerms.long, true ),
        } ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[ i ],
        borderColor: colors[ i ],
        pointBackgroundColor: colors[ i ],
        pointHoverBackgroundColor: colors[ i ],
        pointHoverBorderColor: colors[ i ],
      } );

      riskReturns.set( rr.identifier, rr );
    }
    this.shortTermRiskReturnScatterData.labels = riskReturnScatterLabels;
    this.longTermRiskReturnScatterData.labels = riskReturnScatterLabels;

    return Array.from( riskReturns.values() );
  }

  getBenchmarkComponents() {

    // this.localSecuritiesCache = {};

    this._state.globalVars.securities.forEach( ( sec ) => {
      this.localSecuritiesCache[ sec.ticker ] = sec;
    } );
    // grab the benchmark and add it's stock proxies into allFunds
    this.benchmark?.benchmarkData?.stock?.weights.forEach( i => {
      if ( !this.benchmarkStockProxies[ i.proxy ] ) {
        this.benchmarkStockProxies[ i.proxy ] = {
          label: i.label,
          weight: i.allocation,
        };
      }
    } );
    this.benchmark?.benchmarkData?.bond?.weights.forEach( i => {
      if ( !this.benchmarkBondProxies[ i.proxy ] ) {
        this.benchmarkBondProxies[ i.proxy ] = {
          label: i.label,
          weight: i.allocation,
        };
      }
    } );
    this.cashProxy = {
      label: this.benchmark?.benchmarkData?.cash?.label,
      weight: this.benchmark?.benchmarkData?.strategicAssetAllocation?.cash?.allocation,
    };

    this.allProxies = {};

    this.allProxies = Object.assign( {}, this.allProxies, this.benchmarkBondProxies, this.benchmarkStockProxies );
    this.allProxies[ this.benchmark?.benchmarkData?.cash?.proxy ] = this.cashProxy;
    this.cashBondStockWeightMap = {
      CASH: this.benchmark?.benchmarkData?.strategicAssetAllocation?.cash?.allocation,
      STOCKS: this.benchmark?.benchmarkData?.strategicAssetAllocation?.stocks?.allocation,
      BONDS: this.benchmark?.benchmarkData?.strategicAssetAllocation?.bonds?.allocation,
    };
    this.benchmarkRiskExposureList = [
      {
        label: 'Stocks',
        weight: this.benchmark?.benchmarkData?.strategicAssetAllocation?.stocks as StrategicAllocation,
      },
      {
        label: 'Bonds',
        weight: this.benchmark?.benchmarkData?.strategicAssetAllocation?.bonds as StrategicAllocation,
      },
      {
        label: 'Cash',
        weight: this.benchmark?.benchmarkData?.strategicAssetAllocation?.cash as StrategicAllocation,
      },
    ];
  }

  getPortfolioInfo( fromSubscription?: boolean ) {
    if ( this._state.globalVars.firstAccountPullComplete || fromSubscription ) {

      this.portfolioRiskExposureList = [
        {
          label: 'Cash',
          weight: { allocation: this.aggregatedInvestmentsCached?.cash / this.investmentTotalCached },
        },
        {
          label: 'Bonds',
          weight: { allocation: this.aggregatedInvestmentsCached?.bonds / this.investmentTotalCached },
        },
        {
          label: 'Stocks',
          weight: { allocation: this.aggregatedInvestmentsCached?.stocks / this.investmentTotalCached },
        },
      ];

    }
  }

  static benchmarkExpectedReturnsValid( set: BenchmarkExpectedReturnSet ): boolean {
    if ( set === undefined || set === null ) {
      return false;
    }
    const keys = Object.keys( set );
    for ( const key of keys ) {
      if ( set[ key ] === undefined || isNaN( set[ key ] ) ) {
        return false;
      }
    }
    return keys.length > 0;
  }

  getEfficientFrontier() {

    this.shortTermFrontierHasBeenProcessed = false;
    this.longTermFrontierHasBeenProcessed = false;

    const weights = [];
    const benchmark_covariance_object = {};
    const benchmark_long_term_covariance_object = {};
    const benchmark_expected_return_object: BenchmarkExpectedReturnSet = {
      CASH: undefined,
      BONDS: undefined,
      STOCKS: undefined,
    };
    const benchmark_long_term_expected_return_object: BenchmarkExpectedReturnSet = {
      CASH: undefined,
      BONDS: undefined,
      STOCKS: undefined,
    };

    // we use riskReturnValues instead of Object.keys(this.allProxies) to make sure we preserve the original order
    this.riskReturnsValues.forEach( ( rr ) => {
      weights.push( { identifier: rr.identifier, weight: this.cashBondStockWeightMap[ rr.identifier ] } );
      benchmark_covariance_object[ rr.identifier ] = rr.covariances;
      benchmark_long_term_covariance_object[ rr.identifier ] = rr.longTermCovariances;
      benchmark_expected_return_object[ rr.identifier ] = BenchmarkUtil.getCorrectER( rr, MarketCalcTerms.short, false );
      benchmark_long_term_expected_return_object[ rr.identifier ] = BenchmarkUtil.getCorrectER( rr, MarketCalcTerms.long, false );
    } );

    const minCashPercent = this.getMinCashPercentage();
    const tolerance = 0.00001;


    if ( BenchmarkState.benchmarkExpectedReturnsValid( benchmark_expected_return_object )
      && BenchmarkState.benchmarkExpectedReturnsValid( benchmark_long_term_expected_return_object ) ) {

      const shortTermDesiredExpectedReturns = this.getFrontierRange( benchmark_expected_return_object );
      const longTermDesiredExpectedReturns = this.getFrontierRange( benchmark_long_term_expected_return_object );

      this._benchmarkOptimizerService.optimizeBenchmarkPortfolio( weights,
        benchmark_covariance_object,
        benchmark_expected_return_object,
        shortTermDesiredExpectedReturns,
        minCashPercent,
        tolerance,
        this.doingOnboarding )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            // console.log( 'short term frontier returned' );
            this.processFrontier( resp?.data?.frontier, MarketCalcTerms.short );
            this.shortTermFrontierHasBeenProcessed = true;
          }, error: ( err ) => {
            console.error( err );
            // TODO: alert user
          },
        } );

      this._benchmarkOptimizerService.optimizeBenchmarkPortfolio( weights,
        benchmark_long_term_covariance_object,
        benchmark_long_term_expected_return_object,
        longTermDesiredExpectedReturns,
        minCashPercent,
        tolerance,
        this.doingOnboarding ).pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            // console.log( 'long term frontier returned' );
            this.processFrontier( resp?.data?.frontier, MarketCalcTerms.long );
            this.longTermFrontierHasBeenProcessed = true;
          }, error: ( err ) => {
            console.error( err );
            // TODO: alert user
          },
        } );
    } else {
      this.riskReturnError = true;
    }
  }

  getMinCashPercentage() {
    let emergencyFundsTotal = 0;
    if ( this.goalsState?.goals?.length > 0 ) {
      this.goalsState.goals.filter( g => g.type === USER_GOAL_TYPE_IDS.emergency_fund )
        .forEach( ( g: UserGoal ) => {
          emergencyFundsTotal += g.total_withdrawal;
        } );
    }
    if ( this.investmentTotalCached ) {
      return emergencyFundsTotal / this.investmentTotalCached;
      // return this.benchmark.minimumCash / this.investmentTotalCached;
    }
    const investmentTotal = this.getInvestmentTotal();
    if ( investmentTotal ) {
      return emergencyFundsTotal / investmentTotal;
      // return this.benchmark.minimumCash / investmentTotal;
    }

    return emergencyFundsTotal / BenchmarkUtil.defaultStartingPortfolioSize;
    // return this.benchmark.minimumCash / BenchmarkUtil.defaultStartingPortfolioSize;
  }

  getFrontierRange( benchmark_expected_return_object: BenchmarkExpectedReturnSet ) {
    let min: number, max: number;

    Object.keys( benchmark_expected_return_object ).forEach( ( key: string ) => {
      if ( key?.length > 0 ) { // don't want this block running if the key is undefined
        const er = benchmark_expected_return_object[ key ];
        if ( min === undefined || er < min ) {
          min = er;
        }
        if ( max === undefined || er > max ) {
          max = er;
        }
      }
    } );

    const interval = ( max - min ) / this.frontierTicks;

    const range = [];
    if ( max > 0 ) {
      for ( let i = min; i <= max; i = i + interval ) {
        if ( i !== undefined && i !== null ) {
          range.push( i );
        }
      }

      range.push( max );
    }

    return range;
  }

  /**
   * set up the frontier data to be used in the risk vs return chart. reused for short and long term frontiers
   * @param frontier
   * @param term
   */
  processFrontier( frontier: EfficientFrontierPoint[], term: MarketCalcTerms ) {

    // error check
    this.frontierError = !!( frontier.length === 0 || frontier[ 0 ].error );
    if ( this.frontierError ) {
      console.error( 'frontier error' ); // this should only happen for older users who had a custom benchmark that wasn't vetted
      return;
    }
    // console.log( 'no frontier error' );

    const color = '#79bfd7';

    const dataset = {
      data: [],
      label: 'Efficient Frontier',
      pointRadius: 0,
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      showLine: true,
      fill: false,
    };


    if ( term === MarketCalcTerms.short ) {
      this.currentShortTermFrontier = [];

      this.fillCurrentFrontier( frontier, dataset, this.currentShortTermFrontier );

      // hiding the short term frontier for now
      /*if ( this.shortTermRiskReturnScatterData.datasets[this.riskReturns.size] ) {
       this.shortTermRiskReturnScatterData.datasets[this.riskReturns.size] = dataset;
       } else {
       this.shortTermRiskReturnScatterData.datasets.push( dataset );
       }*/

      // const sd = this.getBenchmarkStandardDeviation( BenchmarkTerms.short );
      // frontierIndex = this.getEFPointClosestToSD( sd, term );

      if ( this.longTermFrontierHasBeenProcessed ) {
        // don't actually want to use the short term frontier yet, so right now, we just want to make sure the setup
        // function gets called once both frontiers have been processed
        setTimeout( () => {
          this.setupFrontier();
        }, 500 );
      }
    } else {
      this.currentLongTermFrontier = [];

      this.fillCurrentFrontier( frontier, dataset, this.currentLongTermFrontier );

      if ( this.longTermRiskReturnScatterData.datasets[ this.riskReturns.size ] ) {
        this.longTermRiskReturnScatterData.datasets[ this.riskReturns.size ] = dataset;
        this.longTermRiskReturnScatterData.labels[ this.riskReturns.size ] = 'Efficient Frontier';
      } else {
        this.longTermRiskReturnScatterData.datasets.push( dataset );
        this.longTermRiskReturnScatterData.labels.push( 'Efficient Frontier' );
      }

      const sd = this.getBenchmarkStandardDeviation( MarketCalcTerms.long );
      this.selectedFrontierIndex = this.getEFPointClosestToSD( sd, term );

      if ( this.shortTermFrontierHasBeenProcessed ) {
        setTimeout( () => {
          this.setupFrontier();
        }, 500 );
      }
    }
  }

  setupFrontier() {
    this.redrawFrontierForNewTerm( this.term );
    this.selectFrontierPoint( this.selectedFrontierIndex );
  }

  /**
   * filter out frontier points that have a greater standard deviation and lower (or not greater) return than the next point on the frontier
   * @param frontier
   * @param dataset
   * @param currentFrontier
   */
  fillCurrentFrontier( frontier: EfficientFrontierPoint[], dataset: any, currentFrontier: EfficientFrontierPoint[] ) {
    for ( let i = 0; i < frontier.length; i++ ) {
      const fp: EfficientFrontierPoint = frontier[ i ];
      if ( BenchmarkState.checkFrontierPoint( i, frontier ) ) {
        currentFrontier.push( frontier[ i ] );
        dataset.data.push( {
          x: fp.minimization_stats.standard_deviation,
          y: fp.minimization_stats.total_expected_return * 100,
        } );
      }
    }
  }

  /**
   *
   * @param index - array index of the frontier point being checked
   * @param frontier - efficient frontier to check
   * @returns boolean - true if current frontier point has a smaller standard deviation than the next point. If it is
   * larger, then this point has a higher risk than the next point on the frontier for less reward
   */
  static checkFrontierPoint( index: number, frontier: EfficientFrontierPoint[] ) {
    const currentFP = frontier[ index ];
    const nextFP = frontier[ index + 1 ];

    return !nextFP || currentFP?.minimization_stats?.standard_deviation < nextFP?.minimization_stats?.standard_deviation;
  }

  /**
   * handle when the user clicks on the chart and chooses a point on the frontier (won't be called when a risk vs return point it clicked)
   * @param event
   */
  frontierPointClicked( event ) {
    if ( event.error ) {
      // no frontier point actually clicked
    } else {
      this.selectFrontierPoint( event.index );
    }
  }

  /**
   * select a frontier point on the frontier given an index in the array
   * @param frontierIndex
   * @param setBySlider - only present when the frontier point selection is done by the risk tolerance slider
   */
  selectFrontierPoint( frontierIndex: number, setBySlider?: boolean ) {
    this.selectedFrontierIndex = frontierIndex;
    const frontier = this.term === MarketCalcTerms.short ? this.currentShortTermFrontier : this.currentLongTermFrontier;
    if ( !frontier || frontier?.length < frontierIndex ) {
      this.frontierError = true;
      return;
    } else {
      this.frontierError = false;
    }
    this.selectedFrontierPoint = frontier[ frontierIndex ];


    if ( this.selectedFrontierPoint ) {
      this.selectedDeviation = this.selectedFrontierPoint.minimization_stats.standard_deviation;

      this.selectedFrontierPoint.optimal_weights.forEach( ( w ) => {
        this.cashBondStockWeightMap[ w.identifier ] = w.weight;
      } );
      this._state.notifyDataChanged( 'setup.benchmark.fl.table' );
      const riskLevel = this.getSolutionRiskLevel( this.selectedFrontierPoint );
      this.selectedRiskLevel = riskLevel;

      if ( !setBySlider ) {
        this._state.notifyDataChanged( 'risk.slider.set.level', riskLevel );
      }
      this.recalculateAssetAllocation();
      if ( this.currentShortTermFrontier && this.currentLongTermFrontier ) {
        this.setupExpectedWealthDataForSelectedFrontierPoint();
        if ( !setBySlider ) {
          this.setupExpectedWealthBuckets();
        }
      }
      this.benchmark.name = this.getBenchmarkName();

      const chosenBenchmarkColor = '#47d022';
      const chosenFrontierPointDataset = {
        data: [ {
          x: this.selectedFrontierPoint.minimization_stats.standard_deviation,
          y: this.selectedFrontierPoint.minimization_stats.total_expected_return * 100, // need to convert this one here
        } ],
        label: 'Chosen Benchmark Portfolio',
        pointRadius: 7,
        backgroundColor: chosenBenchmarkColor, // green color
        borderColor: chosenBenchmarkColor,
        pointBackgroundColor: chosenBenchmarkColor,
        pointHoverBackgroundColor: chosenBenchmarkColor,
        pointHoverBorderColor: chosenBenchmarkColor,
      };
      if ( this.longTermRiskReturnScatterData.datasets[ this.riskReturns.size + 1 ] ) {
        this.longTermRiskReturnScatterData.datasets[ this.riskReturns.size + 1 ] = chosenFrontierPointDataset;
        this.longTermRiskReturnScatterData.labels[ this.riskReturns.size + 1 ] = 'Chosen Benchmark Portfolio';
      } else {
        this.longTermRiskReturnScatterData.datasets.push( chosenFrontierPointDataset );
        this.longTermRiskReturnScatterData.labels.push( 'Chosen Benchmark Portfolio' );
      }
      this._state.notifyDataChanged( 'frontier.point.selected', frontierIndex );
    }
  }

  /**
   * translate tje standard deviation of the given solution to a risk level to set the tolerance slider on a 0-100 scale
   * @param selectedSolution
   */
  getSolutionRiskLevel( selectedSolution: EfficientFrontierPoint ) {

    const frontier = this.term === MarketCalcTerms.short ? this.currentShortTermFrontier : this.currentLongTermFrontier;
    const minSD = frontier[ 0 ].minimization_stats.standard_deviation;
    const maxSD = frontier[ frontier.length - 1 ].minimization_stats.standard_deviation;

    const range = maxSD - minSD;

    return ( ( selectedSolution.minimization_stats.standard_deviation - minSD ) * 100 ) / range;
  }

  /**
   * get the total standard deviation of the benchmark using its components and their weights
   * @param term
   */
  getBenchmarkStandardDeviation( term: MarketCalcTerms ) {
    let totalContribution = 0;

    // create weights vector
    const weightsVector: number[] = this.riskReturnsValues.map( ( rr ) => {
      return this.cashBondStockWeightMap[ rr.identifier ];
    } );

    // get totalContributions using covariances and weights
    this.riskReturnsValues.forEach( rr => {

      const proxyPortfolioWeight = this.cashBondStockWeightMap[ rr.identifier ];
      const contributions = BenchmarkUtil.getContribution( rr, weightsVector, proxyPortfolioWeight );

      totalContribution += term === MarketCalcTerms.short ? contributions.contribution : contributions.longTermContribution;
    } );

    return Math.sqrt( totalContribution );

  }

  /**
   * recalculate the benchmark composition's contributions and total asset allocations, mostly for the donut charts
   */
  recalculateAssetAllocation() {

    this.updateBenchmarkAllocations();

    const stockContributions = [];
    const bondContributions = [];
    const cashContributions = [];

    const itemsMap = {
      CASH: {
        label: 'Cash',
      },
      BONDS: {
        label: 'Bonds',
      },
      STOCKS: {
        label: 'Stocks',
      },
    };
    this.benchmarkAssetAllocationList = [];

    const benchmarkPositions = BenchmarkUtil.convertBenchmarkToPositions(
      this.benchmark,
      this.getInvestmentTotal() || 1,
      this._state.globalVars.securities );

    const totals = {
      cash: 0,
      bonds: 0,
      stocks: 0,
    };

    benchmarkPositions.forEach( ( p: Position ) => {

      // this.getContributionsForSecurity( totals, cashContributions, stockContributions, bondContributions, p, 1, w.weight );

      // this one is easy
      this.benchmarkAssetAllocationList.push( { identifier: p.ticker, allocation: p.portfolio_allocation } );

      const bondContribution = p.bonds * p.portfolio_allocation;
      totals.bonds += bondContribution;
      if ( bondContribution > 0.0001 ) {
        bondContributions.push( { ticker: p.ticker, amount: bondContribution } );
      }
      const stockContribution = p.stocks * p.portfolio_allocation;
      totals.stocks += stockContribution;
      if ( stockContribution > 0.0001 ) {
        stockContributions.push( { ticker: p.ticker, amount: stockContribution } );
      }
      const cashContribution = p.cash * p.portfolio_allocation;
      totals.cash += cashContribution;
      if ( cashContribution > 0.0001 ) {
        cashContributions.push( { ticker: p.ticker, amount: cashContribution } );
      }

    } );

    Object.assign( itemsMap[ BENCHMARK_PROXY_IDENTIFIERS.cash ], {
      contributions: cashContributions,
      weight: { allocation: totals.cash },
    } );

    Object.assign( itemsMap[ BENCHMARK_PROXY_IDENTIFIERS.stocks ], {
      contributions: stockContributions,
      weight: { allocation: totals.stocks },
    } );

    Object.assign( itemsMap[ BENCHMARK_PROXY_IDENTIFIERS.bonds ], {
      contributions: bondContributions,
      weight: { allocation: totals.bonds },
    } );

    // setup list items for donut chart
    this.benchmarkRiskExposureList = Object.keys( itemsMap ).map( key => {
      return itemsMap[ key ];
    } );

  }

  /**
   * update benchmark strategic asset allocation data
   */
  updateBenchmarkAllocations() {
    this.selectedFrontierPoint.optimal_weights.forEach( ( item ) => {
      // console.log( 'updating benchmark strategic asset allocation for ', item.identifier, 'with value ', item.weight );
      this.benchmark.benchmarkData.strategicAssetAllocation[ item.identifier.toLowerCase() ].allocation = item.weight;
    } );

    this.allocWidget?.setBenchmark();
  }

  /**
   * choose a point on the frontier by rescaling the riskLevel to the frontier point with the closest standard deviation
   * @param riskLevel
   */
  riskLevelToFrontierSolution( riskLevel: number ) {
    const frontierIndex = this.getFrontierIndexFromRiskLevel( riskLevel );

    this.selectFrontierPoint( frontierIndex, true );

  }

  getFrontierIndexFromRiskLevel( riskLevel: number ): number {
    const frontier = this.term === MarketCalcTerms.short ? this.currentShortTermFrontier : this.currentLongTermFrontier;
    if ( frontier ) {
      const minSD = frontier[ 0 ].minimization_stats.standard_deviation;
      const maxSD = frontier[ frontier.length - 1 ].minimization_stats.standard_deviation;

      const range = maxSD - minSD;

      const translatedSD = minSD + ( ( riskLevel * range ) / 100 );
      return this.getEFPointClosestToSD( translatedSD, this.term );
    } else {
      return 0;
    }
  }

  /**
   * find the frontier point with a standard deviation closest to the given standardDeviation
   * @param standardDeviation - target standard deviation
   * @param term - used for determining which frontier to use
   */
  getEFPointClosestToSD( standardDeviation: number, term: MarketCalcTerms ): number {
    let min = standardDeviation;
    let frontierIndex;

    const frontier = term === MarketCalcTerms.short ? this.currentShortTermFrontier : this.currentLongTermFrontier;

    const length = frontier.length;
    for ( let i = 0; i < length; i++ ) {
      const fp: EfficientFrontierPoint = frontier[ i ];
      const diff = Math.abs( fp.minimization_stats.standard_deviation - standardDeviation );
      if ( diff < min ) {
        min = diff;
        frontierIndex = i;
      }
    }

    return frontierIndex;
  }

  /**
   * set the term being used
   * @param term - short or long
   */
  setTerm( term: MarketCalcTerms ) {
    this.term = term;
    this.redrawFrontierForNewTerm( term );
  }

  /**
   * trigger a re-draw of the frontier on the risk vs return chart
   * @param term - short or long
   */
  redrawFrontierForNewTerm( term: MarketCalcTerms ) {
    if ( term ) {
      if ( term === MarketCalcTerms.short ) {
        this.riskReturnScatterData = this.shortTermRiskReturnScatterData;
      } else {
        this.riskReturnScatterData = this.longTermRiskReturnScatterData;
      }
      this._state.notifyDataChanged( 'set.risk.scatter.min.and.max' );
    }
  }

  getBenchmarkName( riskLevel?: number, riskLabel?: boolean ) {
    if ( this.selectedFrontierPoint && this.currentLongTermFrontier ) {
      riskLevel = riskLevel ?? this.getSolutionRiskLevel( this.selectedFrontierPoint );
      let exact, prev, next;
      const riskLevels = BenchmarkUtil.mainRiskLevels;
      // console.log( 'riskLevel: ', riskLevel );
      for ( let i = 0; i < riskLevels.length; i++ ) {
        const rl: RiskLevel = riskLevels[ i ];
        if ( Math.abs( riskLevel - rl.riskNumber ) < 2.5 ) {
          exact = rl;
          break;
        } else if ( riskLevel > rl.riskNumber ) {
          prev = rl;
          if ( riskLevels[ i + 1 ] ) {
            next = riskLevels[ i + 1 ];
          } else {
            next = undefined;
          }
        }
      }
      let name;
      if ( exact ) {
        name = riskLabel ? exact.riskLabel : exact.label;
      } else {
        if ( prev ) {
          name = riskLabel ? prev.riskLabel : prev.label;
          if ( next ) {
            const percents = this.getPercentageOfInBetween( prev, next, riskLevel );
            name = `${ this.ripPercentPipe.transform( percents?.prevPercent, '0-0' ) } ${ name } - ${ this.ripPercentPipe.transform( percents?.nextPercent, '0-0' ) } ${ riskLabel ? next.riskLabel : next.label }`;
          }
        } else {
          name = riskLabel ? riskLevels[ 0 ]?.riskLabel : riskLevels[ 0 ]?.label;
        }
      }
      // console.log( name );
      return name;
    } else {
      return riskLabel ? '' : 'Custom Benchmark';
    }
  }

  getBenchmarkRiskLabel( riskLevel?: number ) {
    return this.getBenchmarkName( riskLevel, true );
  }

  getPercentageOfInBetween( prev: RiskLevel, next: RiskLevel, inBetween: number ) {
    const range = next.riskNumber - prev.riskNumber;
    const fromPrev = inBetween - prev.riskNumber;
    const toNext = next.riskNumber - inBetween;
    return { prevPercent: toNext / range, nextPercent: fromPrev / range };
  }

  /**
   * this function will return data for use in benchmark and expected wealth calcs that should exist either in
   * a logged user when authenticated, or in the onboarding data object if a non-authenticated user doing onboarding
   */
  getUserData() {
    if ( this._auth.authenticated() ) {
      return Util.getLoggedInUser( this._auth );
    } else {
      return this.onboardingData ?? {};
    }
  }

  /**
   * when a user logs out, most of the class variables need to be reset to their initial values so signing in as a
   * different user doesn't keep stale data from the previous
   * @param {Boolean} onLogout - whether this is called on logout and extra things should be reset
   */
  resetBenchmarkData( onLogout?: boolean ) {

    if ( onLogout ) {
      this.allocWidget = undefined;
      this.term = MarketCalcTerms.long;
      this.expectedWealthScale = EXPECTED_VALUE_SCALES.age;
    }

    this.benchmark = BenchmarkUtil.defaultBenchmark();
    this.initialUserBenchmark = undefined;
    this.benchmarkLastState = this.benchmark;

    this.benchmarkFormLoading = true;

    this.benchmarkTickers = [];

    this.calculatedBenchmarkData = undefined;

    this.loading = false;

    this.dialogIsOpen = false;
    this.userBenchmarkLoaded = false;

    this.selectedBucket = undefined;

    this.expectedWealthBuckets = [];
    this.expectedWealthMin = 0;
    this.expectedWealthMax = 0;

    this.riskReturns = new Map<string, RiskReturn>();
    this.riskReturnsValues = [];
    this.riskReturnError = false;
    this.riskReturnScatterData = {
      datasets: [],
      labels: [],
    };
    this.shortTermRiskReturnScatterData = {
      datasets: [],
      labels: [],
    };
    this.longTermRiskReturnScatterData = {
      datasets: [],
      labels: [],
    };

    this.benchmarkStockProxies = {};
    this.benchmarkBondProxies = {};
    this.allProxies = {};
    this.cashProxy = {};
    this.cashBondStockWeightMap = {};

    this.benchmarkRiskExposureList = [];
    this.benchmarkAssetAllocationList = [];
    this.portfolioRiskExposureList = [];

    this.currentShortTermFrontier = undefined;
    this.currentLongTermFrontier = undefined;
    this.selectedFrontierPoint = undefined;
    this.selectedFrontierIndex = undefined;
    this.selectedDeviation = undefined;
    this.selectedRiskLevel = undefined;

    this.benchmarkSecuritiesRetrieved = false;
    this.longTermFrontierHasBeenProcessed = false;
    this.shortTermFrontierHasBeenProcessed = false;

    this.bmPortfolio = undefined;
    this.investorsCurrentAge = undefined;
    this.loadingExpectedWealthData = true;
    this.refreshingEfficientFrontier = false;
    this.doingOnboarding = false;
    this.onboardingData = undefined;
    this.frontierError = false;

  }

  private getBenchmarkByLoadedWorkspace(): void {
    this.appStoreService.loadedWorkspace$
      .pipe(
        filter( Boolean ),
      )
      .subscribe( ( workspaceStore: WorkspaceLoadedStore ) => {
        this.currentWorkspaceLoadedStore = workspaceStore;
        // if doing onboarding, we don't want to lose onboarding data when the new user's primary workspace is created and loaded
        if ( !this.doingOnboarding ) {
          this.resetBenchmarkData();
          this.getUserBenchmark();
        }
      } );
  }
}
