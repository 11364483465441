import { inject, Injectable } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs';
import { GlobalState } from '../global.state';
import { Util } from './util.service';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { RipsawCurrencyPipe, RipsawPercentPipe } from '../theme/pipes';
import { TitleCasePipe } from '@angular/common';
import { StripeCard, StripePaymentSource, StripeSubscription } from './dataInterfaces';
import { EVENT_NAMES } from './enums';
import { AppStoreService } from '../store';
import { MatDialog } from '@angular/material/dialog';

import moment from 'moment';
import { AvailableFeature, getListOfFeatures, StripePrice, StripeProduct, User } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from './logger.service';
import { takeUntil } from 'rxjs/operators';
import { StripeService } from '../globalData/stripe.service';


export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
  INACTIVE = 'inactive', // this one is not a status in stripe, but we want it for display
  NONE = 'no_subscription' // this one is not a status in stripe, but we want it for display
}


@Injectable( {
  providedIn: 'root',
} )
export class SubscriptionDetailsUtil {

  private readonly onDestroy: Subject<void> = new Subject<void>();
  private stripeService: StripeService = inject( StripeService );

  noSource: boolean = false;
  currentCardInfo: StripeCard;

  currentSubscriptionInfo: StripeSubscription;
  currentDiscount: string;
  currentDiscountDuration: string;
  currentProduct: StripeProduct;
  currentPricing: StripePrice;
  currentPaymentSource: StripePaymentSource;

  futureSubscriptionInfo: StripeSubscription;
  futureDiscount: string;
  futureDiscountDuration: string;
  futureProduct: StripeProduct;
  futurePricing: any;
  futureSubIsLower: boolean;
  status: SubscriptionStatus = SubscriptionStatus.NONE;
  subEnded: boolean = false;

  stripeProducts: StripeProduct[] = [];

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  titleCasePipe: TitleCasePipe = new TitleCasePipe();

  subscriberName: string = 'subDetailsUtil';

  subIsTrialing: boolean;
  canceledSubs: StripeSubscription[] = [];
  activeSubs: StripeSubscription[] = [];
  trialingSubs: StripeSubscription[] = [];
  pastDueSub: StripeSubscription;

  trialDays: number;
  trialHours: number;

  hasRetrievedSubscription: boolean = false;
  errorRetrievingSubscription: string;
  retrievingSubscriptionDetails: boolean = true;
  needNewToken: Subject<boolean> = new Subject<boolean>();

  user: User;
  componentId: string;

  subscriptionInformationLoading: boolean = true;

  constructor(
    private _state: GlobalState,
    private _appStoreService: AppStoreService,
    public dialog: MatDialog,
  ) {
    this.componentId = Util.generateRandomString( 10 );

    if ( environment.env !== 'prod' ) {
      window[ `ripsaw_subDetailsUtil_${ this.componentId }` ] = this;
    }

    this._state.subscribe(
      EVENT_NAMES.LOGOUT,
      () => {
        this.resetStateOfSubscriptionInfo();
      },
      this.subscriberName,
    );

    this._state.subscribe(
      EVENT_NAMES.LOGGED_IN,
      () => {
        this.initiateProductRetrieval();
      },
    );
  }

  initiateProductRetrieval() {
    this.getCurrentStripeProducts().subscribe( {
      next: () => {
        this._state.notifyDataChanged( EVENT_NAMES.STRIPE_PRODUCTS_RETRIEVED, this.stripeProducts );
        this.subscriptionInformationLoading = true;
        this.refreshSubscriptionDetails()
          .then( () => {
            this.subscriptionInformationLoading = false;
            // Logger.info( 'Subscription details refreshed' );
          } )
          .catch( ( err ) => {
            this.subscriptionInformationLoading = false;
            Logger.warn( 'Error refreshing subscription details' );
          } );
      },
      error: ( err ) => {
        console.error( err );
        setTimeout( () => {
          console.log( 'retrying stripe product retrieval...' );
          this.initiateProductRetrieval();
        }, 5000 );
      },
    } );

  }

  getCurrentStripeProducts(): Observable<StripeProduct[]> {
    return new Observable<StripeProduct[]>( ( subscriber ) => {
      this.stripeService
        .getProducts()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            // console.log( 'Stripe products list: ', resp.data );
            this.stripeProducts = resp.data;
            subscriber.next();
            subscriber.complete();
          },
          error: ( err ) => {
            subscriber.error( err );
          },
        } );
    } );
  }

  resetSubInfos() {
    this.currentSubscriptionInfo = undefined;
    this.futureSubscriptionInfo = undefined;
  }

  refreshSubscriptionDetails() {
    const self = this;
    this.retrievingSubscriptionDetails = true;
    return new Promise<void>( ( resolve, reject ) => {
      this.stripeService
        .getAllSubscriptions()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.resetSubInfos();
            // console.log( resp );
            const subscriptions: StripeSubscription[] = resp.data;
            if ( subscriptions?.length === 0 ) {
              this.setNoSubDetails();
              // need to stop the other functions from executing
              resolve();
              return;
            } else {
              self.processAllSubscriptions( subscriptions );
              self.currentSubscriptionInfo = this.activeSubs[ 0 ] ?? this.trialingSubs[ 0 ];
            }
            this.setSubStatus();
            this.checkForTrialing();
            self.checkCurrentSubscriptionInfo();
            if ( self.futureSubscriptionInfo ) {
              self.checkFutureSubscriptionInfo();
            }
            if ( this.currentProduct.id !== '' ||
              this.canceledSubs.length > 0 ||
              this.pastDueSub || this.status === SubscriptionStatus.PAST_DUE ) {
              // this means an actual product matches the retrieved subscription
              this.hasRetrievedSubscription = true;
              this.errorRetrievingSubscription = undefined;
              this.retrievingSubscriptionDetails = false;
            } else {

              // otherwise, we will need to try again
              setTimeout( () => {
                this.refreshSubscriptionDetails().catch( ( err ) => {
                  console.error( err.err );
                  this.errorRetrievingSubscription = err.err;
                  this.retrievingSubscriptionDetails = false;
                  reject( err );
                } );
              }, 2000 );
            }
            resolve();
          },
          error: ( err ) => {
            console.error( err.err );
            this.errorRetrievingSubscription = err.err;
            this.retrievingSubscriptionDetails = false;
            if ( err?.message?.name === 'JsonWebTokenError' ) {
              this.needNewToken.next( true );
            } else {
              reject( err );
            }
          },
        } );
    } );
  }

  /**
   * returns and observable that notifies subscribers when the user's subscription has been properly retrieved
   */
  hasActiveSubscription(): Observable<any> {
    return new Observable<any>( ( subscriber: Subscriber<any> ) => {
      const check = this.checkForActiveSubscription();
      if ( check !== undefined ) {
        if ( typeof check === 'string' ) {
          subscriber.error( check );
          subscriber.complete();
        } else {
          subscriber.next( check );
          subscriber.complete();
        }
      } else {
        const intervalId = setInterval( () => {
          const check = this.checkForActiveSubscription();
          // Logger.log( 'checking for active subscription at interval...' );
          if ( check !== undefined ) {
            clearInterval( intervalId );
            if ( typeof check === 'string' ) {
              subscriber.error( check );
              subscriber.complete();
            } else {
              subscriber.next( check );
              subscriber.complete();
            }
          }
        }, 500 );
      }
    } );
  }

  checkForActiveSubscription() {
    if ( this.hasRetrievedSubscription ) {
      return [ SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING ].includes( this.status );
    } else if ( this.errorRetrievingSubscription ) {
      return this.errorRetrievingSubscription;
    } else {
      return undefined;
    }
  }

  setupFeaturesFromStripeProductData() {
    const features: AvailableFeature[] = getListOfFeatures( this.currentProduct );
    this._state.setFeaturesConfig( features );
  }

  setCurrentPricing() {
    this.currentPricing = _.find( this.currentProduct.prices, ( p: any ) => {
      return p.id === this.currentSubscriptionInfo?.plan?.id;
    } );
  }

  checkCurrentSubscriptionInfo() {
    this.currentProduct = _.clone(
      _.find( this.stripeProducts, ( p: any ) => {
        return p.id === this.currentSubscriptionInfo?.plan?.product;
      } ),
    );
    if ( !this.currentProduct ) {
      this.currentProduct = this.makeEmptyProduct();
      this.setCurrentPricing();
    }

    if ( this.currentSubscriptionInfo?.discount ) {
      this.setCurrentDiscountString();
    }

    if ( this.currentProduct?.metadata ) {
      this.setupFeaturesFromStripeProductData();
    }

    this._appStoreService.setCurrentUserIsFinancialAdvisor( this.userIsFinancialAdvisor() );
  }

  setFuturePricing() {
    if ( this.futureProduct ) {
      this.futurePricing = _.find( this.futureProduct?.prices, ( p: any ) => {
        return p.id === this.futureSubscriptionInfo?.plan?.id;
      } );
    }
  }

  checkFutureSubscriptionInfo() {
    this.futureProduct = _.find( this.stripeProducts, ( p: StripeProduct ) => {
      return p.id === this.futureSubscriptionInfo?.plan?.product;
    } );

    if ( this.futureSubscriptionInfo.discount ) {
      this.setFutureDiscountString();
    }

    this.setFuturePricing();
  }

  setCurrentDiscountString(): void {
    if ( this.currentSubscriptionInfo.discount.coupon.amount_off ) {
      this.currentDiscount = this.ripCurrencyPipe.transform( this.currentSubscriptionInfo.discount.coupon.amount_off / 100 );
    } else {
      this.currentDiscount = this.ripPercentPipe.transform( this.currentSubscriptionInfo.discount.coupon.percent_off / 100, '0-2' );
    }
    this.currentDiscount = `${ this.currentDiscount } Off`;
    if ( this.currentSubscriptionInfo.discount.coupon.duration_in_months ) {
      this.currentDiscountDuration = `${ this.currentSubscriptionInfo.discount.coupon.duration_in_months } Months`;
    } else {
      this.currentDiscountDuration = this.titleCasePipe.transform( this.currentSubscriptionInfo.discount.coupon.duration );
    }
  }

  setFutureDiscountString(): void {
    if ( this.futureSubscriptionInfo.discount.coupon.amount_off ) {
      this.futureDiscount = this.ripCurrencyPipe.transform( this.futureSubscriptionInfo.discount.coupon.amount_off / 100 );
    } else {
      this.futureDiscount = this.ripPercentPipe.transform( this.futureSubscriptionInfo.discount.coupon.percent_off / 100, '0-2' );
    }
    this.futureDiscount = `${ this.futureDiscount } Off`;
    if ( this.futureSubscriptionInfo.discount.coupon.duration_in_months ) {
      this.futureDiscountDuration = `${ this.futureSubscriptionInfo.discount.coupon.duration_in_months } Months`;
    } else {
      this.futureDiscountDuration = this.titleCasePipe.transform( this.futureSubscriptionInfo.discount.coupon.duration );
    }
  }

  userIsFinancialAdvisor() {
    return this.currentProduct?.metadata?.financialAdvisor;
  }

  redirectToCustomerPortal() {
    this.stripeService
      .getStripeCustomerPortalSession()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp ) => {
          const session = resp.data.session;
          window.open( session.url, '_self' );
        },
        error: ( err ) => {
          console.error( err );
        },
      } );
  }

  private checkForTrialing() {
    if ( this.subIsTrialing ) {
      const diff = moment( this.currentSubscriptionInfo.trial_end ).diff( moment(), 'days' );
      if ( diff === 0 ) {
        this.trialHours = moment( this.currentSubscriptionInfo.trial_end ).diff( moment(), 'hours' );
      } else if ( diff > 0 ) {
        this.trialDays = diff;
      } else {
        // this should mean they are past the trial end, so do nothing
      }
    }
  }

  private setNoSubDetails() {
    this.currentSubscriptionInfo = undefined;
    this.subIsTrialing = false;
    this.hasRetrievedSubscription = true;
  }

  private processAllSubscriptions( subscriptions: StripeSubscription[] ) {
    this.canceledSubs = [];
    this.activeSubs = [];
    this.trialingSubs = [];
    for ( const sub of subscriptions ) {
      if ( sub.status === SubscriptionStatus.ACTIVE ) {
        this.activeSubs.push( sub );
      } else if ( sub.status === SubscriptionStatus.TRIALING ) {
        this.trialingSubs.push( sub );
      } else if (
        [
          SubscriptionStatus.CANCELED,
          SubscriptionStatus.INCOMPLETE,
          SubscriptionStatus.INCOMPLETE_EXPIRED,
          SubscriptionStatus.UNPAID,
          SubscriptionStatus.PAUSED,
        ].includes( sub.status as SubscriptionStatus )
      ) {
        // this could maybe just be the else
        this.canceledSubs.push( sub );
      } else {
        this.status = SubscriptionStatus.PAST_DUE;
        this.pastDueSub = sub;
      }
    }

    if ( this.activeSubs.length > 1 ) {
      // should only be 2 subscriptions at most
      this.setCurrentAndFutureSubscriptions( this.activeSubs[ 0 ], this.activeSubs[ 1 ] );
    }
  }

  /**
   * Function to compare subscription plans. Uses the indexes from the planOptions from the environment config to compare tiers
   * sets higher plan to this.currentSubscription and lower plan to this.futureSubscription
   * @param subA - first subscription to compare
   * @param subB - second subscription to compare
   * @private
   */
  private setCurrentAndFutureSubscriptions( subA: StripeSubscription, subB: StripeSubscription ): void {
    const planAIndex = this.stripeProducts.findIndex( ( po: StripeProduct ) => {
      return po.id === subA.plan.product;
    } );

    const planBIndex = this.stripeProducts.findIndex( ( po: StripeProduct ) => {
      return po.id === subB.plan.product;
    } );

    // if the user has two subscriptions to the same pricing, we have an issue
    if ( planAIndex === planBIndex && subA.plan.id === subB.plan.id ) {
      if ( subA?.status === SubscriptionStatus.ACTIVE ) {
        this.currentSubscriptionInfo = subA;
        this.status = this.currentSubscriptionInfo.status as SubscriptionStatus;
        return;
      }
      if ( subB?.status === SubscriptionStatus.ACTIVE ) {
        this.currentSubscriptionInfo = subB;
        this.status = this.currentSubscriptionInfo.status as SubscriptionStatus;
        return;
      }
      if ( subA?.status === SubscriptionStatus.ACTIVE && subB?.status === SubscriptionStatus.ACTIVE ) {
        console.error( ' TWO SUBS AT SAME TIER!' );
      }
    }

    if ( planAIndex === planBIndex ) {
      // both subs are in the same tier, check subA for cancel_at_period_end first
      if ( subA.cancel_at_period_end ) {
        // subA is cancelling, so it is "current"
        this.setCurrentAndFutureSubs( subA, subB );
      } else if ( subB.cancel_at_period_end ) {
        // subB is cancelling, so it is "current"
        this.setCurrentAndFutureSubs( subB, subA );
      }
      this.futureSubIsLower = false;
    } else if ( planAIndex >= planBIndex ) {
      // planA is the higher tier and thus must be the current one
      this.setCurrentAndFutureSubs( subA, subB );
      this.futureSubIsLower = true;
    } else {
      // planB is the higher tier and thus must be the current one
      this.setCurrentAndFutureSubs( subB, subA );
      this.futureSubIsLower = true;
    }
  }

  private setCurrentAndFutureSubs( cSub: StripeSubscription, fSub: StripeSubscription ) {
    this.currentSubscriptionInfo = cSub;
    this.futureSubscriptionInfo = fSub;
    this.status = this.currentSubscriptionInfo.status as SubscriptionStatus;
  }

  private setSubStatus() {
    // reset. By the end of this next conditional block, only one of these should be true

    this.subIsTrialing = false;

    if ( this.futureSubscriptionInfo ) {
      // this means that the user has a subscription that will end, but also one that will start after.
      // so they must be active, can't be inactive or canceled, otherwise would only be one subscription, current
      this.subIsTrialing = this.trialingSubs.length > 0;
    } else {
      if ( this.trialingSubs.length > 0 ) {
        // theoretically, you shouldn't be able to get here with a previously cancelled sub
        this.subIsTrialing = true;
      }
    }

    if ( this.currentSubscriptionInfo ) {
      this.status = this.currentSubscriptionInfo.status as SubscriptionStatus;
    } else if ( this.canceledSubs.length > 0 ) {
      this.status = SubscriptionStatus.INACTIVE;
    } else {

    }
  }


  private makeEmptyProduct(): StripeProduct {
    return {
      id: '',
      active: false,
      created: undefined,
      livemode: false,
      name: '',
      object: 'product',
      updated: undefined,
    };
  }

  private resetStateOfSubscriptionInfo() {
    this.noSource = false;
    this.currentCardInfo = undefined;

    this.currentSubscriptionInfo = undefined;
    this.currentDiscount = undefined;
    this.currentDiscountDuration = undefined;
    this.currentProduct = undefined;
    this.currentPricing = undefined;
    this.currentPaymentSource = undefined;

    this.futureSubscriptionInfo = undefined;
    this.futureDiscount = undefined;
    this.futureDiscountDuration = undefined;
    this.futureProduct = undefined;
    this.futurePricing = undefined;
    this.futureSubIsLower = undefined;

    this.subIsTrialing = undefined;
    this.canceledSubs = [];
    this.activeSubs = [];
    this.trialingSubs = [];
    this.pastDueSub = undefined;

    this.trialDays = undefined;
    this.trialHours = undefined;

    this.hasRetrievedSubscription = undefined;
    this.errorRetrievingSubscription = undefined;

    this._state.setFeaturesConfig( [] );
  }
}