import { Component, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { TransactionsService } from '../../../../theme/services';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { GlobalState } from '../../../../global.state';
import { AccountManager } from '../../../../utils/accountManager';
import { environment } from '../../../../../environments/environment';
import { ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { RipsawCurrencyPipe, RipsawDecimalPipe } from '../../../../theme/pipes';
import { BaseChartDirective } from 'ng2-charts';
import { TransactionsState } from '../../../../utils/transactions.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';


@Component( {
  selector: 'rip-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: [ './income-statement.component.scss' ],
} )
export class IncomeStatementComponent {

  @ViewChild( 'incomeChart' ) incomeChartDirective: BaseChartDirective;
  @ViewChild( 'expenseChart' ) expenseChartDirective: BaseChartDirective;
  @ViewChild( 'netChart' ) netChart: BaseChartDirective;

  @Output() categoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @HostListener( 'window:resize', [ '$event' ] )
  onResize() {
    // const barChartContainer = document.getElementById('net-income-bar-chart-container');
    if ( !this.deviceIsMobile ) {
      this.barChartContainerWidth = `${ window.innerWidth / 3 }px`;
    }
  }

  barChartContainerWidth: string = '100%';


  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  doughnutChartLabelCallback: any = ( context: TooltipItem<ChartType> ) => {
    return `${ context.label }: $${ context.formattedValue }`;
  };

  // Chart Options for Expense donut Chart
  incomeDonutchartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 25,
        top: 25,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: this.doughnutChartLabelCallback,
        },
      },
    },
  };


  // Chart Options for Expense donut Chart
  ExpenseDonutchartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 25,
        top: 25,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: this.doughnutChartLabelCallback,
        },
      },
    },
  };


  // Chart Options for Net Chart
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // showLine: true,

    scales: {
      x: {
        stacked: true,
        type: 'time',
        time: {
          unit: 'month',
        },
        ticks: {
          maxTicksLimit: 13,
        },
      },
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: ( value/*, index, values*/ ) => {
            return `$${ this.ripDecimalPipe.transform( value, '0-0' ) }`;
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        // text: 'Monthly Income vs Expense',
      },
      tooltip: {
        callbacks: {
          title: ( tooltipItems: ( TooltipItem<ChartType> )[] ) => {
            return moment( tooltipItems[ 0 ].label ).format( 'MMMM, YYYY' );
          },
          label: ( context: TooltipItem<ChartType> ) => {
            return `${ context.dataset.label }: $${ context.formattedValue }`;
          },
        },
      },
    },

  };
  barChartType: ChartType = 'bar';


  subscriberName: string = 'IncomeStatementComponent';

  deviceIsMobile: boolean;

  constructor( private transactionsService: TransactionsService,
               private _state: GlobalState,
               private _accountManager: AccountManager,
               public transactionsState: TransactionsState,
               private deviceDetector: DeviceDetectorService ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_incomeStatementComponent' ] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this.deviceDetector );
  }


  filterByCategory( event: any, type?: string ) {
    if ( event.active ) { // category.active[0]._index
      const index = event.active[ 0 ]._index;
      if ( type === 'expense' ) {
        this.categoryClicked.emit( this.transactionsState.expenseCategories[ index ] );
      } else {
        this.categoryClicked.emit( this.transactionsState.incomeCategories[ index ] );
      }
    } else {
      // event is a category
      this.categoryClicked.emit( event );
    }
  }

  onHoverLegendItem( index: number, type: string ) {
    // Logger.log('on hover of ', index, ' in ', type, ' chart');
    const chartDirective: any = type === 'income' ? this.incomeChartDirective : this.expenseChartDirective;
    const chart = chartDirective.chart;
    const chartArea = chart.chartArea;
    // reset the active elements if there are any already
    if ( chart.getActiveElements().length > 0 ) {
      chart.setActiveElements( [] );
    } else {
      // set the given item as the active element
      chart.setActiveElements( [
        {
          datasetIndex: 0,
          index: index,
        },
      ] );
    }
    // reset the active tooltip elements if there are any already
    if ( chart.tooltip.getActiveElements().length > 0 ) {
      chart.tooltip.setActiveElements( [], { x: 0, y: 0 } );
    } else {
      // set the given item as the active tooltip element
      chart.tooltip.setActiveElements( [
        {
          datasetIndex: 0,
          index: index,
        },
      ] );
    }
    chart?.update();
  }

  onLeaveLegend( index: number, type: string ) {
    // Logger.log('on leave of ', index, ' in ', type, ' chart');
    const chartDirective: any = type === 'income' ? this.incomeChartDirective : this.expenseChartDirective;
    const chart = chartDirective.chart;
    const chartArea = chart.chartArea;
    chart?.setActiveElements( [] );
    chart?.tooltip?.setActiveElements( [] );
    chart?.update();
  }

}

